import SignInForm from 'components/modules/auth/SignInForm';
import AuthSimpleLayout from 'layouts/AuthSimpleLayout';

const SignIn = () => {
  const rootEl: any= document.getElementById('root');
  rootEl.style['background-color'] = '#5f1ed0';
  return (
    <AuthSimpleLayout>
      <SignInForm layout="simple" />
    </AuthSimpleLayout>
  );
};

export default SignIn;
