import { baseClient } from './base';

export async function getAdminUsers() {
  const { data } = await baseClient.get('/admin/users');
  return data.data;
}

export async function enableUser(userId: number) {
  const { data } = await baseClient.put(`/admin/users/${userId}/enable`);
  return data.data;
}

export async function disableUser(userId: number) {
  const { data } = await baseClient.put(`/admin/users/${userId}/disable`);
  return data.data;
}

export async function getMe() {
  const { data } = await baseClient.get(`/admin/users/me`);
  return data.data;
}
