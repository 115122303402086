import { Navbar } from 'react-bootstrap';
import '../../../assets/scss/theme/_icon-2.scss';
import { useAppContext } from 'providers/AppProvider';
import classNames from 'classnames';
import NavbarBrand from 'components/navbars/nav-items/NavbarBrand';
import NavItemsSlim from 'components/navbars/nav-items/NavItemsSlim';
import NavItems from 'components/navbars/nav-items/NavItems';
import NavbarTopNav from './NavbarTopNav';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import DropdownSearchBox from 'components/common/DropdownSearchBox';
import SearchResult from 'components/common/SearchResult';
import { useState } from 'react';
import { faSearch,faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const NavbarTopHorizontal = () => {
  const {
    config: {
      navbarPosition,
      openNavbarVertical,
      navbarTopShape,
      navbarTopAppearance
    }
  } = useAppContext();

  const { breakpoints } = useBreakpoints();

  const [searchVisible, setSearchVisible] = useState(false);

  const toggleSearchVisibility = () => {
    setSearchVisible(!searchVisible);
  };

  return (
    <Navbar
      className={classNames('navbar-top fixed-top', {
        'navbar-slim': navbarTopShape === 'slim',
        'navbar-darker': navbarTopAppearance === 'darker'
      })}
      expand="lg"
      variant="">
      <NavbarBrand />
      {!(navbarPosition === 'combo' && breakpoints.down('lg')) && (
        <Navbar.Collapse
          className="navbar-top-collapse order-1 order-lg-0 justify-content-start pb-0"
          in={openNavbarVertical}>
          <NavbarTopNav />
        </Navbar.Collapse>
      )}
      <div className="navbar-nav navbar-nav-icons flex-row search-box-icon" onClick={toggleSearchVisibility}>
        {searchVisible ? (
          <FontAwesomeIcon icon={faTimes} className="icon-margin-r" />
        ) : (
          <FontAwesomeIcon icon={faSearch} className="icon-margin-r" />
        )}
      </div>
      {searchVisible && (
        <DropdownSearchBox>
          <SearchResult />
        </DropdownSearchBox>
      )}
      {navbarTopShape === 'default' ? <NavItems /> : <NavItemsSlim />}
    </Navbar>
  );
};

export default NavbarTopHorizontal;
