
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Card } from 'react-bootstrap';
import LeftMenu from './Leftmenu';
import Calendar from '../apps/calendar/Calendar';
import Map from './dashboard-pages-tab/Map';
import List from './dashboard-pages-tab/List';
import '../../assets/scss/theme/_dinamic-tabs.scss';
import ServiceAreaAbm from './dashboard-pages-tab/Service-area-abm';
import TechnicianAbm from './dashboard-pages-tab/Technician-abm';
import ClientAbm from './dashboard-pages-tab/client-abm';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import LogoAnimated from 'components/icons/logo-animated';
import './Dashboard.css';

const QuickAccessFilters = [
  {
    filter: 'Pending schedule',
    quantity: 2
  },
  {
    filter: 'RTN Approved',
    quantity: 2
  },
  {
    filter: 'RTN inspection',
    quantity: 2
  },
  {
    filter: 'Snoozed',
    quantity: 2
  },
  {
    filter: 'Overdue',
    quantity: 2
  }
];

const Dashboard = () => {
  const [isDataReady, setIsDataReady] = useState(false);
  const [activeButton, setActiveButton] = useState('list');
  const [activeButtonFilters, setActiveButtonFilters] = useState('all');
  const [serviceAreaFilters, setServiceAreaFilters] = useState([]);
  const [selectedServiceArea, setSelectedServiceArea] = useState(null);
  // const [snoozedCount, setSnoozedCount] = useState(0);
  const [filtersState, setFiltersState] = useState([]);
  const [inmutableFilters, setInmutableFilters] = useState(null);
  const [selected, setSelected] = useState(null);
  const [filteredWorkOrders, setFilteredWorkOrders] = useState([]);

  const rootEl = document.getElementById('root');
  if (rootEl) {
    rootEl.style['background-color'] = 'var(--phoenix-body-bg)';
  }

  const {
    changeServiceArea,
    filters,
    searchWorkOrders,
    loadingWorkOrders,
    workOrders,
    groups,
    totalItems,
    snoozedCount,
    getTotalSnoozedCount,
  } = useWorkOrderContext();

  const fetchWorkOrders = async () => {
    try {
      const response = await searchWorkOrders({
        filters: {},
        page: 1,
        perPage: 10
      });

      if (response) {
        setFiltersState(response.filters);
        setIsDataReady(true)
        return response;
      } else {
        console.log('Invalid response');
      }
    } catch (error) {
      console.error('Error fetching work orders: ', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchWorkOrders();
      await getTotalSnoozedCount();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (inmutableFilters && inmutableFilters.snoozed) {
      const snoozedOrders = Object.values(inmutableFilters.snoozed).reduce(
        (sum, value) => sum + value,
        0
      );
      setFiltersState(snoozedOrders);
    }
  }, [inmutableFilters]);

  useEffect(() => {
    if (snoozedCount?.filters && Object.keys(snoozedCount.filters).length > 0) {
      setInmutableFilters({ ...snoozedCount.filters });
      setServiceAreaFilters(snoozedCount.filters.service_areas || []);
    }
  }, [snoozedCount.filters]);

  useEffect(() => {
    const updateFilters = async () => {
      await getTotalSnoozedCount();
    };
    updateFilters();
    
  }, [workOrders]);
  
  useEffect(() => {
    if (selected === 'Snoozed') {
      const filteredOrders = workOrders.filter(
        (order) => order.status === 'snoozed'
      );
      setFilteredWorkOrders(filteredOrders);
    } else {
      setFilteredWorkOrders(workOrders);
    }
  }, [workOrders, selected]);

  const components = {
    calendar: <Calendar />,
    // workorders: <WorkOrders />,
    map: <Map />,
    list: <List workOrders={filteredWorkOrders} />,
    ServiceAreaAbm: <ServiceAreaAbm />,
    TechnicianAbm: <TechnicianAbm />,
    ClientAbm: <ClientAbm />
  };

  const getButtonStyle = buttonName => ({
    backgroundColor: activeButton === buttonName ? '#0aedd1' : undefined
  });

  const handleClickCard = newOptionSelected => {
    if (newOptionSelected === selected) {
      setSelected(null);
      searchWorkOrders({});
    } else {
      setSelected(newOptionSelected);
      const params = {
        page: 1,
        perPage: 10
      };
      if (newOptionSelected === 'RTN Approved') {
        params.statuses = ['rtn_approved_work'];
      }
      if (newOptionSelected === 'Pending schedule') {
        params.statuses = ['pending_schedule'];
      }
      if (newOptionSelected === 'RTN inspection') {
        params.statuses = ['rtn_inspection'];
      }
      if (newOptionSelected === 'Snoozed') {
        params.statuses = ['snoozed'];
      }
      if (newOptionSelected === 'Overdue') {
        params.dueOverdueFilters = [
          'overdue_for_more_than_a_week',
          'overdue_for_less_than_a_week'
        ];
      }
      searchWorkOrders(params);
    }
  };

  const handleButtonClick = eventKey => {
    setActiveButton(eventKey);
  };

  if (!isDataReady) {
    return (
      <div className="loader-container">
        <LogoAnimated />
      </div>
    );
  }
  return (
    <>
      <div className="quick-access-filters">
        {inmutableFilters &&
          QuickAccessFilters.map(quickAccessFilter => (
            <Card
              key={quickAccessFilter.filter}
              onClick={() => handleClickCard(quickAccessFilter.filter)}
              style={{
                padding: '0.5rem',
                marginBottom: '3rem',
                marginTop: '1rem',
                backgroundColor:
                  selected === quickAccessFilter.filter
                    ? 'var(--selected-color)'
                    : '#ffffff',
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)'
              }}>
              <div className="quick-access-filter">
                <span className="filter-quantity">
                  {quickAccessFilter.filter === 'Pending schedule'
                    ? inmutableFilters?.dispatching?.pending_schedule
                    : ''}

                  {quickAccessFilter.filter === 'RTN Approved'
                    ? inmutableFilters?.dispatching?.rtn_approved_work
                    : ''}

                  {quickAccessFilter.filter === 'RTN inspection'
                    ? inmutableFilters?.dispatching?.rtn_inspection
                    : ''}
                  {quickAccessFilter.filter === 'Snoozed'
                    ? Object.values(inmutableFilters.snoozed).reduce(
                      (sum, value) => sum + value,
                      0
                    )
                    : ''}


                  {quickAccessFilter.filter === 'Overdue'
                    ? (inmutableFilters?.due_date
                        ?.overdue_for_less_than_a_week || 0) +
                      (inmutableFilters?.due_date
                        ?.overdue_for_more_than_a_week || 0)
                    : ''}
                </span>
                <span className="filter-name">{quickAccessFilter.filter}</span>
              </div>
            </Card>
          ))}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'center',
            marginBottom: '3rem'
          }}>
          <ButtonGroup aria-label="Basic example">
            {['all', 'dispatching', 'quoting', 'billing'].map(group => (
              <Button
                key={group}
                variant="phoenix-secondary"
                onClick={() => setActiveButtonFilters(group)}
                style={{
                  backgroundColor:
                    activeButtonFilters === group
                      ? 'var(--selected-color)'
                      : '#ffffff'
                }}
                active={activeButtonFilters === group}>
                {group.toUpperCase()} (
                  {group === 'all'
                  ? snoozedCount?.count
                  : snoozedCount?.groups?.[group] ?? 0})
              </Button>
            ))}
          </ButtonGroup>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row',  marginBottom: activeButtonFilters === 'quoting' || activeButtonFilters === 'billing' ? '0rem' : '3rem'}}>
          <LeftMenu activeButton={activeButtonFilters}/>
          <div
            style={{
              width: '75%',
              marginLeft: '3rem',
              marginBottom: '1rem'
            }}>
            {activeButtonFilters !== 'quoting' &&
              activeButtonFilters !== 'billing' && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'space-between',
                    marginBottom: '1rem'
                  }}>
                  <ButtonGroup
                    aria-label="Basic example"
                    style={{
                      marginLeft: ''
                    }}>
                    <Button
                      key="all"
                      variant="phoenix-secondary"
                      onClick={() => {
                        setSelectedServiceArea(null);
                        changeServiceArea(null, activeButton);
                      }}
                      style={{
                        backgroundColor: selectedServiceArea === null ? '#0aedd1' : undefined
                      }}>
                      All ({snoozedCount?.count})
                    </Button>
                    {serviceAreaFilters.map(serviceArea => (
                      <Button
                        key={serviceArea.id}
                        variant="phoenix-secondary"
                        onClick={() => {
                          setSelectedServiceArea(serviceArea);
                          changeServiceArea(serviceArea.id, activeButton);
                        }}
                        style={{
                          backgroundColor:
                            serviceArea.id === selectedServiceArea?.id ? '#0aedd1' : undefined
                        }}>
                        {serviceArea.label} ({serviceArea.count})
                      </Button>
                    ))}
                  </ButtonGroup>

                  <ButtonGroup aria-label="Basic example">
                    <Button
                      variant="phoenix-secondary"
                      onClick={() => handleButtonClick('list')}
                      active={activeButton === 'list'}
                      style={getButtonStyle('list')}>
                      List
                    </Button>
                    <Button
                      variant="phoenix-secondary"
                      onClick={() => handleButtonClick('map')}
                      active={activeButton === 'map'}
                      style={getButtonStyle('map')}>
                      Map
                    </Button>
                    <Button
                      variant="phoenix-secondary"
                      onClick={() => handleButtonClick('calendar')}
                      active={activeButton === 'calendar'}
                      style={getButtonStyle('calendar')}>
                      Calendar
                    </Button>
                  </ButtonGroup>
                </div>
              )}
            {loadingWorkOrders ? (
              <LogoAnimated
                className={inmutableFilters ? 'logo-on-dashboard' : ''}
              />
            ) : (
              components[activeButton]
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;