import { formatAsDateAndTime } from 'helpers/date-utils';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import useLightbox from 'hooks/useLightbox';
import Lightbox from 'components/base/LightBox';
import './checkin-checkout.css';
import { formatStatus } from 'components/work-orders/work-order-list-item';

const validTypes = [
  'check_out_work_finished',
  'check_out_pending_tech_quote',
  'check_out_work_not_finished',
  'check_in'
];

const CheckinCheckoutImages = () => {
  const { workOrderFullDetailFound } = useWorkOrderContext();
  return (
    <div className='ms-xl-8 mt-4'>
      {workOrderFullDetailFound.onsiteTechnicianActions
        .filter(ta => validTypes.includes(ta.type))
        .map(event => {
          const { lightboxProps, openLightbox } = useLightbox(
            event.images.map(image => image.image_url)
          );
          return (
            <div key={event.id} className="onsite-technician-action">
              <Lightbox {...lightboxProps} />
              <div className="d-flex gap-2 align-content-center align-items-center">
                <h4>{formatStatus(event.type)}</h4>
                <span style={{ fontSize: '12px'}}>{formatAsDateAndTime(event.created_at)}</span>
              </div>
              <div className="onsite-technician-action__images">
                {event.images.map(image => image.image_url).map((image, index) => (
                  <div key={index} onClick={() => openLightbox(index + 1)}>
                    <img
                      src={image}
                      style={{
                        width: '105px',
                        height: '105px',
                        borderRadius: '8px',
                        cursor: 'pointer'
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default CheckinCheckoutImages;
