import { useState, useEffect } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Modal } from 'react-bootstrap';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Button from 'components/base/Button';
import CreateNewServiceCategory from 'components/forms/CreateNewServiceCategory';
import EditServiceCategory from 'components/forms/EditServiceCategory'; 

interface ServiceCategory {
  id: number;
  name: string;
}

const ServiceCategoryTable: React.FC = () => {
  const { serviceCategories, createServiceCategory, getServiceCategories } =
    useWorkOrderContext();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false); 
  const [selectedServiceCategory, setSelectedServiceCategory] = useState<ServiceCategory | null>(null); 

  useEffect(() => {
    getServiceCategories();
  }, []);

  const handleCreate = () => {
    setShowCreateModal(true);
  };

  const handleCloseModal = () => {
    setShowCreateModal(false);
    setShowEditModal(false);
  };

  const handleEdit = (original: ServiceCategory) => {
    setSelectedServiceCategory(original); 
    setShowEditModal(true); 
  };

  const handleDelete = (original: ServiceCategory) => {
    alert('not implemented yet');
  };

  const ServiceCategoryColumns: ColumnDef<ServiceCategory>[] = [
    {
      accessorKey: 'name',
      header: 'Name',
      cell: ({ row: { original } }) => original.name,
      meta: {
        headerProps: { style: { width: '20%' }, className: 'text-center pe-7' },
        cellProps: { className: 'text-center fw-semi-bold text-1000 pe-7' }
      }
    },
    {
      id: 'action',
      header: 'Actions',
      cell: ({ row: { original } }) => (
        <div className="d-flex justify-content-start">
          <Button
            variant="primary"
            className="m-2"
            size="sm"
            onClick={() => handleEdit(original)}> 
            Edit
          </Button>
        {/*   <Button
            variant="danger"
            size="sm"
            onClick={() => handleDelete(original)}>
            Delete
          </Button> */}
        </div>
      ),
      meta: {
        headerProps: { style: { width: '25%' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: serviceCategories,
    columns: ServiceCategoryColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  return (
    <AdvanceTableProvider {...table}>
      <div className="border-y">
        <div
          className="d-flex flex-wrap gap-3 justify-content-end"
          style={{ paddingRight: '2rem' }}>
          <Button
            variant="success"
            size="sm"
            className="mt-3"
            onClick={() => handleCreate()}>
            Create
          </Button>
        </div>
        <Modal show={showCreateModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Create New Service Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateNewServiceCategory closeModalAction={handleCloseModal} />
          </Modal.Body>
        </Modal>
        <Modal show={showEditModal} onHide={handleCloseModal}> 
          <Modal.Header closeButton>
            <Modal.Title>Edit Service Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedServiceCategory && <EditServiceCategory closeModalAction={handleCloseModal} serviceCategory={selectedServiceCategory} />} 
          </Modal.Body>
        </Modal>
        <AdvanceTable
          tableProps={{ className: 'phoenix-table table-sm fs-9' }}
        />
        <AdvanceTableFooter pagination showViewAllBtn={false} />
      </div>
    </AdvanceTableProvider>
  );
};

export default ServiceCategoryTable;