import React, { useEffect, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import { Link } from 'react-router-dom';
import { SearchWorkOrdersParams } from 'api/types/work-orders';
import LogoAnimated from 'components/icons/logo-animated';

interface WorkOrder {
  client_id: any;
  issue_resident_detail: string;
  priority: string;
  residence_address: string;
  residence_address_lat: number | undefined;
  residence_address_long: number | undefined;
  resident: {
    email: string;
    alternative_phone: string;
    full_name: string;
    phone: string;
  };
  service_area_id: any;
  service_category_id: any;
  sub_client_id: any;
  technician_id: any;
  client_nte: string;
  tech_nte: string;
  code: string;
  status: string;
  schedule: string;

  client: {
    id: number;
    name: string;
  };
  sub_client: {
    id: number;
    name: string;
  };
  service_area: {
    id: number;
    name: string;
  };
  service_category: {
    id: number;
    name: string;
  };
  scheduling: {
    id: string;
    date_and_time: string;
    work_order_id: string;
    date_and_time_end: string;
    notes: string;
    created_at: string;
    updated_at: string;
    technicians: {
      id: number;
      fullname: string;
    }[];
  }[];
  next_visit: {
    id: string;
    date_and_time: string;
    work_order_id: string;
    date_and_time_end: string;
    notes: string;
    created_at: string;
    updated_at: string;
    technicians: {
      id: number;
      fullname: string;
    }[];
  };
}

const tableColumns: ColumnDef<WorkOrder>[] = [
  {
    accessorKey: 'code',
    header: 'WO',
    cell: ({ row: { original } }) => {
      const workOrderCode = original.code || '';
      return (
        <Link
          to={`/workorder-data/${workOrderCode}`}
          target="_blank"
          rel="noopener noreferrer">
          {workOrderCode}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: {
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        }
      },
      cellProps: { style: { backgroundColor: '#ffffff', padding: '5px' } }
    }
  },
  {
    accessorKey: 'technician_id',
    header: 'Technician',
    cell: ({ row: { original } }) => {
      const technician = original.scheduling[0]?.technicians[0];
      return technician ? technician.fullname : '';
    },
    meta: {
      headerProps: {
        style: {
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        }
      },
      cellProps: { style: { backgroundColor: '#ffffff', padding: '5px' } }
    }
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row: { original } }) => original.status || '',
    meta: {
      headerProps: {
        style: {
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        }
      },
      cellProps: { style: { backgroundColor: '#ffffff', padding: '5px' } }
    }
  },
  {
    accessorKey: 'priority',
    header: 'Priority',
    cell: ({ row: { original } }) => {
      const priorityColor = () => {
        switch (original.priority) {
          case 'critical':
            return '#B70E06';
          case 'emergency':
            return '#D81B08';
          case 'urgent':
            return '#D8D208';
          case 'routine':
            return '#0867D8';
          default:
            return '#0867D8';
        }
      };

      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start'
          }}>
          <div
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: priorityColor(),
              marginRight: '5px'
            }}
          />
          {original.priority}
        </div>
      );
    },
    meta: {
      headerProps: {
        style: {
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        }
      },
      cellProps: {
        style: {
          backgroundColor: '#ffffff',
          padding: '5px',
          textAlign: 'center'
        }
      }
    }
  },
  {
    accessorKey: 'schedule',
    header: 'Schedule',
    cell: ({ row: { original } }) => {
      const { date_and_time, date_and_time_end } = original.scheduling[0] || {};
      return date_and_time && date_and_time_end
        ? `${date_and_time.slice(0, 10)} ${date_and_time.slice(11, 16)} - ${date_and_time_end.slice(11, 16)}`
        : '';
    },
    meta: {
      headerProps: {
        style: {
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        }
      },
      cellProps: { style: { backgroundColor: '#ffffff', padding: '5px' } }
    }
  },
  {
    accessorKey: 'service_category.name',
    header: 'Service Category',
    cell: ({ row: { original } }) => original.service_category?.name || '',
    meta: {
      headerProps: {
        style: {
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        }
      },
      cellProps: { style: { backgroundColor: '#ffffff', padding: '5px' } }
    }
  },
  {
    accessorKey: 'residence_address',
    header: 'Address',
    cell: ({ row: { original } }) => original.residence_address || '',
    meta: {
      headerProps: {
        style: {
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        }
      },
      cellProps: { style: { backgroundColor: '#ffffff', padding: '5px' } }
    }
  },
  {
    accessorKey: 'resident.full_name',
    header: 'Resident Contact',
    cell: ({ row: { original } }) => original.resident?.full_name || '',
    meta: {
      headerProps: {
        style: {
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        }
      },
      cellProps: { style: { backgroundColor: '#ffffff', padding: '5px' } }
    }
  },
  {
    accessorKey: 'service_area.name',
    header: 'Service Area',
    cell: ({ row: { original } }) => original.service_area?.name || '',
    meta: {
      headerProps: {
        style: {
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        }
      },
      cellProps: { style: { backgroundColor: '#ffffff', padding: '5px' } }
    }
  },
  {
    accessorKey: 'client.name',
    header: 'Client',
    cell: ({ row: { original } }) => original.client?.name || '',
    meta: {
      headerProps: {
        style: {
          width: '10%',
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        },
        className: 'text-center pe-7'
      },
      cellProps: {
        className: 'text-center fw-semi-bold text-1000 pe-7',
        style: { backgroundColor: '#ffffff', padding: '5px' }
      }
    }
  },
  {
    accessorKey: 'sub_client.name',
    header: 'Sub Client',
    cell: ({ row: { original } }) => original.sub_client?.name || '',
    meta: {
      headerProps: {
        style: {
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        }
      },
      cellProps: { style: { backgroundColor: '#ffffff', padding: '5px' } }
    }
  }
];

const QuickAccessFiltersKeys = {
  all: 'All',
  onSite: 'On Site',
  checkedOut: 'Checked out',
  etaAlert: 'Eta alert'
};

const QuickAccessFilters = [
  { filter: QuickAccessFiltersKeys.all, quantity: 2 },
  { filter: QuickAccessFiltersKeys.onSite, quantity: 2 },
  { filter: QuickAccessFiltersKeys.checkedOut, quantity: 2 },
  { filter: QuickAccessFiltersKeys.etaAlert, quantity: 2 }
];

const Today = () => {
  const { searchTodayWorkOrders: searchWorkOrders, todayWorkOrders, loadingWorkOrders } =
    useWorkOrderContext();

  const { filters, currentPage, totalPages, workOrders } = todayWorkOrders;
  const [selectedQuickFilter, setSelectedQuickFilter] = useState<string | null>(
    null
  );
  const [selectedServiceArea, setSelectedServiceArea] = useState<any | null>(
    null
  );
  const [inmutableFilters, setInmutableFilters] = useState(null);

  const handleClickCard = (newOptionSelected: string) => {
    if (newOptionSelected === selectedQuickFilter) {
      setSelectedQuickFilter(null);
      setSelectedServiceArea(null);
    } else {
      setSelectedQuickFilter(newOptionSelected);
      setSelectedServiceArea(null);
    }
  };

  useEffect(() => {
    const params: any = { todayWorkOrders: true };
    if (selectedQuickFilter === QuickAccessFiltersKeys.onSite) {
      params.statuses = ['on_site'];
    } else if (selectedQuickFilter === QuickAccessFiltersKeys.checkedOut) {
      params.checkoutStatuses = true;
    } else if (selectedQuickFilter === QuickAccessFiltersKeys.etaAlert) {
      params.etaAlert = true;
    } else {
      params.statuses = null;
      params.checkoutStatuses = null;
      params.etaAlert = null;
    }

    if (selectedServiceArea) {
      params.serviceAreaIds = [selectedServiceArea.id];
    } else {
      params.serviceAreaIds = null;
    }

    searchWorkOrders(params);
  }, [selectedQuickFilter, selectedServiceArea]);

  useEffect(() => {
    if (Object.keys(filters).length > 0 && !inmutableFilters) {
      setInmutableFilters({ ...filters });
    }
  }, [filters]);

  const handlePageChange = async (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      const params: SearchWorkOrdersParams = {
        page: newPage,
        perPage: 10,
        filters: {},
        todayWorkOrders: true
      };
      await searchWorkOrders(params);
    }
  };

  const calculateVisiblePages = () => {
    const visiblePages = [];
    const startPage = Math.max(1, Math.min(currentPage - 3, totalPages - 6));
    const endPage = Math.min(totalPages, startPage + 6);
    for (let i = startPage; i <= endPage; i++) {
      visiblePages.push(i);
    }
    return visiblePages;
  };

  const visiblePages = calculateVisiblePages();

  const table = useAdvanceTable({
    data: workOrders?.sort((a, b) => {
      const aSchedule = a.scheduling[0]?.date_and_time || '';
      const bSchedule = b.scheduling[0]?.date_and_time || '';
      return new Date(bSchedule).getTime() - new Date(aSchedule).getTime();
    }),
    columns: tableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true
  });

  return (
    <>
      <div className="quick-access-filters">
        {inmutableFilters?.todayFilters &&
          QuickAccessFilters.map(filter => (
            <Card
              key={filter.filter}
              onClick={() => handleClickCard(filter.filter)}
              style={{
                padding: '0.5rem',
                marginBottom: '1rem',
                marginTop: '1rem',
                backgroundColor:
                  selectedQuickFilter === filter.filter
                    ? 'var(--selected-color)'
                    : '#ffffff',
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)'
              }}>
              <div className="quick-access-filter">
                <span className="filter-quantity">
                  {filter.filter === 'All' &&
                    inmutableFilters?.todayFilters.all}
                  {filter.filter === 'On Site' &&
                    inmutableFilters?.todayFilters.onSite}
                  {filter.filter === 'Checked out' &&
                    inmutableFilters?.todayFilters.checkedOut}
                  {filter.filter === 'Eta alert' &&
                    inmutableFilters?.todayFilters.etaAlert}
                </span>
                <span className="filter-name">{filter.filter}</span>
              </div>
            </Card>
          ))}
      </div>

      {!loadingWorkOrders && (
        <div className="city-filters mt-8">
          {inmutableFilters?.service_areas?.map(serviceArea => (
            <Button
              key={serviceArea.id}
              onClick={() =>
                setSelectedServiceArea(
                  selectedServiceArea?.id === serviceArea.id
                    ? null
                    : serviceArea
                )
              }
              style={{
                backgroundColor:
                  selectedServiceArea?.id === serviceArea.id
                    ? '#4111aa'
                    : '#dcdcdc',
                color:
                  selectedServiceArea?.id === serviceArea.id
                    ? '#ffffff'
                    : '#000000',
                padding: '0.5rem',
                margin: '0.5rem',
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)'
              }}>
              {serviceArea.label}
            </Button>
          ))}
        </div>
      )}
      {loadingWorkOrders ? (
        <LogoAnimated />
      ) : (
        <AdvanceTableProvider {...table}>
          <div className="border-y">
            <AdvanceTable
              tableProps={{ className: 'phoenix-table table-sm fs-9 mt-4' }}
            />
            {visiblePages?.length > 0 && (
              <div className="d-flex justify-content-center mt-3">
                <Button
                  variant="secondary"
                  className="me-2"
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                  style={{ height: '36px', width: '14px' }}>
                  {'<'}
                </Button>
                {visiblePages.map(pageNumber => (
                  <Button
                    key={pageNumber}
                    variant="secondary"
                    className={
                      currentPage === pageNumber ? 'active me-2' : 'me-2'
                    }
                    onClick={() => handlePageChange(pageNumber)}
                    style={{
                      backgroundColor:
                        currentPage === pageNumber ? '#0aedd1' : '#ffffff',
                      color: '#000000',
                      border: '0.5px solid #CFD5CF',
                      marginBottom: '1rem'
                    }}>
                    {pageNumber}
                  </Button>
                ))}
                <Button
                  variant="secondary"
                  className="me-2"
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                  style={{ height: '36px', width: '14px' }}>
                  {'>'}
                </Button>
              </div>
            )}
          </div>
        </AdvanceTableProvider>
      )}
    </>
  );
};

export default Today;
