import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import { ChangeEvent, useState } from 'react';
import { Button, Form, FormControl, FormLabel, Modal } from 'react-bootstrap';
import './AddSchedule.scss';
import { formatForApi } from 'helpers/date-utils';
import {
  LocalizationProvider,
  MobileDateTimePicker,
  MobileTimePicker
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const CreateSchedule = ({
  closeModalAction
}: {
  closeModalAction: () => void;
}) => {
  const { technicians } = useWorkOrderContext();
  const { scheduleVisit, schedulingVisit, selectedWorkOrder } =
    useWorkOrderContext();

  const [formData, setFormData] = useState({
    leadTechnician: null,
    helperTechnicians: [],
    selectedDate: moment.utc(),
    selectedDateEnd: moment.utc().add(2, 'hour'),
    notes: ''
  });

  const handleFieldChange = (fieldName, value) => {
    setFormData(prevData => ({
      ...prevData,
      [fieldName]: value
    }));
  };

  const addHelperTechnician = () => {
    setFormData({
      ...formData,
      helperTechnicians: [...formData.helperTechnicians, null]
    });
  };

  const removeHelperTechnician = index => {
    const copy = [...formData.helperTechnicians];
    copy.splice(index, 1);
    setFormData({
      ...formData,
      helperTechnicians: copy
    });
  };

  const saveVisit = async () => {
    try {
      const techniciansInScheduledVisit = [];
      techniciansInScheduledVisit.push({
        id: formData.leadTechnician.id,
        type: 'lead-tech'
      });
      formData.helperTechnicians.forEach(t => {
        return techniciansInScheduledVisit.push({
          id: t.id,
          type: 'helper-tech'
        });
      });
      await scheduleVisit({
        workOrderId: selectedWorkOrder.id,
        technicians: techniciansInScheduledVisit,
        date: formatForApi(formData.selectedDate),
        dateEnd: formatForApi(formData.selectedDateEnd),
        notes: formData.notes
      });

      closeModalAction();
    } catch (error) {
      console.error('Error:', error);
      alert('¡Something went wrong! Please try again.');
    }
  };

  const areValidData = () => {
    const allHelperTechniciansSelected = formData.helperTechnicians.every(
      technician => technician !== null && technician?.id
    );
    return (
      !!formData.selectedDate &&
      !!formData.leadTechnician &&
      allHelperTechniciansSelected
    );
  };

  const isEndTimeValid = () => {
    const start = moment(formData.selectedDate);

    const end = moment(formData.selectedDate).set({
      hour: moment(formData.selectedDateEnd).hour(),
      minute: moment(formData.selectedDateEnd).minute()
    });

    return end.isAfter(start) && end.diff(start, 'minutes') >= 60;
  };

  return (
    <Form>
      <Modal.Header className="px-card border-0 header-service-area">
        Schedule Visit
      </Modal.Header>
      <Modal.Body className="p-card py-0">
        <div className="work-order d-flex flex-column">
          <div className="mb-3 col-md-12 d-flex flex-column">
            <FormLabel>Start</FormLabel>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="en-us">
              <MobileDateTimePicker
                value={formData.selectedDate}
                onChange={selected => {
                  setFormData(prevData => ({
                    ...prevData,
                    selectedDate: selected,
                    selectedDateEnd: moment(selected).add(2, 'hours')
                  }));
                }}
              />
            </LocalizationProvider>
          </div>
          <div className="mb-3 col-md-12 d-flex flex-column">
            <FormLabel>End</FormLabel>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="en-us">
              <MobileTimePicker
                value={formData.selectedDateEnd}
                onChange={selected => {
                  setFormData({ ...formData, selectedDateEnd: selected });
                }}
              />
            </LocalizationProvider>
            {!isEndTimeValid() && (
              <span className="fs-error-msg text-danger">
                The end time must be at least one hour after the start time.
              </span>
            )}
          </div>
          <div className="mb-3">
            <FormLabel>Additional notes (optional)</FormLabel>
            <textarea
              required={true}
              title="Complete this field"
              className="form-control"
              placeholder="Optional notes"
              name="Optional notes "
              id="Optional notes_details"
              value={formData.notes}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                handleFieldChange('notes', e.target.value)
              }
              style={{ height: '128px' }}
            />
          </div>
          <div className="mb-2 col-md-12">
            <FormLabel>Lead Technician</FormLabel>
            <FormControl
              as="select"
              name="leadTechnician"
              value={formData.leadTechnician?.id || ''}
              onChange={e => {
                const selectedTech = technicians.find(
                  technician => +technician.id === +e.target.value
                );
                setFormData({ ...formData, leadTechnician: selectedTech });
              }}
              required>
              <option value="">Select lead technician</option>
              {technicians
                .filter(
                  technician =>
                    !formData.helperTechnicians.some(
                      helper => helper?.id === technician.id
                    )
                )
                .map((technician: any) => (
                  <option key={technician.id} value={technician.id}>
                    {technician.technician_number} {technician.first_name}{' '}
                    {technician.last_name}
                  </option>
                ))}
            </FormControl>
          </div>

          {formData.helperTechnicians.length > 0 && (
            <div className="mb-1 col-md-12">
              <FormLabel>Helper Technicians</FormLabel>
            </div>
          )}

          {formData.helperTechnicians.map((technician, index) => (
            <div key={index} className="mb-2 col-md-12">
              <div className="d-flex align-items-center gap-2">
                <FormControl
                  as="select"
                  name="helperTechnician"
                  value={technician?.id || ''}
                  onChange={e => {
                    const copy = [...formData.helperTechnicians];
                    copy[index] = technicians.find(
                      technician => +technician.id === +e.target.value
                    );
                    setFormData({
                      ...formData,
                      helperTechnicians: copy
                    });
                  }}>
                  <option value="">Select helper technician</option>
                  {technicians
                    .filter(
                      technician =>
                        technician.id !== formData.leadTechnician?.id &&
                        !formData.helperTechnicians.some(
                          (helper, i) =>
                            helper?.id === technician.id && i !== index
                        )
                    )
                    .map((technician: any) => (
                      <option key={technician.id} value={technician.id}>
                        {technician.technician_number} {technician.first_name}{' '}
                        {technician.last_name}
                      </option>
                    ))}
                </FormControl>
                <span
                  className="cursor-pointer text-danger"
                  onClick={() => removeHelperTechnician(index)}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </span>
              </div>
              {!technician?.id && (
                <span className="fs-error-msg text-danger">
                  Remove or complete this field.
                </span>
              )}
            </div>
          ))}

          <div className="mt-2 d-flex justify-content-end">
            <Button
              className="add-technician-button"
              variant="link"
              disabled={
                technicians.length === formData.helperTechnicians.length + 1
              }
              onClick={addHelperTechnician}>
              Add helper technician
            </Button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center border-0">
        <Button variant="secondary" onClick={closeModalAction}>
          Cancel
        </Button>
        <Button
          variant="primary"
          className="px-4"
          disabled={!areValidData() || !isEndTimeValid()}
          onClick={saveVisit}>
          {schedulingVisit ? 'Saving...' : 'Save'}
        </Button>
      </Modal.Footer>
    </Form>
  );
};

export default CreateSchedule;
