import React, { useState, useEffect } from 'react';
import { Form, Modal, FormLabel, Button } from 'react-bootstrap';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';

interface ServiceCategory {
  id: number;
  name: string;
}

interface EditServiceCategoryProps {
  closeModalAction: () => void;
  serviceCategory: ServiceCategory;
}

const EditServiceCategory: React.FC<EditServiceCategoryProps> = ({ closeModalAction, serviceCategory }) => {
  const { updateServiceCategory, getServiceCategories } = useWorkOrderContext();
  const [formData, setFormData] = useState<ServiceCategory>({
    id: serviceCategory.id,
    name: serviceCategory.name
  });

  useEffect(() => {
    setFormData(serviceCategory);
  }, [serviceCategory]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await updateServiceCategory(formData.id, {
        name: formData.name
      });
      closeModalAction();
      await getServiceCategories(); 
    } catch (error) {
      console.error('Error updating service category:', error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
    
      <Modal.Body className="p-card py-0">
        <div className="work-order">
          <div className="mt-3 mb-3">
            <FormLabel>Name</FormLabel>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="form-control"
              placeholder="Enter category name"
              required
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center border-0">
        <Button variant="primary" className="px-4" type="submit">
          Save
        </Button>
      </Modal.Footer>
    </Form>
  );
};

export default EditServiceCategory;