import React, { useState, useEffect } from 'react';
import './DateSelector.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';

const DateSelector = ({ onDateChange }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    const today = new Date();
    setSelectedDate(today);

    onDateChange(today, today); 
  }, []);

  const getDatesInMonth = date => {
    const daysInMonth = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getDate();
    return Array.from(
      { length: daysInMonth },
      (_, i) => new Date(date.getFullYear(), date.getMonth(), i + 1)
    );
  };

  const dates = getDatesInMonth(currentDate);

  const handleDateClick = date => {
    setSelectedDate(date);

    onDateChange(date, date);
  };

  const handlePrevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
  };

  return (
    <div className="date-selector">
      <div className="navigation">
        <button className="nav-button" onClick={handlePrevMonth}>
          <FontAwesomeIcon icon={faChevronLeft} size="sm" />
        </button>
        <span className="current-month">
          {currentDate.toLocaleDateString('en-US', {
            month: 'long',
            year: 'numeric'
          })}
        </span>
        <button className="nav-button" onClick={handleNextMonth}>
          {' '}
          <FontAwesomeIcon icon={faChevronRight} size="sm" />
        </button>
      </div>
      <div className="date-header">
        {dates.map(date => (
          <div
            key={date}
            className={`date-item ${date.toDateString() === selectedDate?.toDateString() ? 'active-day' : ''}`}
            onClick={() => handleDateClick(date)}>
            <div className="day-label">
              {date.toLocaleDateString('en-US', { weekday: 'short' })}
            </div>
            <div className="date-number">{date.getDate()}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DateSelector;
