import { useState, ChangeEvent } from 'react';
import { Modal, Button, Form, FormControl, FormLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import {
  LocalizationProvider,
  MobileDateTimePicker,
  MobileTimePicker
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import './UpdateWorkOrderScheduleModal.scss';
import moment from 'moment';

const UpdateWorkOrderScheduleModal = ({
  show,
  onHide,
  onSubmit,
  workOrderId,
  scheduleVisit
}) => {
  const { technicians } = useWorkOrderContext();
  const [formData, setFormData] = useState({
    leadTechnician: scheduleVisit.technicians.find(t => t.type === 'lead-tech'),
    helperTechnicians: scheduleVisit.technicians.filter(t => t.type !== 'lead-tech'),
    selectedDate: moment(scheduleVisit.date_and_time),
    selectedDateEnd: moment(scheduleVisit.date_and_time_end),
    notes: scheduleVisit.notes
  });

  const handleFieldChange = (fieldName, value) => {
    setFormData(prevData => ({
      ...prevData,
      [fieldName]: value
    }));
  };

  const addHelperTechnician = () => {
    setFormData({
      ...formData,
      helperTechnicians: [...formData.helperTechnicians, null]
    });
  };

  const removeHelperTechnician = (index: number) => {
    const copy = [...formData.helperTechnicians];
    copy.splice(index, 1);
    setFormData({
      ...formData,
      helperTechnicians: copy
    });
  };

  const handleSubmit = () => {
    const techniciansData = [
      { id: formData.leadTechnician.id, type: 'lead-tech' },
      ...formData.helperTechnicians.map(tech => ({
        id: tech.id,
        type: 'helper-tech'
      }))
    ];

    const scheduleData = {
      technicians: techniciansData,
      date_and_time: formData.selectedDate,
      date_and_time_end: formData.selectedDateEnd,
      notes: formData.notes
    };

    onSubmit(workOrderId, scheduleVisit.id, scheduleData);
    onHide();
  };

  const areValidData = () => {
    const allHelperTechniciansSelected = formData.helperTechnicians.every(
      technician => technician !== null && technician?.id
    );
    return (
      !!formData.selectedDate &&
      !!formData.leadTechnician &&
      allHelperTechniciansSelected
    );
  };

  const isEndTimeValid = () => {
    const start = moment(formData.selectedDate);

    const end = moment(formData.selectedDate).set({
      hour: moment(formData.selectedDateEnd).hour(),
      minute: moment(formData.selectedDateEnd).minute()
    });

    return end.isAfter(start) && end.diff(start, 'minutes') >= 60;
  };

  return (
    <Form>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        className="update-work-order-schedule-modal">
        <Modal.Header
          className="px-card border-0 header-service-area"
          closeButton>
          <Modal.Title>
            <FontAwesomeIcon
              icon={faEdit}
              className="work-order-icon"
              style={{ marginRight: '0.5rem' }}
            />
            Update Schedule Visit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-card py-0">
          <div className="work-order d-flex flex-column">
            <div className="mt-3 mb-3 col-md-12 d-flex flex-column">
              <FormLabel>Start</FormLabel>
              <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="en-us">
              <MobileDateTimePicker
                value={formData.selectedDate}
                onChange={selected => {
                  setFormData(prevData => ({
                    ...prevData,
                    selectedDate: selected,
                    selectedDateEnd: moment(selected).add(2, 'hours')
                  }));
                }}
              />
            </LocalizationProvider>
            </div>
            <div className="mt-3 mb-3 col-md-12 d-flex flex-column">
              <FormLabel>End</FormLabel>
              <LocalizationProvider
                dateAdapter={AdapterMoment}
                adapterLocale="en-us">
                <MobileTimePicker
                  value={formData.selectedDateEnd}
                  onChange={selected =>
                    handleFieldChange('selectedDateEnd', selected)
                  }
                />
              </LocalizationProvider>
              {!isEndTimeValid() && (
                <span className="text-danger">
                  The end time must be at least one hour after the start time.
                </span>
              )}
            </div>
            <div className="mt-3 mb-3">
              <FormLabel>Notes</FormLabel>
              <textarea
                required={true}
                title="Complete this field"
                className="form-control"
                placeholder="Optional notes"
                name="Optional notes"
                id="Optional notes_details"
                value={formData.notes}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                  handleFieldChange('notes', e.target.value)
                }
                style={{ height: '128px' }}
              />
            </div>
            <div className="mt-3 mb-3 col-md-12">
              <FormLabel>Lead Technician</FormLabel>
              <FormControl
                as="select"
                name="leadTechnician"
                defaultValue={formData.leadTechnician?.id || ''}
                onChange={e => {
                  const selectedTech = technicians.find(
                    technician => +technician.id === +e.target.value
                  );
                  handleFieldChange('leadTechnician', selectedTech);
                }}
                required>
                <option value="">Select lead technician</option>
                {technicians.map((technician: any) => (
                  <option key={technician.id} value={technician.id} selected={formData.leadTechnician?.id == technician.id}>
                    {technician.technician_number} {technician.first_name} {technician.last_name}
                  </option>
                ))}
              </FormControl>
            </div>

            {formData.helperTechnicians.length > 0 && (
              <div className="mb-1 col-md-12">
                <FormLabel>Helper Technicians</FormLabel>
              </div>
            )}

            {formData.helperTechnicians.map((technician, index) => (
              <div key={index} className="mb-2 col-md-12">
                <div className="d-flex align-items-center gap-2">
                  <FormControl
                    as="select"
                    name="helperTechnician"
                    value={technician?.id || ''}
                    onChange={e => {
                      const copy = [...formData.helperTechnicians];
                      copy[index] = technicians.find(
                        technician => +technician.id === +e.target.value
                      );
                      setFormData({
                        ...formData,
                        helperTechnicians: copy
                      });
                    }}>
                    <option value="">Select helper technician</option>
                    {technicians
                      .filter(
                        technician =>
                          technician.id !== formData.leadTechnician?.id &&
                          !formData.helperTechnicians.some(
                            (helper, i) =>
                              helper?.id === technician.id && i !== index
                          )
                      )
                      .map((technician: any) => (
                        <option key={technician.id} value={technician.id}>
                          {technician.technician_number} {technician.first_name}{' '}
                          {technician.last_name}
                        </option>
                      ))}
                  </FormControl>
                  <span
                    className="cursor-pointer text-danger"
                    onClick={() => removeHelperTechnician(index)}>
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </span>
                </div>
                {!technician?.id && (
                  <span className="fs-error-msg text-danger">
                    Remove or complete this field.
                  </span>
                )}
              </div>
            ))}

            <div className="d-flex justify-content-end">
              <Button
                className="add-technician-button me-2"
                variant="link"
                disabled={
                  technicians.length === formData.helperTechnicians.length + 1
                }
                onClick={addHelperTechnician}>
                Add helper technician
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end align-items-center border-0">
          <Button variant="secondary" onClick={onHide}>
            Cancel
          </Button>
          <Button
            variant="primary"
            className="px-4"
            disabled={!areValidData() || !isEndTimeValid()}
            onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

export default UpdateWorkOrderScheduleModal;
