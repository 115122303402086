import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Form, Modal, Button, FormLabel, InputGroup } from 'react-bootstrap';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';

interface User {
  email: string;
  password: string;
  fullname: string;
  confirmPassword: string;
}

const CreateUser = ({ closeModalAction }: { closeModalAction: () => void; }) => {
  const { createUser } = useWorkOrderContext();
  const [formData, setFormData] = useState<User>({
    email: '',
    password: '',
    fullname: '',
    confirmPassword: ''
  });
  const [passwordError, setPasswordError] = useState<string | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setPasswordError('Passwords do not match.');
      return;
    }

    try {
      await createUser(formData.email, formData.password, formData.fullname);
   
      closeModalAction();
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  const handleCopyPassword = () => {
    navigator.clipboard.writeText(formData.password)
      .then(() => {
        console.log('Password copied to clipboard');
      })
      .catch(err => {
        console.error('Failed to copy password: ', err);
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Modal.Body className="p-card py-0">
        <Form.Group controlId="formEmail">
          <FormLabel>Email <span style={{ color: 'red' }}>*</span></FormLabel>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formFullname">
          <FormLabel>Fullname <span style={{ color: 'red' }}>*</span></FormLabel>
          <Form.Control
            type="fullname"
            name="fullname"
            value={formData.fullname}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formPassword" className="mt-3">
          <FormLabel>Password <span style={{ color: 'red' }}>*</span></FormLabel>
          <InputGroup>
            <Form.Control
              type="text"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <Button variant="outline-secondary" onClick={handleCopyPassword}>
              Copy
            </Button>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="formConfirmPassword" className="mt-3">
          <FormLabel>Confirm Password <span style={{ color: 'red' }}>*</span></FormLabel>
          <Form.Control
            type="text"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            isInvalid={formData.confirmPassword?.length >= formData.password?.length && formData.password !== formData.confirmPassword}
          />
          <Form.Control.Feedback type="invalid">
            {formData.confirmPassword?.length >= formData.password?.length && formData.password !== formData.confirmPassword && 'Passwords doesnt match'}
          </Form.Control.Feedback>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center border-0">
        <Button variant="secondary" onClick={closeModalAction} className="me-2">
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={
          formData.confirmPassword !== formData.password || !formData.email || !formData.fullname
        }>
          Create
        </Button>
      </Modal.Footer>
    </Form>
  );
};

export default CreateUser;