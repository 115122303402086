import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Timeline from 'components/base/Timeline';
import { TimelineItem } from 'data/timelineData';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './BasicTimeline.scss';
import { formatAsDateAndTime } from 'helpers/date-utils';
import useLightbox from 'hooks/useLightbox';
import Lightbox from 'components/base/LightBox';

const BasicTimeline = ({ data }: { data: TimelineItem[] }) => {
  return (
    <Timeline variant="basic" className="mb-5">
      {data.map((item, index) => {
        const { lightboxProps, openLightbox } = useLightbox(
          item.action_type === 'onsite_technician_action' ? item.images : []
        );
        return (
          <Timeline.Item key={item.id} className="timeline-item">
            <Lightbox {...lightboxProps} />
            <Row className="g-3">
              <Col xs="auto">
                <Timeline.Separator className="position-relative">
                  <Timeline.Dot className="icon-item-md border bg-soft">
                    <FontAwesomeIcon
                      icon={item.icon}
                      className={`fs-9,5 text-${item.iconColor}`}
                    />
                  </Timeline.Dot>
                  {index !== data.length - 1 && (
                    <Timeline.Bar className="h-100 border-dashed border-400" />
                  )}
                </Timeline.Separator>
              </Col>
              <Col>
                <Timeline.Content>
                  <div className="d-flex justify-content-between">
                    <div className="text-500 fs-9,5 text-nowrap timeline-time mb-2">
                      {formatAsDateAndTime(item.created_at)}
                    </div>
                  </div>
                  {/* <h5
        className={classNames('fs-10 fw-normal', {
          'mb-3': index !== data.length - 1
        })}>
        by{' '}
        <Link to="#!" className="fw-semi-bold">
          {item.tasker}
        </Link>
      </h5> */}
                  {/* <p
        className={classNames('fs-9,5 text-800 w-sm-60 mb-0', {
          'mb-1': index !== data.length - 1
        })}>
        {item.content}
      </p>
      {item.checkinCheckoutMessage && (
        <p className="fs-9,5 text-800 mb-0">
          <strong>Checkin/Checkout:</strong>{' '}
          {item.checkinCheckoutMessage}
          <a href="#" onClick={openModal}>
            See the photos
          </a>
        </p>
      )} */}
                  {item.content && (
                    <div className="d-flex align-items-center">
                      <span className="fs-9,5 text-800 mb-0">
                        {item.content}
                      </span>
                      {item.action_type === 'onsite_technician_action' &&
                        item.images?.length > 0 && (
                          <Link style={{ marginLeft: '10px', marginTop: '1px'}} to="#!" onClick={() => openLightbox()}>
                            See photos
                          </Link>
                        )}
                    </div>
                  )}
                  {/* {item.afterMessage && (
        <p className="fs-9,5 text-800 mb-0">
          <strong>After:</strong> {item.afterMessage}
        </p>
      )}
      {item.residentMissedAppointmentMessage && (
        <p className="fs-9,5 text-800 mb-0">
          <strong>Resident Missed Appointment:</strong>{' '}
          {item.residentMissedAppointmentMessage}
          <a href="#" onClick={openModal}>
            See the evidence
          </a>
        </p>
      )}
      {item.technicianQuoteSentMessage && (
        <p className="fs-9,5 text-800 mb-0">
          <strong>Technician Quote Sent:</strong>{' '}
          {item.technicianQuoteSentMessage}
          <a href="#">See the quote</a>
        </p>
      )}
      {item.invoiceCreatedMessage && (
        <p className="fs-9,5 text-800 mb-0">
          <strong>Invoice Created:</strong>{' '}
          <a href="#">View Invoice</a>
        </p>
      )}
      {item.returnTripNeededMessage && (
        <p className="fs-9,5 text-800 mb-0">
          <strong>Return Trip Needed:</strong>{' '}
          {item.returnTripNeededMessage}
        </p>
      )} */}
                </Timeline.Content>
              </Col>
            </Row>
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
};

export default BasicTimeline;
