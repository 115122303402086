import React, { useState, useEffect } from 'react';
import { Form, Modal, FormLabel, Button } from 'react-bootstrap';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import DatePicker from 'components/base/DatePicker';

interface Technician {
  id?: number;
  technician_number?: string;
  first_name?: string;
  last_name?: string;
  user_id?: number | null;
  start_date?: string | null;
  email?: string;
}

interface EditNewTechniciansProps {
  closeModalAction: () => void;
  technician: Partial<Technician>;
}

const EditNewTechnicians: React.FC<EditNewTechniciansProps> = ({
  closeModalAction,
  technician
}) => {
  const { updateTechnician, getTechnicians } = useWorkOrderContext();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [formData, setFormData] = useState<Technician>({
    id: technician.id,
    technician_number: technician.technician_number,
    first_name: technician.first_name,
    last_name: technician.last_name,
    user_id: technician.user_id,
    start_date: technician.start_date || null,
    email: null
  });

  useEffect(() => {
    setFormData(technician);
  }, [technician]);

  const handleStartDateChange = (date: Date[]) => {
    const formattedDate =
      date.length > 0 ? date[0].toISOString().split('T')[0] : null;
    setFormData(prevState => ({ ...prevState, start_date: formattedDate }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormSubmitted(true);
    try {
      await updateTechnician(formData.id, {
        first_name: formData.first_name,
        last_name: formData.last_name,
        technician_number: formData.technician_number,
        start_date: formData.start_date || null,
        email: formData.email
      });
      closeModalAction();
      await getTechnicians();
    } catch (error) {
      console.error('Error updating technician:', error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Modal.Body className="p-card py-0">
        <div className="work-order">
          <div className="mt-3 mb-3">
            <FormLabel>
              Technician Number <span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <input
              title="Complete this field"
              type="text"
              name="technician_number"
              value={formData.technician_number}
              onChange={handleChange}
              className="form-control"
              placeholder="Enter technician number"
              required
            />
          </div>
          <div className="mt-3 mb-3">
            <FormLabel>
              First Name <span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <input
              title="Complete this field"
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              className="form-control"
              placeholder="Enter first name"
              required
            />
          </div>
          <div className="mt-3 mb-3">
            <FormLabel>
              Last Name <span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <input
              title="Complete this field"
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              className="form-control"
              placeholder="Enter last name"
            />
          </div>
          <div className="mt-3 mb-3">
            <FormLabel>
              Email <span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <input
              title="Complete this field"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="form-control"
              placeholder="Enter email"
              required
            />
          </div>
          <div className="mt-3 mb-3">
            <FormLabel>Start Date</FormLabel>
            <DatePicker
              render={(_, ref) => (
                <div style={{ position: 'relative' }}>
                  <input
                    type="text"
                    ref={ref}
                    className="form-control"
                    placeholder="H:i"
                    style={{ paddingRight: '35px' }}
                    value={formData.start_date}
                  />
                </div>
              )}
              onChange={handleStartDateChange}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center border-0">
        <Button variant="primary" className="px-4" type="submit">
          Save
        </Button>
      </Modal.Footer>
    </Form>
  );
};

export default EditNewTechnicians;
