import { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import ServiceAreaAbm from './service-areas';
import TechiniciansAbm from './technicians';
import UserAbmTable from '../../components/tables/UserAbmTable';
import ClientAbm from './clients';
import './index.css';
import ServiceCategoryAbm from 'pages/dashboard/dashboard-pages-tab/Service-Category-abm';

const Data = () => {
  const [activeABM, setActiveABM] = useState('clients');

  const components: Record<string, any> = {
    serviceAreas: <ServiceAreaAbm />,
    technicians: <TechiniciansAbm />,
    clients: <ClientAbm />,
    serviceCategories: <ServiceCategoryAbm />,
    createUser: <UserAbmTable />
  };

  const handleItemClick = (key: string) => {
    setActiveABM(key);
    const url = new URL(
      window.location.href.replace(window.location.search, '')
    );
    url.searchParams.set(key, '');
    window.history.pushState({}, '', url);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const activeItem = searchParams.get('active');
    if (activeItem) {
      setActiveABM(activeItem);
    }
  }, []);

  return (
    <div className="d-flex row border abms-container">
      <div className="col-md-2 justify-content-start mt-3 mb-3">
        <ListGroup variant="flush">
          <ListGroup.Item
            className="List-Button-Data"
            action
            active={activeABM === 'clients'}
            onClick={() => handleItemClick('clients')}
            style={{
              backgroundColor: activeABM === 'clients' ? '#5f1ed0' : '',
              borderBottomLeftRadius: activeABM === 'clients' ? '0.400rem' : '',
              borderBottomRightRadius: activeABM === 'clients' ? '0.400rem' : ''
            }}>
            Clients
          </ListGroup.Item>
          <ListGroup.Item
            className="List-Button-Data"
            action
            active={activeABM === 'serviceAreas'}
            onClick={() => handleItemClick('serviceAreas')}
            style={{
              backgroundColor: activeABM === 'serviceAreas' ? '#5f1ed0' : '',
              borderBottomLeftRadius:
                activeABM === 'serviceAreas' ? '0.400rem' : '',
              borderBottomRightRadius:
                activeABM === 'serviceAreas' ? '0.400rem' : ''
            }}>
            Service areas
          </ListGroup.Item>
          <ListGroup.Item
            className="List-Button-Data"
            action
            active={activeABM === 'serviceCategories'}
            onClick={() => handleItemClick('serviceCategories')}
            style={{
              backgroundColor:
                activeABM === 'serviceCategories' ? '#5f1ed0' : '',
              borderBottomLeftRadius:
                activeABM === 'serviceCategories' ? '0.400rem' : '',
              borderBottomRightRadius:
                activeABM === 'serviceCategories' ? '0.400rem' : ''
            }}>
            Service categories
          </ListGroup.Item>
          <ListGroup.Item
            className="List-Button-Data"
            action
            active={activeABM === 'technicians'}
            onClick={() => handleItemClick('technicians')}
            style={{
              backgroundColor: activeABM === 'technicians' ? '#5f1ed0' : '',
              borderBottomLeftRadius:
                activeABM === 'technicians' ? '0.400rem' : '',
              borderBottomRightRadius:
                activeABM === 'technicians' ? '0.400rem' : ''
            }}>
            Technicians
          </ListGroup.Item>
          <ListGroup.Item
            className="List-Button-Data"
            action
            active={activeABM === 'createUser'}
            onClick={() => handleItemClick('createUser')}
            style={{
              backgroundColor: activeABM === 'createUser' ? '#5f1ed0' : '',
              borderBottomLeftRadius:
                activeABM === 'createUser' ? '0.400rem' : '',
              borderBottomRightRadius:
                activeABM === 'createUser' ? '0.400rem' : ''
            }}>
            Users
          </ListGroup.Item>
        </ListGroup>
      </div>
      <div className="col-md-9">{components[activeABM]}</div>
    </div>
  );
};

export default Data;
