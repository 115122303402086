import { useState, useEffect } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Modal } from 'react-bootstrap';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';

import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Button from 'components/base/Button';
import { disableUser, enableUser, getAdminUsers } from 'api/users';
import CreateUser from './CreateUser';

interface User {
  id: number;
  email: string;
  enabled: boolean;
  fullname: string;
}

const ServiceAreaAbmTable = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [users, setUsers] = useState([]);

  const loadUsers = async () => {
    const _users = await getAdminUsers();
    setUsers(_users);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const handleCreate = () => {
    setShowCreateModal(true);
  };

  const handleCloseModal = () => {
    setShowCreateModal(false);
    loadUsers();
  };

  const disableEnableUser = async (user: User) => {
    if (user.enabled) {
      await disableUser(user.id);
    } else {
      await enableUser(user.id);
    }
    loadUsers();
  };

  const usersTableColumns: ColumnDef<User>[] = [
    {
      accessorKey: 'email',
      header: 'Email',
      cell: ({ row: { original } }) => original.email,
      meta: {
        headerProps: { style: { width: '20%' }, className: 'text-center pe-7' },
        cellProps: { className: 'text-center fw-semi-bold text-1000 pe-7' }
      }
    },
    {
      accessorKey: 'fullname',
      header: 'Fullname',
      cell: ({ row: { original } }) => original.fullname,
      meta: {
        headerProps: { style: { width: '20%' }, className: 'text-center pe-7' },
        cellProps: { className: 'text-center fw-semi-bold text-1000 pe-7' }
      }
    },
    {
      accessorKey: 'enabled',
      header: 'Enabled',
      cell: ({ row: { original } }) => original.enabled ? 'YES' : 'NO',
      meta: {
        headerProps: { style: { width: '20%' }, className: 'text-center pe-7' },
        cellProps: { className: 'text-center fw-semi-bold text-1000 pe-7' }
      }
    },
    {
      id: 'action',
      header: 'Actions',
      cell: ({ row: { original } }) => (
        <div className="d-flex justify-content-start">
          <Button
            variant="primary"
            className="m-2"
            size="sm"
            onClick={() => disableEnableUser(original)}>
            {original.enabled ? 'Disable user' : 'Enable user' }
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '25%', paddingLeft: '1rem' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: users,
    columns: usersTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  const Modals = () => {
    return (
      <>
        <Modal show={showCreateModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Create New admin user</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateUser closeModalAction={handleCloseModal} />
          </Modal.Body>
        </Modal>
      </>
    );
  };

  return (
    <>
      <Modals />
      <h3 style={{ textAlign: 'center', marginTop: '30px'}} >Users</h3>
      <AdvanceTableProvider {...table}>
        <div className="border-y">
          <div
            className="d-flex flex-wrap gap-3 justify-content-end"
            style={{ paddingRight: '2rem' }}>
            <Button
              variant="success"
              size="sm"
              className="mt-3"
              onClick={() => handleCreate()}>
              Create new admin user
            </Button>
          </div>

          <AdvanceTable
            tableProps={{ className: 'phoenix-table table-sm fs-9' }}
          />
          <AdvanceTableFooter pagination showViewAllBtn={false} />
        </div>
      </AdvanceTableProvider>
    </>
  );
};

export default ServiceAreaAbmTable;
