import {
  SearchWorkOrdersParams,
  SearchWorkOrdersSimpleParams,
  WorkOrder
} from 'api/types/work-orders';
import {
  Dispatch,
  PropsWithChildren,
  createContext,
  useContext,
  useReducer
} from 'react';
import {
  CREATE_CLIENT,
  CREATE_SERVICE_AREA,
  CREATE_SERVICE_CATEGORIES,
  CREATE_TECHNICIAN,
  CREATE_WORK_ORDER,
  GET_CLIENTS,
  GET_SCHEDULED_WORK_ORDERS,
  GET_SERVICE_AREAS,
  GET_SERVICE_CATEGORIES,
  GET_TECHNICIANS,
  GET_WORK_ORDERS,
  SCHEDULING_VISIT,
  SET_SELECTED_WORK_ORDER,
  UPDATE_SERVICE_AREA,
  UPDATE_SERVICE_CATEGORIES,
  UPDATE_TECHNICIAN,
  VISIT_SCHEDULED,
  WORK_ORDERS_ACTION_TYPE,
  UPDATE_CLIENT,
  workOrderReducer,
  FIND_ORDER_BY_CODE,
  UPDATE_WORK_ORDER_SCHEDULE,
  SET_PAGE,
  UPDATE_WORK_ORDER,
  CREATE_USER,
  WORK_ORDER_FOUND,
  FINDING_WORK_ORDER,
  GET_TODAY_WORK_ORDERS,
  LOADING_WORK_ORDERS,
  SET_WORK_ORDER_IMAGES,
  GET_ON_MAP_WORK_ORDERS,
  SET_CURRENT_PARAMS,
  GET_WORK_ORDERS_HISTORY,
  SET_SNOOZED_COUNT,
  GET_ON_LIST_WORK_ORDERS
} from 'reducers/WorkOrdersReducer';

import api from '../api/work-orders';

import axios from 'axios';

const A_LOT = 10000000000;
export interface WorkOrderState {
  todayWorkOrders: any;
  serviceAreas: any[];
  serviceCategories: any[];
  workOrders: any[];
  technicians: any[];
  client: any[];
  clients: any[];
  selectedWorkOrder: WorkOrder;
  scheduledWorkOrders: any[];
  schedulingVisit: boolean;
  filters: any;
  groups: any;
  workOrderFound: any;
  workOrderFullDetailFound: any;
  loadingFullDetailWorkOrder: boolean;
  currentPage: number;
  totalPages: number;
  totalItems: number;
  appliedFilters: any;
  user: any;
  loadingWorkOrders: boolean;
  workOrdersOnMap: any[];
  workOrdersOnCalendar: any[];
  workOrdersOnList: any[];
  workOrdersHistory: any;
  snoozedCount: any;
}

export interface TechnicianData {
  first_name: string;
  last_name: string;
  technician_number: string;
  start_date: string | null;
  email: string;
}

export interface ValidateWOCodeResponse {
  status: number;
  data: {
    isValid: boolean;
    message?: string;
  };
}

interface WorkOrderContextInterface extends WorkOrderState {
  workOrderDispatch: Dispatch<WORK_ORDERS_ACTION_TYPE>;
  dispatchShowMapModal: Dispatch<any>;
  getServiceAreas: () => Promise<void>;
  getServiceCategories: () => Promise<void>;
  createWorkOrder: (data: any, onOk: () => void) => Promise<void>;
  createServiceArea: (data: any) => Promise<void>;
  createServiceCategory: (data: any) => Promise<void>;
  getTechnicians: () => Promise<void>;
  searchWorkOrders: (params?: SearchWorkOrdersParams) => Promise<void>;
  searchWorkOrdersSimple: (
    params?: SearchWorkOrdersSimpleParams
  ) => Promise<void>;
  searchWorkOrdersScheduled: (params?: SearchWorkOrdersParams) => Promise<void>;
  updateServiceArea: (serviceAreaId: number, serviceArea: any) => Promise<void>;
  setSelectedWorkOrder: (workOrder: WorkOrder) => Promise<void>;
  createClient: (data: any) => Promise<void>;
  getClients: () => Promise<void>;
  updateWorkOrder: (
    workOrderId: string,
    updatedData: Partial<WorkOrder>,
    onOk: () => void
  ) => Promise<void>;

  createTechnician: (technicianData: TechnicianData) => Promise<void>;

  updateTechnician: (
    technicianId: number,
    technicianData: TechnicianData
  ) => Promise<void>;
  updateClient: (clientId: number, clientData: any) => Promise<void>;
  updateServiceCategory: (
    serviceCategoryId: number,
    serviceCategory: any
  ) => Promise<void>;
  scheduleVisit: (params: {
    workOrderId: number;
    technicians: { id: number; type: 'lead-tech' | 'helper-tech' }[];
    date: string;
    dateEnd: string;
    notes: string;
  }) => Promise<void>;
  findWorkOrderByCode: (code: string) => Promise<any>;
  updateWorkOrderSchedule: (
    workOrderId: number,
    scheduleId: number,
    schedule: {
      technicians: { id: number; type: string }[];
      date_and_time: string;
      date_and_time_end: string | null;
      notes: string;
    }
  ) => Promise<void>;
  createUser: (
    email: string,
    password: string,
    fullname: string
  ) => Promise<void>;
  getFullDetailWorkOrderByCode: (code: string) => Promise<any>;
  snoozeWorkOrder: (workOrderId: number, reason: string) => Promise<any>;
  cancelWorkOrder: (workOrderId: number, reason: string) => Promise<any>;
  reactivateWorkOrder: (workOrderId: number) => Promise<any>;
  approveRejectQuote: (
    quoteId: number,
    approveOrReject: 'approve' | 'reject',
    rejectReason?: string
  ) => Promise<any>;
  searchCalendarWorkOrders: (params?: SearchWorkOrdersParams) => Promise<void>;
  searchMapWorkOrders: (params?: SearchWorkOrdersParams) => Promise<void>;
  searchTodayWorkOrders: (params?: SearchWorkOrdersParams) => Promise<void>;
  changeServiceArea: (
    serviceAreaId: number,
    view: 'list' | 'map' | 'calendar'
  ) => Promise<void>;
  getTotalSnoozedCount: () => Promise<void>;
  validateWOCode: (
    workOrderCode: string
  ) => Promise<ValidateWOCodeResponse | null>;
  uploadImage: ( formData: FormData) => Promise<string>;
}

export const WorkOrderContext = createContext({} as WorkOrderContextInterface);

const WorkOrderProvider = ({ children }: PropsWithChildren) => {
  const initialState: WorkOrderState = {
    serviceAreas: [],
    serviceCategories: [],
    workOrders: [],
    technicians: [],
    client: [],
    clients: [],
    selectedWorkOrder: null,
    scheduledWorkOrders: [],
    schedulingVisit: false,
    filters: {},
    groups: {},
    workOrderFound: null,
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    appliedFilters: {},
    user: null,
    workOrderFullDetailFound: null,
    loadingFullDetailWorkOrder: false,
    todayWorkOrders: {
      filters: {},
      totalItems: 0,
      currentPage: 1,
      totalPages: 0,
      workOrders: []
    },
    loadingWorkOrders: false,
    workOrdersOnMap: [],
    workOrdersOnCalendar: [],
    workOrdersOnList: [],
    workOrdersHistory: {
      filters: {},
      totalItems: 0,
      currentPage: 1,
      totalPages: 0,
      workOrders: []
    },
    snoozedCount: []
  };

  const [workOrderState, workOrderDispatch] = useReducer(
    workOrderReducer,
    initialState
  );

  const dispatchShowMapModal = (residenceAddress: any) => {};

  const createUser = async (
    email: string,
    password: string,
    fullname: string
  ) => {
    try {
      const user = await api.createUser(email, password, fullname);
      workOrderDispatch({ payload: user, type: CREATE_USER });
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  const getServiceAreas = async () => {
    const serviceAreas = await api.getServiceAreas();
    workOrderDispatch({ payload: serviceAreas, type: GET_SERVICE_AREAS });
  };

  const getServiceCategories = async () => {
    const serviceCategories = await api.getServiceCategories();
    workOrderDispatch({
      payload: serviceCategories,
      type: GET_SERVICE_CATEGORIES
    });
  };

  const createServiceCategory = async (data: any) => {
    try {
      const newServiceCategory = await api.createServiceCategory(data);
      workOrderDispatch({
        payload: newServiceCategory,
        type: CREATE_SERVICE_CATEGORIES
      });
    } catch (error) {
      console.error('Error creating service category:', error);
    }
  };

  const updateWorkOrderSchedule = async (
    workOrderId: number,
    scheduleId: number,
    schedule: {
      technicians: { id: number; type: string }[];
      date_and_time: string;
      date_and_time_end: string;
      notes: string;
    }
  ) => {
    try {
      const updatedSchedule = await api.updateWorkOrderSchedule(
        workOrderId,
        scheduleId,
        schedule
      );
      workOrderDispatch({
        payload: updatedSchedule,
        type: UPDATE_WORK_ORDER_SCHEDULE
      });
    } catch (error) {
      console.error('Error updating work order schedule:', error);
    }
  };

  const searchTodayWorkOrders = async (
    params: SearchWorkOrdersParams = { page: 1, perPage: 10 }
  ) => {
    const response = await searchWorkOrdersOnApi(params, workOrderState);

    workOrderDispatch({
      payload: response,
      type: GET_TODAY_WORK_ORDERS
    });
  };

  const changeServiceArea = async (
    serviceAreaId: number,
    view: 'list' | 'map' | 'calendar'
  ) => {
    const params = {
      page: workOrderState.currentPage,
      perPage: view === 'list' ? 10 : A_LOT,
      ...workOrderState.appliedFilters,
      serviceAreaIds: [serviceAreaId]
    };
    const response = await searchWorkOrdersOnApi(params, workOrderState);

    let type = GET_WORK_ORDERS;
    if (view === 'calendar') {
      type = GET_SCHEDULED_WORK_ORDERS;
    } else if (view === 'map') {
      type = GET_ON_MAP_WORK_ORDERS;
    }

    workOrderDispatch({
      payload: response,
      type
    });
  };

  const searchMapWorkOrders = async (
    params: SearchWorkOrdersParams = { page: 1, perPage: A_LOT }
  ) => {
    const response = await searchWorkOrdersOnApi(
      {
        ...workOrderState.appliedFilters,
        ...params,
        page: 1,
        perPage: A_LOT
      },
      workOrderState
    );

    workOrderDispatch({
      payload: response,
      type: GET_ON_MAP_WORK_ORDERS
    });
  };

  const searchCalendarWorkOrders = async (
    params: SearchWorkOrdersParams = { page: 1, perPage: A_LOT }
  ) => {
    searchWorkOrdersScheduled(params);
  };

  const searchWorkOrders = async (
    params: SearchWorkOrdersParams = { page: 1, perPage: 10 },
    fetchImages: boolean = true
  ) => {
    workOrderDispatch({
      type: LOADING_WORK_ORDERS
    });

    const response = await searchWorkOrdersOnApi(params, workOrderState);

    workOrderDispatch({
      payload: params,
      type: SET_CURRENT_PARAMS
    });

    workOrderDispatch({
      payload: response,
      type: GET_WORK_ORDERS
    });

    if (fetchImages) {
      api
        .getWorkOrderImages(response.workOrders.map(w => w.id))
        .then(images => {
          workOrderDispatch({
            payload: images,
            type: SET_WORK_ORDER_IMAGES
          });
        });
    }

    return response;
  };

  const searchWorkOrdersSimple = async (
    params: SearchWorkOrdersSimpleParams = { page: 1, perPage: 10 }
  ) => {
    workOrderDispatch({
      type: LOADING_WORK_ORDERS
    });

    const response = await api.searchWorkOrdersSimple(params);

    workOrderDispatch({
      payload: {
        workOrders: response,
        currentPage: params.page
      },
      type: GET_WORK_ORDERS_HISTORY
    });
  };

  const updateWorkOrder = async (
    workOrderCode: string,
    updatedData: Partial<WorkOrder>,
    onOk: () => void
  ) => {
    try {
      const updatedWorkOrder = await api.updateWorkOrder(
        workOrderCode,
        updatedData
      );

      workOrderDispatch({ type: UPDATE_WORK_ORDER, payload: updatedWorkOrder });
    } catch (error) {
      console.error('Error updating work order:', error);
    }
    onOk();
  };

  const createWorkOrder = async (data: any, onOk: () => void) => {
    try {
      const newWorkOrder = await api.createWorkOrder(data);
      workOrderDispatch({ payload: newWorkOrder, type: CREATE_WORK_ORDER });
    } catch (error) {
      console.error('Error creating work order:', error);
    }
    onOk();
  };

  const createServiceArea = async (data: any) => {
    try {
      const newWorkOrder = await api.createServiceArea(data);
      workOrderDispatch({ payload: newWorkOrder, type: CREATE_SERVICE_AREA });
    } catch (error) {
      console.error('Error creating service area:', error);
    }
  };

  const createClient = async (data: any) => {
    try {
      const Client = await api.createClient(data);
      workOrderDispatch({ payload: Client, type: CREATE_CLIENT });
    } catch (error) {
      console.error('Error creating client:', error);
    }
  };

  const updateServiceArea = async (serviceAreaId: number, serviceArea: any) => {
    try {
      await api.updateServiceArea(serviceAreaId, serviceArea);
      workOrderDispatch({ payload: serviceArea, type: UPDATE_SERVICE_AREA });
    } catch (error) {
      console.error('Error updating service area:', error);
    }
  };

  const updateClient = async (clientId: number, clientData: any) => {
    try {
      await api.updateClient(clientId, clientData);
      workOrderDispatch({ payload: clientData, type: UPDATE_CLIENT });
    } catch (error) {
      console.error('Error updating client:', error);
    }
  };

  const getTechnicians = async () => {
    const technicians = await api.getTechnicians();

    workOrderDispatch({ payload: technicians, type: GET_TECHNICIANS });
  };

  const getClients = async () => {
    const clients = await api.getClients();
    clients;
    workOrderDispatch({ payload: clients, type: GET_CLIENTS });
  };

  const createTechnician = async (technicianData: TechnicianData) => {
    try {
      const newTechnician = await api.createTechnician(technicianData);
      workOrderDispatch({ payload: newTechnician, type: CREATE_TECHNICIAN });
    } catch (error) {
      console.error('Error creating technician:', error);
    }
  };

  const updateTechnician = async (
    technicianId: number,
    technicianData: TechnicianData
  ) => {
    try {
      await api.updateTechnician(technicianId, technicianData);

      workOrderDispatch({ payload: technicianData, type: UPDATE_TECHNICIAN });
    } catch (error) {
      console.error('Error updating technician:', error);
    }
  };

  const updateServiceCategory = async (
    categoryId: number,
    categoryData: any
  ) => {
    try {
      await api.updateServiceCategory(categoryId, categoryData);
      workOrderDispatch({
        payload: categoryData,
        type: UPDATE_SERVICE_CATEGORIES
      });
    } catch (error) {
      console.error('Error updating service category:', error);
    }
  };

  const searchWorkOrdersScheduled = async (params?: SearchWorkOrdersParams) => {
    const searchParams: SearchWorkOrdersParams = {
      ...workOrderState.appliedFilters,
      ...params,
      page: 1,
      perPage: A_LOT,
      scheduled: true
    };

    const data = await searchWorkOrdersOnApi(searchParams, workOrderState);

    workOrderDispatch({
      payload: data,
      type: GET_SCHEDULED_WORK_ORDERS
    });
  };

  const setSelectedWorkOrder = async (workOrder: WorkOrder) => {
    workOrderDispatch({ payload: workOrder, type: SET_SELECTED_WORK_ORDER });
  };

  const scheduleVisit = async ({
    workOrderId,
    technicians,
    date,
    dateEnd,
    notes
  }: {
    workOrderId: number;
    technicians: { id: number; type: 'lead-tech' | 'helper-tech' }[];
    date: string;
    dateEnd: string;
    notes: string;
  }) => {
    workOrderDispatch({ type: SCHEDULING_VISIT });
    await api.scheduleVisit({
      workOrderId,
      technicians,
      date,
      dateEnd,
      notes
    });
    workOrderDispatch({ type: VISIT_SCHEDULED });
    refreshWorkOrders();
  };

  const findWorkOrderByCode = async (code: string) => {
    try {
      const workOrder = await api.findWorkOrderByCode(code);
      workOrderDispatch({ payload: workOrder, type: FIND_ORDER_BY_CODE });
    } catch (error) {
      console.error('Error finding work order:', error);
    }
  };

  const getFullDetailWorkOrderByCode = async (code: string) => {
    try {
      workOrderDispatch({ type: FINDING_WORK_ORDER });
      const workOrder = await api.getFullDetailOrder(code);
      workOrderDispatch({ payload: workOrder, type: WORK_ORDER_FOUND });
      return workOrder;
    } catch (error) {
      console.error('Error finding work order:', error);
    }
  };

  const refreshWorkOrders = async () => {
    // Esta función se encarga de refrescar la lista de órdenes de trabajo
    const params = {
      page: workOrderState.currentPage,
      perPage: 10,
      ...workOrderState.appliedFilters
    };
    await searchWorkOrders(params);
  };

  const snoozeWorkOrder = async (workOrderId: number, reason: string) => {
    try {
      const response = await api.snoozeWorkOrder(workOrderId, reason);

      const updatedWorkOrder = {
        ...workOrderState.workOrders.find(wo => wo.id === workOrderId),
        status: 'snoozed'
      };

      workOrderDispatch({
        type: UPDATE_WORK_ORDER,
        payload: updatedWorkOrder // Envia un objeto individual
      });

      refreshWorkOrders();

      return response;
    } catch (error) {
      console.error('Error snoozing work order:', error);
    }
  };

  const reactivateWorkOrder = async (workOrderId: number) => {
    try {
      const response = await api.reactivateWorkOrder(workOrderId);

      const updatedWorkOrder = {
        ...workOrderState.workOrders.find(wo => wo.id === workOrderId),
        status: response.currentStatus
      };

      workOrderDispatch({
        type: UPDATE_WORK_ORDER,
        payload: updatedWorkOrder // Envia un objeto individual
      });

      refreshWorkOrders();

      return response;
    } catch (error) {
      console.error('Error reactivating work order:', error);
    }
  };

  const cancelWorkOrder = async (workOrderId: number, reason: string) => {
    try {
      const currentStatus = await api.cancelWorkOrder(workOrderId, reason);
      // actualizar work order en la lista daleeee
    } catch (error) {
      console.error('Error finding work order:', error);
    }
  };

  const getTotalSnoozedCount = async () => {
    try {
      const response = await api.getWorkOrdersFilters();
      if (response.status === 200 && typeof response.data === 'object') {
        workOrderDispatch({ type: SET_SNOOZED_COUNT, payload: response.data });
      }
    } catch (error) {
      console.error('Error al obtener el total de órdenes snoozed:', error);
    }
  };

  const approveRejectQuote = async (
    quoteId: number,
    approveOrReject: 'approve' | 'reject',
    rejectReason?: string
  ) => {
    try {
      await api.approveRejectQuote(quoteId, approveOrReject, rejectReason);
    } catch (error) {
      console.error('Error finding work order:', error);
    }
  };

  const validateWOCode = async (
    workOrderCode: string
  ): Promise<ValidateWOCodeResponse | null> => {
    try {
      const response = await api.findWorkOrderByCode(workOrderCode);
      if (response) {
        return response as ValidateWOCodeResponse;
      }
      return null;
    } catch (error) {
      console.error('Error validate work order code:', error);
      return null;
    }
  };

  const uploadImage = async (formData: FormData) => {
    try {
      const response = await api.uploadImage(formData);
      return response;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };
  
  return (
    <WorkOrderContext.Provider
      value={{
        ...workOrderState,
        setSelectedWorkOrder,
        getServiceAreas,
        getServiceCategories,
        workOrderDispatch,
        dispatchShowMapModal,
        createWorkOrder,
        searchWorkOrders,
        getTechnicians,
        createServiceArea,
        updateServiceArea,
        createClient,
        getClients,
        createTechnician,
        updateTechnician,
        createServiceCategory,
        updateServiceCategory,
        searchWorkOrdersScheduled,
        scheduleVisit,
        updateClient,
        findWorkOrderByCode,
        updateWorkOrderSchedule,
        updateWorkOrder,
        createUser,
        getFullDetailWorkOrderByCode,
        snoozeWorkOrder,
        cancelWorkOrder,
        reactivateWorkOrder,
        approveRejectQuote,
        searchCalendarWorkOrders,
        searchMapWorkOrders,
        searchTodayWorkOrders,
        changeServiceArea,
        searchWorkOrdersSimple,
        getTotalSnoozedCount,
        validateWOCode,
        uploadImage,
      }}>
      {children}
    </WorkOrderContext.Provider>
  );
};

export const useWorkOrderContext = () => useContext(WorkOrderContext);

export default WorkOrderProvider;

async function searchWorkOrdersOnApi(
  params: SearchWorkOrdersParams,
  workOrderState: WorkOrderState
) {
  const { page, perPage, filters, ...rest } = params;

  const paginationRequest =
    Object.keys(params).length === 2 &&
    Object.keys(params).filter(key => key === 'page' || key === 'perPage');

  if (paginationRequest) {
    params = { page, perPage, ...workOrderState.appliedFilters };
  }

  const response = await api.searchWorkOrders(params);

  const parsedResponse: any = {
    workOrders: response.workOrders,
    filters: response.filters,
    currentPage: page || 1,
    groups: response.groups,
    perPage,
    totalPages: Math.ceil(response.count / perPage),
    totalItems: response.count,
    snoozedReasons: params.snoozedReasons,
    appliedFilters:
      Object.keys(rest).length > 0 ? rest : workOrderState.appliedFilters,
    billingStatuses: params.billingStatuses
  };

  return parsedResponse;
}
