import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import { useState } from 'react';
import { Button, FormLabel, Modal } from 'react-bootstrap';

function RejectQuoteWithReasonModal({ show, handleClose, quoteId }) {
  const [reason, setReason] = useState('');
  const [rejecting, setRejecting] = useState(false);

  const { approveRejectQuote, getFullDetailWorkOrderByCode, workOrderFullDetailFound } = useWorkOrderContext();

  return (
    <Modal
      show={show}
      onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Rejection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <FormLabel>Reject reason</FormLabel>
          <textarea
            required={true}
            title="Add a reason to reject this quote so tech can see the reason"
            className="form-control"
            placeholder="Some reason"
            name="reject-reason"
            id="Optional notes_details"
            onChange={e => setReason(e.target.value)}
            style={{ height: '128px' }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={rejecting}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={async () => {
            setRejecting(true);
            try {
              await approveRejectQuote(quoteId, 'reject', reason);
              getFullDetailWorkOrderByCode(workOrderFullDetailFound.code);
              handleClose();
            } catch (err) {
              alert(err);
            } finally {
              setRejecting(false);
            }
          }}
          disabled={rejecting}>
          {rejecting ? 'Rejecting' : 'Reject'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RejectQuoteWithReasonModal;
