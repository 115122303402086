import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Form, Modal, FormLabel, Button } from 'react-bootstrap';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';

interface ServiceCategoryData {
  name: string;
}

const CreateNewServiceCategory = ({ closeModalAction }: { closeModalAction: () => void; }) => {
  const { createServiceCategory, getServiceCategories } = useWorkOrderContext();

  const [formData, setFormData] = useState<ServiceCategoryData>({
    name: ''
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await createServiceCategory(formData);
      await getServiceCategories(); 
      closeModalAction();
    } catch (error) {
      console.error('Error creating service category:', error);
    }
  };
  return (
    <Form onSubmit={handleSubmit}>
    
      <Modal.Body className="p-card py-0">
        <div className="mt-2 mb-3">
          <FormLabel>Service Category Name <span style={{ color: 'red' }}>*</span></FormLabel>
          <input
            title='Complete this field'
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="form-control"
            placeholder="Enter service category name"
            required
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center border-0">
        <Button variant="primary" className="px-4" type="submit">
          Save
        </Button>
      </Modal.Footer>
    </Form>
  );
};

export default CreateNewServiceCategory;