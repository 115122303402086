import { Form, FormLabel } from 'react-bootstrap';
import React, { useState } from 'react';
import { emails } from 'data/email';

const ResidentData = ({ formData, handleChange }) => {
  const [addAlternativeContact, setAddAlternativeContact] = useState(false);

  const onChangeAddAlternativeContact = e => {
    setAddAlternativeContact(e.currentTarget.checked);
  };
  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-center align-items-center">
        <hr style={{ height: '1px', width: '40%' }} />
        <h4 style={{ width: '20%', textAlign: 'center' }}>Resident data</h4>
        <hr style={{ height: '1px', width: '40%' }} />
      </div>
      <div className="row mt-2 mb-2">
        <h5>Resident</h5>
        <div className="col-6">
          <FormLabel>Full Name</FormLabel>
          <Form.Control
            required={true}
            title="Complete this field"
            type="text"
            placeholder="Resident Full Name"
            name="resident_full_name"
            value={formData.resident.full_name}
            onChange={handleChange}
          />
        </div>
        <div className="col-6 d-flex d-flex-row gap-2">
          <div className="col-6">
            <FormLabel>Phone</FormLabel>
            <Form.Control
              required={true}
              title="Complete this field"
              type="text"
              placeholder="Resident Phone"
              name="resident_phone"
              value={formData.resident.phone}
              onChange={handleChange}
            />
          </div>
          <div className="col-6">
            <FormLabel>Alternative Phone</FormLabel>
            <Form.Control
              required={true}
              type="text"
              title="Complete this field"
              placeholder="Resident Alternative Phone"
              name="resident_alternative_phone"
              value={formData.resident.alternative_phone}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="row mb-2 mt-2">
        <div className="col-6">
          <FormLabel type={emails}>Email</FormLabel>
          <Form.Control
            required={true}
            title="Complete this field"
            placeholder="Resident Email"
            name="resident_email"
            value={formData.resident.email}
            onChange={handleChange}
          />
        </div>
        <div className="col-6 mt-2">
          <FormLabel></FormLabel>
          <Form.Check
            onChange={onChangeAddAlternativeContact}
            label={'Add alternative contact'}
          />
        </div>
      </div>
      {addAlternativeContact && (
        <div className="row mt-4">
          <h5>Alternative contact</h5>
          <div className="col-4">
            <FormLabel>Full Name</FormLabel>
            <Form.Control
              type="text"
              title="Complete this field"
              placeholder="Full Name"
              name="alternativeContact_name"
              value={formData.alternativeContact.name}
              onChange={handleChange}
            />
          </div>
          <div className="col-4">
            <FormLabel>Phone</FormLabel>
            <Form.Control
              type="text"
              title="Complete this field"
              placeholder="Phone"
              name="alternativeContact_phone"
              value={formData.alternativeContact.phone}
              onChange={handleChange}
            />
          </div>
          <div className="col-4">
            <FormLabel>Email</FormLabel>
            <Form.Control
              type="email"
              title="Complete this field"
              placeholder="Email"
              name="alternativeContact_email"
              value={formData.alternativeContact.email}
              onChange={handleChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ResidentData;
