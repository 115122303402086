import CreateSchedule from 'components/forms/AddSchedule';
import { useModalsContext } from 'providers/ModalsProvider';
import { Modal } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const ScheduleWorkOrderModal = ({ onClose }) => {
  const { dispatchShowModalScheduleWorkOrder, showModalScheduleWorkOrder } =
    useModalsContext();

  const handleClose = () => {
    dispatchShowModalScheduleWorkOrder(false);
    if (onClose) onClose();
  };

  if (!showModalScheduleWorkOrder) {
    return null;
  }

  return (
    <Modal size="lg" show={showModalScheduleWorkOrder} onHide={handleClose}>
      <CreateSchedule closeModalAction={handleClose} />
    </Modal>
  );
};

export default ScheduleWorkOrderModal;
