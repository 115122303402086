import { Form, FormLabel, InputGroup, Row } from 'react-bootstrap';
import geocodeByAddress from 'helpers/maps-utils';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useEffect, useState } from 'react';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import './style.css';

const FirstStepData = ({
  clients,
  serviceCategories,
  serviceAreas,
  formData,
  handleChange,
  setFormData,
  codeExists,
  setCodeExists
}) => {
  const getClients = () => {
    return clients.filter(c => !c.parent_client_id);
  };

  const [results, setResults] = useState<google.maps.GeocoderResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [clientInitial, setClientInitial] = useState(''); 

  const handleAddressSelect = selectedOptions => {
    const selectedAddress = selectedOptions[0];
    if (!selectedAddress) return;
    setFormData({
      ...formData,
      residence_address: selectedAddress.label,
      residence_address_lat: selectedAddress.location.lat,
      residence_address_long: selectedAddress.location.lng
    });
  };

  const { validateWOCode } = useWorkOrderContext();

  const generateWOCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let code = '';
    for (let i = 0; i < 5; i++) {
      code += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return code; 
  };

  const validateAndSetWOCode = async () => {
    if (!formData.client_id) return;

    const newCode = generateWOCode();
    const woCodeWithInitial = `${clientInitial}-${newCode}`;

    try {
      const response = await validateWOCode(woCodeWithInitial);

      if (response === null) {
        setFormData({
          ...formData,
          code: woCodeWithInitial.trim()
        });
        setCodeExists(false);
      } else {
        setCodeExists(true);
      }
    } catch (error) {
      console.error('Error validating wo code', error);
    }
  };

  const handleIconClick = () => {
    validateAndSetWOCode();
  };

  useEffect(() => {
    if (formData.client_id) {
      const selectedClient = clients.find(
        client => client.id === Number(formData.client_id)
      );
      if (selectedClient) {
        const initial = selectedClient.name.charAt(0).toUpperCase();

        const existingCode = formData.code ? formData.code.slice(2) : '';
        setClientInitial(initial);
        setFormData({
          ...formData,
          code: `${initial}-${existingCode}`.trim()
        });
      }
    }
  }, [formData.client_id, clients]);

  const getSubClients = () => {
    const selectedClient = clients.find(
      client => client.id === Number(formData.client_id)
    );
  
    if (selectedClient && selectedClient.sub_clients) {
      return selectedClient.sub_clients;
    }
  
    return [{
      id: 1,
      logo_url: null,
      name: "Select client first"
    }];
  };

  const handleWOCodeChange = e => {
    const inputCode = e.target.value.slice(clientInitial.length + 1);
    setCodeExists(false);
    setFormData({
      ...formData,
      code: `${clientInitial}-${inputCode}`.trim()
    });
  };

  const handleBlur = async () => {
    if (formData.code) {
      try {
        const response = await validateWOCode(formData.code);
        if (response !== null) {
          setCodeExists(true);
        } else {
          setCodeExists(false);
        }
      } catch (error) {
        console.error('Error validating the code on onBlur', error);
      }
    }
  };

  return (
    <>
      <div className="work-order">
        <div className="d-flex justify-content-center align-items-center">
          <hr style={{ height: '1px', width: '40%' }} />
          <h4 style={{ width: '20%', textAlign: 'center' }}>First details</h4>
          <hr style={{ height: '1px', width: '40%' }} />
        </div>
        <div className="row mt-2 mb-2">
          <div className="col-6">
            <FormLabel>Priority *</FormLabel>
            <Form.Control
              as="select"
              required={true}
              title="Complete this field"
              name="priority"
              value={formData.priority}
              onChange={handleChange}>
              {!formData.priority && <option value="" disabled>Select priority</option>}
              <option key={'urgent'} value={'urgent'}>
                Urgent
              </option>
              <option key={'emergency'} value={'emergency'}>
                Emergency
              </option>
              <option key={'routine'} value={'routine'}>
                Routine
              </option>
            </Form.Control>
          </div>
          <div className="col-6">
            <FormLabel>Type *</FormLabel>
            <Form.Control
              as="select"
              required={true}
              title="Complete this field"
              name="type"
              value={formData.type}
              onChange={handleChange}>
              <option value="" disabled>Select type</option>
              <option key={'reactive'} value={'reactive'}>
                Reactive
              </option>
              <option key={'urgent'} value={'turns_and_rehabs'}>
                Turns & Rehabs
              </option>
            </Form.Control>
          </div>
        </div>
        <div className="row mt-2 mb-2">
          <Row>
            <div className="col-3">
              <FormLabel>Client *</FormLabel>
              <Form.Control
                required={true}
                title="Complete this field"
                as="select"
                name="client_id"
                value={formData.client_id}
                onBlur={handleBlur}
                onChange={handleChange}>
                {!formData.client_id && <option value="">Select client</option>}
                {getClients().map(client => (
                  <option key={client.id} value={client.id}>
                    {client.name}
                  </option>
                ))}
              </Form.Control>
            </div>
            <div className="col-3">
              <div>
                <FormLabel className={getSubClients().length === 0 ? 'disabled' : ''}>Sub Client</FormLabel>
                <Form.Control
                  title="Complete this field"
                  as="select"
                  name="sub_client_id"
                  value={formData.sub_client_id}
                  disabled={getSubClients().length === 0 || !formData.client_id}
                  onChange={handleChange}>
                    {getSubClients().length === 0 && <option value="">No sub clients</option>}
                    {!formData.sub_client_id && <option value="">Select sub client</option>}
                  {getSubClients().map(subClient => (
                    <option key={subClient.id} value={subClient.id}>
                      {subClient.name}
                    </option>
                  ))}
                </Form.Control>
              </div>
            </div>
            <div className="col-6">
              <FormLabel>WO Code * </FormLabel>
                <InputGroup hasValidation>
                <Form.Control
                  type="text"
                  name="code"
                  value={formData.code ? formData.code : ''}
                  onChange={handleWOCodeChange}
                  placeholder="Enter WO Code"
                  required
                  disabled={!formData.client_id}
                  onBlur={handleBlur}
                  className={codeExists ? 'is-invalid' : ''}
                />
                <Form.Control.Feedback type="invalid">
                  {codeExists ? 'Code already exists' : 'Complete this field'}
                </Form.Control.Feedback>
              </InputGroup>
            </div>
          </Row>
          {/* <div className="d-flex justify-content-center align-items-center col-1 w-cclass-button-generate-wo-code">
            <FontAwesomeIcon
              icon={faRotate}
              className={`text-primary ${isRotating ? 'rotate-icon' : ''}`} 
              onClick={handleIconClick}
              style={{ cursor: 'pointer' }}
            />
          </div> */}
        </div>
        <div className="mt-3 mb-3 row">
          <div className="col-3">
            <FormLabel>Service Category *</FormLabel>
            <Form.Control
              required={true}
              title="Complete this field"
              as="select"
              name="service_category_id"
              value={formData.service_category_id}
              onChange={handleChange}>
              {!formData.service_category_id && (
                <option value="">Select service category</option>
              )}
              {serviceCategories.map(serviceCategory => (
                <option key={serviceCategory.id} value={serviceCategory.id}>
                  {serviceCategory.name}
                </option>
              ))}
            </Form.Control>
          </div>
          <div className="col-3">
            <FormLabel>Service Area *</FormLabel>
            <Form.Control
              as="select"
              required={true}
              title="Complete this field"
              name="service_area_id"
              value={formData.service_area_id}
              onChange={handleChange}>
              {!formData.service_area_id && (
                <option value="">Select service area</option>
              )}
              {serviceAreas.map(serviceArea => (
                <option key={serviceArea.id} value={serviceArea.id}>
                  {serviceArea.name}
                </option>
              ))}
            </Form.Control>
          </div>
          <div className="col-6">
            <FormLabel>Residence Address *</FormLabel>
            <AsyncTypeahead
              id="residence_address"
              isLoading={loading}
              onChange={handleAddressSelect}
              filterBy={() => true}
              onSearch={async query => {
                setLoading(true);
                try {
                  const _results = await geocodeByAddress(query);
                  setResults(_results);
                } catch (err) {
                  console.error({ err });
                } finally {
                  setLoading(false);
                }
              }}
              options={results.map(r => ({
                id: r.place_id,
                label: r.formatted_address,
                ...r,
                location: {
                  lat: r.geometry.location.lat(),
                  lng: r.geometry.location.lng()
                }
              }))}
              disabled={!formData.service_area_id}
              placeholder={
                !formData.service_area_id && 'Select service area first'
              }
            />
          </div>
        </div>
        <div className="mt-3 mb-3">
          <FormLabel>Issue Resident Detail *</FormLabel>
          <textarea
            required={true}
            title="Complete this field"
            className="form-control"
            placeholder="Issue Resident Detail"
            name="issue_resident_detail"
            value={formData.issue_resident_detail}
            onChange={handleChange}
            style={{ height: '128px' }}
          />
        </div>
      </div>
      <div className="row mt-2 mb-2">
        <div className="col-6">
          <Form.Label>Client NTE</Form.Label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">$</span>
            </div>
            <Form.Control
              type="number"
              placeholder="Client NTE"
              name="client_nte"
              value={formData.client_nte}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-6">
          <Form.Label>Tech NTE</Form.Label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">$</span>
            </div>
            <Form.Control
              type="number"
              placeholder="Tech NTE"
              name="tech_nte"
              value={formData.tech_nte}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstStepData;
