import { useState } from 'react';
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import AuthSocialButtons from 'components/common/AuthSocialButtons';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import { loginUser } from '../../../api/login';
import '../../../assets/scss/theme/_login-theme.scss';
import { isValidEmail } from 'helpers/validator-utils';

const SignInForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await loginUser(email, password);
      navigate('/');
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
    }
  };

  const disableLogin = () => !isValidEmail(email) || !password || password.length < 6;

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="login-title">Sign In</h3>
        <p className="login-text">Get access to your account</p>
      </div>
      <AuthSocialButtons title="Sign in" />
      <div className="position-relative">
        <hr className="bg-200 mt-5 mb-4" />
        <div className="divider-content-center black">or use email</div>
      </div>
      <Form.Group className="mb-3 text-start">
        <div className="login-label">Email address</div>
        <div className="form-icon-container">
          <Form.Control
            id="email"
            type="email"
            className="form-icon-input"
            placeholder="name@example.com"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <FontAwesomeIcon icon={faUser} className="text-900 fs-9 form-icon" />
        </div>
      </Form.Group>
      <Form.Group className="mb-3 text-start login-text">
        <div className="login-label">Password</div>
        <div className="form-icon-container">
          <Form.Control
            id="password"
            type="password"
            className="form-icon-input"
            placeholder="Password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <FontAwesomeIcon icon={faKey} className="text-900 fs-9 form-icon" />
        </div>
      </Form.Group>
      <Row className="flex-center mb-3 mt-3">
        <Col xs="auto">
          <Link
            to={`/pages/authentication/${layout}/forgot-password`}
            className="fs-9 fw-semi-bold"
            style={{ color: '#ffffff' }}>
            Forgot Password?
          </Link>
        </Col>
      </Row>
      <Col xs="auto">
        <Link to="/" onClick={handleLogin}></Link>
        <Button
          style={{ backgroundColor: '#4111aa' }}
          disabled={disableLogin()}
          className="w-100 mb-3"
          onClick={handleLogin}>
          <p style={{ color: 'white', margin: '0' }}> Sign In </p>
        </Button>
      </Col>
    </>
  );
};

export default SignInForm;
