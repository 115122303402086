const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';

export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

export const isLogged = () => !!localStorage.getItem(ACCESS_TOKEN_KEY);

export const setAccessToken = (accessToken: string) => localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);

export const logout = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem('me');
  window.location.href = '/login';
};
