//Action types
export const GET_SERVICE_AREAS = 'GET_SERVICE_AREAS';
export const GET_CLIENTS = 'GET_CLIENTES';
export const GET_SERVICE_CATEGORIES = 'GET_SERVICE_CATEGORIES';

export interface ClientsState {
  clients: any[],
}

export type CLIENTS_ACTION_TYPE = {
  type: typeof GET_CLIENTS;
  payload: any[];
};

export const clientsReducer = (
  state: ClientsState,
  action: { payload: any; type: string }
) => {
  switch (action.type) {
    case GET_CLIENTS: {
      const { payload } = action;
      return {
        ...state,
        clients: payload
      };
    }
    default:
      return state;
  }
};
