import Avatar from 'components/base/Avatar';
import { Dropdown, Modal, Nav } from 'react-bootstrap';
import avatar57 from 'assets/img/team/40x40/avatar.webp';
import ProfileDropdownMenu from './ProfileDropdownMenu';
import { useAppContext } from 'providers/AppProvider';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import NotificationDropdownMenu from './NotificationDropdownMenu';
import { useState } from 'react';
import DropdownSearchBox from 'components/common/DropdownSearchBox';
import SearchResult from 'components/common/SearchResult';
import classNames from 'classnames';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../../assets/scss/theme/_icon-2.scss';
import '../../../assets/scss/theme/_icon-style-workorder.scss';
import CalendarProvider, {
  useCalendarContext
} from 'providers/CalendarProvider';
import { SET_CALENDAR_STATE } from 'reducers/CalendarReducer';
import CalendarEventModal from 'components/modals/CalendarEventModal';
import CalendarAddNewEventModal from 'components/modals/CalendarAddNewEventModal';

const NavItems = () => {
  const {
    config: { navbarPosition }
  } = useAppContext();
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const { calendarDispatch } = useCalendarContext();

  return (
    <div className="navbar-nav navbar-nav-icons flex-row">
      <Nav.Item
        className="icon-container"
        onClick={() => {
          calendarDispatch({
            type: SET_CALENDAR_STATE,
            payload: { openNewEventModal: true }
          });
        }}>
        {/* <ThemeToggler className="px-2" /> cambiar  tema */}
        <FontAwesomeIcon
          color="#5f1ed0"
          size="xl"
          icon={faPlus}
          className="icon-margin-l icon-margin-r"
        />
        <span className="tooltip">Add Work Order</span>
      </Nav.Item>
      <Nav.Item
        className={classNames({
          'd-lg-none':
            navbarPosition === 'vertical' || navbarPosition === 'dual'
        })}>
        <Nav.Link onClick={() => setOpenSearchModal(!openSearchModal)}>
          <FeatherIcon icon="search" size={19} style={{ marginBottom: 2 }} />
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Dropdown autoClose="outside" className="h-100 drop-down-nav">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            title="not available for now in Beta version"
            className="dropdown-caret-none bell-icon-nav h-100 fa-disabled"
            variant="">
            <FeatherIcon icon="bell" size={20} />
            <span className="badge rounded-pill badge-notification bg-danger">10</span>
          </Dropdown.Toggle>

          {/* uncomment when is done */}
          {/* <NotificationDropdownMenu /> */}
        </Dropdown>
      </Nav.Item>
     
      <Nav.Item>
        <Dropdown autoClose="outside" className="h-100">
          <Dropdown.Toggle
            as={Link}
            to="#!"
            className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-100 d-flex align-items-center"
            variant="">
            <Avatar src={avatar57} size="l" />
          </Dropdown.Toggle>
          <ProfileDropdownMenu />
        </Dropdown>
      </Nav.Item>

      <Modal
        show={openSearchModal}
        onHide={() => setOpenSearchModal(false)}
        className="search-box-modal mt-15">
        <Modal.Body className="p-0 bg-transparent">
          <DropdownSearchBox
            className="navbar-top-search-box"
            inputClassName="rounded-pill"
            size="lg"
            style={{ width: 'auto' }}>
            <SearchResult />
          </DropdownSearchBox>
        </Modal.Body>
      </Modal>
      <CalendarEventModal />
      <CalendarAddNewEventModal />
    </div>
  );
};

const index = () => (
  <CalendarProvider>
    <NavItems />
  </CalendarProvider>
);

export default index;
