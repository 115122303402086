import {  Row } from 'react-bootstrap';

import Dashboard from '../Dashboard';
import WorkOrders from '../WorkOrders';
import Comunications from '../Comunications';
import Accouting from '../Accouting';
import Reports from '../Reports';

const Ecommerce = () => {
  // const tabs = [
  //   { eventKey: 'Dashboard1', title: 'Dashboard', content: <DashboardPages /> },
  //   { eventKey: 'workOrders1', title: 'WorkOrders', content: <WorkOrders /> },
  //   {
  //     eventKey: 'Comunications1',
  //     title: 'Comunications(Ably)',
  //     content: <Comunications />
  //   },
  //   { eventKey: 'Accouting1', title: 'Accouting', content: <Accouting /> },
  //   { eventKey: 'Reports1', title: 'Reports', content: <Reports /> }
  // ];

  return (
    <>
      <div className="pb-5">
        <Row className="g-4">
          <div className="tab-row-header">
            <Dashboard />
          </div>

          {/*   <LeftMenu /> */}
          {/* <Col xs={12} xxl={6}>
            <EcomStats /> 
             <EcomTotalSells /> componente ventas
          </Col>
          <Col xs={12} xxl={6}>
            <Row className="g-3">
              <Col xs={12} md={6}>
                <EcomTotalOrdersCard />
              </Col>
              <Col xs={12} md={6}>
                <EcomNewCustomersCard />
              </Col>
              <Col xs={12} md={6}>
                <EcomTopCouponsCard />
              </Col>
              <Col xs={12} md={6}>
                <EcomPayingVsNonPayingCard />
              </Col>
            </Row>
          </Col> */}
        </Row>
        {/*  <Calendar /> */}
      </div>
      {/* <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white pt-7 border-y border-300">
        <EcomLatestReviewsTable />
      </div> */}
      {/* <Row className="gx-6">
        <Col xs={12} xl={6}>
          <EcomTopRegions />
        </Col>
        <Col xs={12} xl={6}>
          <div className="mx-n4 mx-lg-n6 ms-xl-0 h-100">
            <div className="h-100 w-100" style={{ minHeight: 300 }}>
              <EcomTopRegionsMap data={mapMarkerPoints} />
            </div>
          </div>
        </Col>
      </Row> */}
      {/* <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white pt-6 pb-9 border-top border-300">
        <Row className="g-6">
          <Col xs={12} xl={6}>
            <EcomProjectionVsActual />
          </Col>
          <Col xs={12} xl={6}>
            <EcomReturningCustomerRate />
          </Col>
        </Row>
      </div> */}
    </>
  );
};

export default Ecommerce;
