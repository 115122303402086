import { baseClient } from "./base";

async function getClients() {
  const { data } = await baseClient.get('/admin/clients');
  
  return data.data;
}

export default {
  getClients,
};
