import { capitalize } from 'helpers/utils';
import { useEffect, useState } from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import { RouteItems, routes } from 'sitemap';
import TopNavMegaMenu from './TopNavMegaMenu';
import TopNavItem from './TopNavItem';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import  '../../../assets/scss/theme/_leftmenu.scss';


const NavbarTopNav = () => {
  return (
    <Nav className="navbar-nav-top pb-4 pb-lg-0 nav-link">
      {routes.map(route => (
        <NavbarTopNavItem route={route} key={route.label} />
      ))}
    </Nav>
  );
};

const NavbarTopNavItem = ({ route }: { route: RouteItems }) => {
  const Icon = route.icon;
  const [show, setShow] = useState(false);
  const { pathname } = useLocation();

  const { breakpoints } = useBreakpoints();

  const handleMouseEnter = () => {
    if (breakpoints.up('lg') && route.megaMenuEnabled) {
      setShow(true);
    }
  };
  
  const handleMouseLeave = () => {
    if (breakpoints.up('lg') && route.megaMenuEnabled) {
      setShow(false);
    }
  };
  useEffect(() => {
    if (show) {
      setShow(false);
    }
  }, [pathname]);

  const navigate = useNavigate();
  const _location = useLocation();

  return (
    <Dropdown
      as="li"
      show={show}
      className={`nav-item ${route.active === false && 'fa-disabled'} ${route.pages.length === 0 ? 'remove-dropdown-icon' : ''}`}
      key={route.label}
      autoClose="outside"
      onMouseEnter={handleMouseEnter} 
      onMouseLeave={handleMouseLeave}
      onToggle={() => setShow(!show)}
      onClick={route.path ? () => navigate(route.path) : null}
      style={{ fontWeight: _location.pathname === route.path && route.path ? 900 : 'unset' }}
    >
      <Dropdown.Toggle
        as="a"
        variant=""
        style={{ cursor: route.active ? 'pointer' : 'not-allowed'}}
        className="nav-link lh-1 d-flex align-items-center"
        onClick={(e) => {
       
          if (!route.megaMenuEnabled || !route.megaMenu || Object.keys(route.megaMenu).length === 0) {
            e.preventDefault(); 
          }
        }}
        
      >
        <Icon className="me-2" size={16} />
        <span>
          {capitalize(
            route.horizontalNavLabel ? route.horizontalNavLabel : route.label
          )}
        </span>
      </Dropdown.Toggle>
      {route.megaMenu ? (
        <TopNavMegaMenu route={route} />
      ) : (
        <TopNavItem route={route} />
      )}
    </Dropdown>
  );
};

export default NavbarTopNav;
