import { baseClient } from './base';

export const loginUser = async (email, password) => {
  const response = await baseClient.post('/admin/users/login', {
    email,
    password,
    type: 'password'
  });
  const { data } = response;
  if (data.status === 'fail') {
    alert('email/password combination is invalid');
  } else {
    const { token } = response.data.data;
    localStorage.setItem('ACCESS_TOKEN', token);
    baseClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
};

export const loginWithGoogle = async token => {
  const response = await baseClient.post('/admin/users/login', {
    type: 'google',
    token,
    source: 'admin'
  });
  const { data } = response;
  if (data.status === 'fail') {
    alert('email/password combination is invalid');
  } else {
    const { token } = response.data.data;
    localStorage.setItem('ACCESS_TOKEN', token);
    baseClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
};
