import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { currencyFormat } from 'helpers/utils';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';

import Badge from 'components/base/Badge';
import FeatherIcon from 'feather-icons-react';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import { useState, useEffect } from 'react';

interface WorkOrder {
  id: string;
  client: { id: number; name: string };
  client_id: number;
  code: string;
  issue_resident_detail: string;
  issue_technician_detail: string | null;
  priority: string;
  residence_address: string;
  resident_email: string;
  resident_name: string;
  resident_phone: string;
  scheduling: any[];
  service_area: { id: number; name: string };
  service_area_id: number;
  service_category: { id: number | null; name: string };
  service_category_id: number | null;
  status: string;
  sub_client: { id: number; name: string };
  sub_client_id: number;
}

export const ordersTableColumns: ColumnDef<WorkOrder>[] = [
  {
    accessorKey: 'orderId',
    header: 'Order',
    cell: ({ row: { original } }) => {
      const { id } = original;
      return (
        <Link to={`#${id}`} className="fw-semi-bold">
          #{id}
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'pe-3' }
    }
  },
  {
    accessorKey: 'priority',
    header: 'Priority',
    cell: ({ row: { original } }) => original.priority,
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end pe-7' },
      cellProps: { className: 'text-end fw-semi-bold text-1000 pe-7' }
    }
  },
  {
    accessorKey: 'status',
    header: 'Fulfilment Status',
    cell: ({ row: { original } }) => {
      const { status } = original;
      return (
        <Badge
          bg={status === 'pending_schedule' ? 'warning' : 'success'} 
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
          icon={
            <FeatherIcon
              icon={status === 'pending_schedule' ? 'alert-triangle' : 'check-circle'}
              size={12.8}
              className="ms-1"
            />
          }
        >
          {status}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: '15%' }, className: 'pe-3' }
    }
  },
  {
    accessorKey: 'residence_address',
    header: 'Residence Address',
    cell: ({ row: { original } }) => original.residence_address,
    meta: {
      headerProps: { style: { width: '20%' }, className: 'pe-3' }
    }
  },
  {
    accessorKey: 'id',
    header: 'Date',
    cell: ({ row: { original } }) => {
      const date = new Date();
      date.setDate(date.getDate() + parseInt(original.id)); 
      const formattedDate = date.toISOString(); 
      return formattedDate;
    },
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'text-700 fs-9 ps-4 text-end white-space-nowrap' }
    }
  },
  {
    id: 'action',
    cell: () => (
      <RevealDropdownTrigger>
        <RevealDropdown btnClassName="lh-1">
          <ActionDropdownItems />
        </RevealDropdown>
      </RevealDropdownTrigger>
    ),
    meta: {
      headerProps: { style: { width: '7%' } },
      cellProps: { className: 'text-end' }
    }
  }
];

const CustomerOrdersTable = () => {

  const { searchWorkOrders, workOrders } = useWorkOrderContext();

  useEffect(() => {   
    searchWorkOrders();    
  }, []);



  const table = useAdvanceTable({
    data: workOrders,
    columns: ordersTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  return (
    <AdvanceTableProvider {...table}>
      <div className="border-y">
        <AdvanceTable
          tableProps={{ className: 'phoenix-table table-sm fs-9' }}
        />
        <AdvanceTableFooter pagination showViewAllBtn={false} />
      </div>
    </AdvanceTableProvider>
  );
};

export default CustomerOrdersTable;