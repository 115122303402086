import Button from 'components/base/Button';
import { useState } from 'react';
import {
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'react-bootstrap';

const CancelWorkOrderModal = ({ show, onHide, onCancelWorkOrder }) => {
  const [reason, setReason] = useState('');

  return (
    <Modal show={show} onHide={onHide}>
      <ModalHeader closeButton>Cancel Work Order</ModalHeader>
      <ModalBody>
        <Form>
          <Form.Group controlId="reason">
            <Form.Label>Notes / Reason for cancellation:</Form.Label>
            <Form.Control
              as="textarea"
              value={reason}
              onChange={e => setReason(e.target.value)}
            />
          </Form.Group>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button variant="outline-info" onClick={onHide}>
          Go back
        </Button>
        <Button variant="danger" onClick={() => onCancelWorkOrder(reason)}>
          Accept
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CancelWorkOrderModal;
