import { Button, Card } from 'react-bootstrap';
import './materials.scss';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import RejectQuoteWithReasonModal from 'components/modals/RejectQuoteWithReasonModal';
import { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { faPrint, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const Quote = ({ quote }) => {
    const quoteData = quote.data;
  
    const [repairDescription, setRepairDescription] = useState([]);
  
    useEffect(() => {
      setRepairDescription(
        quoteData.cost_entries
          .filter(entry => entry.type === 'material')
          .map(entry => ({
            description: entry.description,
            quantity: entry.quantity,
            unitPrice: entry.rate,
            netPrice: entry.quantity * entry.rate,
            type: entry.type
          }))
      );
    }, [quoteData]);
  
    return (
      <div>
        <div className="table-responsive materiak-container">
          <h4 className="materials-title">Materials</h4>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="table-column-quantity">Type</th>
                <th className="table-column-description">Description</th>
                <th className="table-column-quantity">Quantity</th>
                <th className="table-column-unit-price">Unit Price</th>
                <th className="table-column-net-price">Net Price</th>
              </tr>
            </thead>
            <tbody>
              {repairDescription.map((item, index) => (
                <tr key={index}>
                  <td className="table-column-quantity">{item.type}</td>
                  <td className="table-column-description">{item.description}</td>
                  <td className="table-column-quantity">{item.quantity}</td>
                  <td className="table-column-unit-price">{item.unitPrice}</td>
                  <td className="table-column-net-price">{item.netPrice}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="total">
            <b>
              Total: $
              {repairDescription.reduce(
                (sum, cost) => cost.unitPrice * cost.quantity + sum,
                0
              )}
            </b>
          </div>
        </div>
      </div>
    );
  };
  
  const MaterialTab = () => {
    const {
      getFullDetailWorkOrderByCode,
      workOrderFullDetailFound
    } = useWorkOrderContext();
    const techToCompany = workOrderFullDetailFound.quotes.find(
      t => t.receiver === 'tech_to_company'
    );
  
    return (
      <div className="material-container d-flex flex-column">
        <Quote quote={techToCompany} />
      </div>
    );
  };
  
  export default MaterialTab;