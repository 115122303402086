import { Col, Row } from 'react-bootstrap';

import BasicTimeline from 'components/timelines/BasicTimeline';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import { faClock, faHammer } from '@fortawesome/free-solid-svg-icons';
import { formatAsDateAndTime } from 'helpers/date-utils';
import { snakeCaseToNormal } from 'helpers/utils';

const formatEventContent = event => {
  if (event.action_type === 'onsite_technician_action') {
    return `Technician did a ${snakeCaseToNormal(event.type)} action`;
  }
  if (event.action_type === 'scheduling') {
    return `The visit was scheduled to the ${formatAsDateAndTime(event.date_and_time)}`;
  }
};

const Timeline = () => {
  const { workOrderFullDetailFound } = useWorkOrderContext();
  return (
    <>
      <Row className="gx-xl-8 gx-xxl-11">
        <Col xl={7} className="scrollbar ms-xl-8">
          <BasicTimeline
            data={workOrderFullDetailFound.timeline.map(event => ({
              ...event,
              content: formatEventContent(event),
              icon:
                event.action_type === 'onsite_technician_action'
                  ? faHammer
                  : faClock,
              images: event.images?.map(i => i.image_url)
            }))}
          />
        </Col>
      </Row>
    </>
  );
};

export default Timeline;
