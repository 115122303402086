import { GoogleMap, InfoWindowF, MarkerF } from '@react-google-maps/api';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatStatus } from 'components/work-orders/work-order-list-item';
import { useState } from 'react';

const MapModal = ({ workOrder, handleClose, show, hideInfoWindow = false }) => {
  const containerStyle = {
    width: '100%',
    height: '400px'
  };

  function getIconUrl(priority) {
    switch (priority) {
      case 'urgent':
        return '/alert.ico';
      case 'emergency':
      case 'critical':
        return '/danger.ico';
      case 'routine':
        return '/favicon.ico';
      default:
        return '/favicon.ico';
    }
  }

  const [showInfoWindow, setShowInfoWindow] = useState(false);

  if (!workOrder) return null;

  function onLoad(map) {
    if (workOrder) {
      map.setCenter({
        lat: parseFloat(workOrder.residence_address_lat),
        lng: parseFloat(workOrder.residence_address_long)
      });
      map.setZoom(12);
    }
  }

  return (
    <Modal size="lg" centered={true} show={show} onHide={handleClose}>
      <Modal.Body className="p-card py-3">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{
            lat: parseFloat(workOrder.residence_address_lat),
            lng: parseFloat(workOrder.residence_address_long)
          }}
          onLoad={onLoad}
          zoom={12}>
          <MarkerF
            key={workOrder.id}
            position={{
              lat: parseFloat(workOrder.residence_address_lat),
              lng: parseFloat(workOrder.residence_address_long)
            }}
            onClick={() => setShowInfoWindow(true)}
            icon={{
              url: getIconUrl(workOrder.priority),
              scaledSize: new window.google.maps.Size(40, 40),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(15, 30)
            }}
          />

          {showInfoWindow && !hideInfoWindow && (
            <InfoWindowF
              position={{
                lat: parseFloat(workOrder.residence_address_lat),
                lng: parseFloat(workOrder.residence_address_long)
              }}
              onCloseClick={() => setShowInfoWindow(false)}>
              <div>
                <p>
                  <strong>Code:</strong>{' '}
                  <Link
                    to={`/workorder-data/${workOrder.code}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    {workOrder.code}
                    {workOrder.code}
                  </Link>
                </p>
                <p>
                  <strong>Resident Name:</strong> {workOrder.resident_name}
                </p>
                <p>
                  <strong>Resident Email:</strong> {workOrder.resident_email}
                </p>
                <p>
                  <strong>Status:</strong> {formatStatus(workOrder.status)}
                </p>
              </div>
            </InfoWindowF>
          )}
        </GoogleMap>
      </Modal.Body>
    </Modal>
  );
};

export default MapModal;
