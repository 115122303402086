import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTools,
  faFileAlt,
  faMicrochip,
  faUser,
  faHome
} from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import { Form } from 'react-bootstrap';

const SnoozedModal = ({
  show,
  onHide,
  workOrderId,
  onConfirm
}: {
  show: boolean;
  onHide: any;
  workOrderId: number;
  onConfirm: (status: string) => void
}) => {
  const { snoozeWorkOrder } = useWorkOrderContext();

  const  handleSnooze = async () => {
    try {
      const response = await snoozeWorkOrder(workOrderId, selectedReason);
      
      onConfirm(response);
      onHide();
    } catch (error) {
      console.error("Error snoozing the work order", error);
    }
  }

  const items = [
    {
      enum: 'waiting_for_permits',
      label: 'Waiting for Permits',
      icon: faFileAlt
    },
    {
      enum: 'waiting_for_parts',
      label: 'Waiting for Parts',
      icon: faTools
    },
    {
      enum: 'waiting_for_tech',
      label: 'Waiting for Tech',
      icon: faMicrochip
    },
    {
      enum: 'waiting_for_client',
      label: 'Waiting for Client',
      icon: faUser
    },
    {
      enum: 'waiting_for_resident',
      label: 'Waiting for Resident',
      icon: faUser
    }
  ];

  const [selectedReason, setSelectedReason] = useState(null);

  return (
    <Modal show={show} onHide={onHide} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>Snooze Work Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Reason</Form.Label>
          <ListGroup>
            {items.map((item, index) => (
              <ListGroup.Item
                key={index}
                style={{
                  cursor: 'pointer',
                  backgroundColor:
                    selectedReason === item.enum ? 'Highlight' : 'unset'
                }}
                onClick={c => setSelectedReason(item.enum)}>
                <FontAwesomeIcon icon={item.icon} color="#3874ff" />{' '}
                {item.label}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Form.Group>

        <Form.Group className="mb-3 mt-3">
          <Form.Label>Notes</Form.Label>
          <Form.Control
            as="textarea"
            rows={6}
            placeholder="Details related to this action"
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button
          onClick={handleSnooze}
          variant="primary"
          className="px-4"
          disabled={!selectedReason}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SnoozedModal;
