import { useJsApiLoader } from '@react-google-maps/api';
import { getMe } from 'api/users';
import { getAccessToken } from 'helpers/login-utils';
import useToggleStyle from 'hooks/useToggleStyle';
import { useAppContext } from 'providers/AppProvider';
import { useSettingsPanelContext } from 'providers/SettingsPanelProvider';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

const App = () => {
  const { isStylesheetLoaded } = useToggleStyle();
  const { pathname } = useLocation();
  const gMapsApiKey = 'AIzaSyDzC8Dj2BRC3VnulOpv_mjIrGAyP27JV2o';
  useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: gMapsApiKey
  });

  const { setSettingsPanelConfig } = useSettingsPanelContext();

  const {
    config: { theme, isRTL }
  } = useAppContext();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setSettingsPanelConfig({
      openSettingPanel: false
    });
  }, [isRTL]);

  useEffect(() => {
    if (getAccessToken()) {
      getMe().then((user) => {
        // TODO: im hurry for send this to prod, for now save in local storage :)
        localStorage.setItem('me', JSON.stringify(user));
      });
    }
  })

  return (
    <>
      {!isStylesheetLoaded ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: theme === 'dark' ? '#000' : '#fff'
          }}
        />
      ) : (
        <>
          <Outlet />
          {/* {showSettingPanelButton && (
            <>
              <SettingsToggle />
              <SettingsPanel />
            </>
          )} */}
        </>
      )}
    </>
  );
};

export default App;
