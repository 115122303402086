import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import { formatStatus } from 'components/work-orders/work-order-list-item';
import { Link } from 'react-router-dom';
import DateSelector from './DateSelector';
import LogoAnimated from 'components/icons/logo-animated';
import Button from 'components/base/Button';
import { useModalsContext } from 'providers/ModalsProvider';
import ScheduleWorkOrderModal from 'components/modals/ScheduleWorkOrderModal';
import moment from 'moment';

const containerStyle = {
  width: '100%',
  height: '700px'
};
function Map() {
  const { workOrdersOnMap: workOrders, searchMapWorkOrders, setSelectedWorkOrder } =
    useWorkOrderContext();
  const { dispatchShowModalScheduleWorkOrder } = useModalsContext();
  const [generatedMarkers, setGeneratedMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    setLoading(true)
    searchMapWorkOrders();
    setLoading(false)
  }, []);

  useEffect(() => {
    const markers = [];

    for (const workOrder of workOrders) {
      const { residence_address_lat: lat, residence_address_long: lng } =
        workOrder;
      markers.push({ id: workOrder.id, position: { lat, lng }, workOrder });
    }

    setGeneratedMarkers(markers);
  }, [workOrders]);

  const [, setMap] = React.useState(null);

  const onLoad = React.useCallback(
    function callback(map) {
      if (generatedMarkers[0]) {
        map.setCenter(generatedMarkers[0].position);
        map.setZoom(12);
        setMap(map);
      }
    },
    [generatedMarkers]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleMarkerClick = marker => {
    setSelectedMarker(marker);
    localStorage.setItem('workOrderCode', marker.workOrder.code);
  };   

  function getIconUrl(priority) {
    switch (priority) {
      case 'urgent':
        return '/alert.ico';
      case 'emergency':
      case 'critical':
        return '/danger.ico';
      case 'routine':
        return '/favicon.ico';
      default:
        return '/favicon.ico';
    }
  }

  const onDateChange = (start, end) => {
    searchMapWorkOrders({ scheduledFrom: start, scheduledTo: end });
  };

  return (
    <>
      <ScheduleWorkOrderModal />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <DateSelector onDateChange={onDateChange} />
        {loading ? ( // Mostrar loader mientras se carga
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '700px'
            }}>
            <LogoAnimated />
          </div>
        ) : generatedMarkers.length > 0 ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={generatedMarkers[0].position}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}>
            {generatedMarkers.map(marker => (
              <Marker
                key={marker.id}
                position={marker.position}
                onClick={() => handleMarkerClick(marker)}
                icon={{
                  url: getIconUrl(marker.workOrder.priority),
                  scaledSize: new window.google.maps.Size(40, 40),
                  origin: new window.google.maps.Point(0, 0),
                  anchor: new window.google.maps.Point(15, 30)
                }}
              />
            ))}

            {selectedMarker && (
              <InfoWindow
                position={selectedMarker.position}
                onCloseClick={() => setSelectedMarker(null)}>
                <div>
                  <p>
                    <strong>WO Code:</strong>{' '}
                    <Link
                      to={`/workorder-data/${selectedMarker.workOrder.code}`}
                      target="_blank"
                      rel="noopener noreferrer">
                      {selectedMarker.workOrder.code}
                    </Link>
                  </p>
                  <p>
                    <strong>Technician:</strong>{' '}
                    {selectedMarker.workOrder.scheduling[0]?.technicians.map(
                      (technician, index) => (
                        <span key={index}>{technician.fullname}</span>
                      )
                    ) || 'No technicians assigned'}
                  </p>
                  <p>
                    <strong>Status:</strong>{' '}
                    {formatStatus(selectedMarker.workOrder.status)}
                  </p>
                  {selectedMarker.workOrder.status === 'scheduled_inspection' && (
                    <div>
                      <p>
                        <strong>ETA:</strong>{' '}
                        {moment(selectedMarker.workOrder.scheduling[0]?.date_and_time).format('HH:mm') || 'No ETA available'}{' '}PST
                      </p>
                      <p>
                        <strong>Expected Timeframe:</strong>{' '}
                        {selectedMarker.workOrder.scheduling[0]?.date_and_time_end
                          ? `${moment
                              .duration(
                                moment(selectedMarker.workOrder.scheduling[0]?.date_and_time_end).diff(
                                  moment(selectedMarker.workOrder.scheduling[0]?.date_and_time)
                                )
                              )
                              .asHours()} hrs`
                          : '2 hrs'}
                      </p>
                    </div>
                  )}
                  <p>
                    <p>
                      <strong>Priority:</strong>{' '}
                      {selectedMarker.workOrder.priority}
                    </p>
                    <strong>Client:</strong>{' '}
                    {selectedMarker.workOrder.client.name}
                  </p>
                  <p>
                    <strong>Service Category:</strong>{' '}
                    {selectedMarker.workOrder.service_category.name}
                  </p>
                  {/* <p>
                    <strong>Resident Name:</strong>{' '}
                    {selectedMarker.workOrder.resident_name}
                  </p> */}
                  {/* <p>
                    <strong>Resident Email:</strong>{' '}
                    {selectedMarker.workOrder.resident_email}
                  </p> */}
                  {selectedMarker.workOrder.status === 'pending_schedule' && (
                    <div className="d-flex align-items-center justify-content-center">
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setSelectedWorkOrder(selectedMarker.workOrder);
                          dispatchShowModalScheduleWorkOrder(true);
                        }}>
                        Take Action
                      </Button>
                    </div>
                  )}
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        ) : (
          // Mostrar leyenda si no hay work orders
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '700px',
              backgroundColor: '#CFF2DD'
            }}>
            <p>No Work Orders found to display this day</p>
          </div>
        )}
      </div>
    </>
  );
}

export default React.memo(Map);
