import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';

const ReactivateModal = ({ show, onHide, workOrderId, onConfirm }: { show: boolean; onHide: any; workOrderId: number; onConfirm: (status: string) => void }) => {
  const { reactivateWorkOrder } = useWorkOrderContext();

  const handleReactivate = async () => {
    try {
      const response = await reactivateWorkOrder(workOrderId); 
  
      onConfirm(response);
      onHide();
    } catch (error) {
      console.error("Error reactivating the work order", error); 
    }
  };
  

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Reactivate work order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to reactivate this work order?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button onClick={handleReactivate} variant="primary">
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReactivateModal;
