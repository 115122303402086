import React from 'react';
import Button from 'components/base/Button';

interface PaginationButtonsProps {
  currentPage: number;
  totalPages: number;
  visiblePages: number[];
  onPageChange: (page: number) => void;
  startItem: number;
  endItem: number;
  totalItems: number;
}

const PaginationButtons: React.FC<PaginationButtonsProps> = ({
  currentPage,
  totalPages,
  visiblePages,
  onPageChange,
  startItem,
  endItem,
  totalItems
}) => (
  <div className="d-flex align-items-center flex-column">
    <div className='d-flex'>
      <Button
        variant="secondary"
        className="me-2"
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
        style={{ height: '36px', width: '14px' }}>
        {'<'}
      </Button>
      {visiblePages.map(pageNumber => (
        <Button
          key={pageNumber}
          variant="secondary"
          className={currentPage === pageNumber ? 'active me-2' : 'me-2'}
          onClick={() => onPageChange(pageNumber)}
          style={{
            backgroundColor: currentPage === pageNumber ? '#0aedd1' : '#ffffff',
            color: '#000000',
            border: '0.5px solid #CFD5CF'
          }}>
          {pageNumber}
        </Button>
      ))}
      <Button
        variant="secondary"
        className="me-2"
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
        style={{ height: '36px', width: '14px' }}>
        {'>'}
      </Button>
    </div>
    <div
      className="d-flex justify-content-end mb-2 mt-2">
      <span style={{ fontSize: '11px' }}>
        {startItem}-{endItem} work orders of {totalItems}
      </span>
    </div>
  </div>
);

export default PaginationButtons;
