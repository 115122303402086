export const workOrderPriorities = [
  'urgent',
  'emergency',
  'routine',
  'critical'
];

export const workOrderStatuses = [
  'pending_schedule',
  'scheduled_inspection',
  'scheduled_approved_work',
  'on_site',
  'onsite_tech_quote_submitted',
  'onsite_tech_quote_rejected',
  'onsite_approved_work',
  'pending_tech_quote',
  'tech_quote_submitted',
  'tech_quote_rejected',
  'rtn_approved_work',
  'rtn_inspection',
  'pending_client_quote_approval',
  'client_quote_approved',
  'client_quote_rejected',
  'work_complete_pending_tech_invoice',
  'tech_invoice_received',
  'tech_invoice_rejected',
  'tech_invoice_approved',
  'pending_client_invoice_approval',
  'client_invoice_rejected',
  'pending_client_payment',
  'tech_invoice_paid',
  'cancelled',
  'resolved_without_dispatch',
  'resolved_without_invoice'
];
