import React, { useEffect, useState } from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import { Link } from 'react-router-dom';
import DatePicker from 'components/base/DatePicker';
import LogoAnimated from 'components/icons/logo-animated';
import { ColumnDef } from '@tanstack/react-table';
import './History.scss';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import {
  workOrderPriorities,
  workOrderStatuses
} from 'data/work-orders/values';

export interface WorkOrder {
  id: string;
  code: string;
  client_id: number;
  sub_client_id: number;
  service_area_id: number;
  residence_address: string;
  resident_name: string;
  resident_phone: string;
  resident_email: string;
  issue_resident_detail: string;
  issue_technician_detail: null;
  status: string;
  priority: string;
  service_category_id: number;
  created_at: Date;
  updated_at: Date;
  residence_address_lat: number;
  residence_address_long: number;
  nte: string;
  alternative_contact_name: null | string;
  alternative_contact_phone: null | string;
  alternative_contact_email: null | string;
  client_invoice_status: Status;
  tech_invoice_status: Status;
  snoozed: Snoozed | null;
  notes: null;
  schedule_by: Date;
  arrival_by: Date;
  resolve_by_non_quoted: Date;
  tech_nte: string;
  service_area: Client;
  service_category: Client;
  client: Client;
  sub_client: Client;
  scheduling: Scheduling[];
  technician: Technician;
}

export interface Client {
  id: number;
  name: string;
}

export enum Status {
  Pending = 'pending'
}

export interface Scheduling {
  date_and_time: Date;
  status: Status;
}

export interface Snoozed {
  snoozed_at: Date;
  snoozed_reason: string;
  previous_status: string;
}

export interface Technician {
  first_name: string;
  last_name: string;
  id: number;
}

const tableColumns: ColumnDef<WorkOrder>[] = [
  {
    accessorKey: 'code',
    header: 'Code',
    cell: ({ row: { original } }) => {
      const workOrderCode = original.code || '';
      return (
        <Link
          to={`/workorder-data/${workOrderCode}`}
          target="_blank"
          rel="noopener noreferrer">
          {workOrderCode}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: {
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        }
      },
      cellProps: { style: { backgroundColor: '#ffffff', padding: '5px' } }
    }
  },
  {
    accessorKey: 'technician_id',
    header: 'Technician',
    cell: ({ row: { original } }) => {
      const technician = original.technician;
      return technician
        ? technician.first_name + ' ' + technician.last_name
        : '';
    },
    meta: {
      headerProps: {
        style: {
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        }
      },
      cellProps: { style: { backgroundColor: '#ffffff', padding: '5px' } }
    }
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row: { original } }) => original.status || '',
    meta: {
      headerProps: {
        style: {
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        }
      },
      cellProps: { style: { backgroundColor: '#ffffff', padding: '5px' } }
    }
  },
  {
    accessorKey: 'priority',
    header: 'Priority',
    cell: ({ row: { original } }) => {
      const priorityColor = () => {
        switch (original.priority) {
          case 'critical':
            return '#B70E06';
          case 'emergency':
            return '#D81B08';
          case 'urgent':
            return '#D8D208';
          case 'routine':
            return '#0867D8';
          default:
            return '#0867D8';
        }
      };

      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start'
          }}>
          <div
            style={{
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: priorityColor(),
              marginRight: '5px'
            }}
          />
          {original.priority}
        </div>
      );
    },
    meta: {
      headerProps: {
        style: {
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        }
      },
      cellProps: {
        style: {
          backgroundColor: '#ffffff',
          padding: '5px',
          textAlign: 'center'
        }
      }
    }
  },
  {
    accessorKey: 'schedule',
    header: 'Schedule',
    cell: ({ row: { original } }) => {
      const { date_and_time } = original.scheduling[0] || {};
      return date_and_time ? moment(date_and_time).format('MM-DD-YYYY') : '';
    },
    meta: {
      headerProps: {
        style: {
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        }
      },
      cellProps: { style: { backgroundColor: '#ffffff', padding: '5px' } }
    }
  },
  {
    accessorKey: 'service_category.name',
    header: 'Service Category',
    cell: ({ row: { original } }) => original.service_category?.name || '',
    meta: {
      headerProps: {
        style: {
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        }
      },
      cellProps: { style: { backgroundColor: '#ffffff', padding: '5px' } }
    }
  },
  {
    accessorKey: 'residence_address',
    header: 'Address',
    cell: ({ row: { original } }) => original.residence_address || '',
    meta: {
      headerProps: {
        style: {
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        }
      },
      cellProps: { style: { backgroundColor: '#ffffff', padding: '5px' } }
    }
  },
  {
    accessorKey: 'resident_name',
    header: 'Resident Contact',
    cell: ({ row: { original } }) => original.resident_name || '',
    meta: {
      headerProps: {
        style: {
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        }
      },
      cellProps: { style: { backgroundColor: '#ffffff', padding: '5px' } }
    }
  },
  {
    accessorKey: 'service_area.name',
    header: 'Service Area',
    cell: ({ row: { original } }) => original.service_area?.name || '',
    meta: {
      headerProps: {
        style: {
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        }
      },
      cellProps: { style: { backgroundColor: '#ffffff', padding: '5px' } }
    }
  },
  {
    accessorKey: 'client.name',
    header: 'Client',
    cell: ({ row: { original } }) => original.client?.name || '',
    meta: {
      headerProps: {
        style: {
          width: '10%',
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        },
        className: 'text-center pe-7'
      },
      cellProps: {
        className: 'text-center fw-semi-bold text-1000 pe-7',
        style: { backgroundColor: '#ffffff', padding: '5px' }
      }
    }
  },
  {
    accessorKey: 'sub_client.name',
    header: 'Sub Client',
    cell: ({ row: { original } }) => original.sub_client?.name || '',
    meta: {
      headerProps: {
        style: {
          backgroundColor: '#4111aa',
          color: '#ffffff',
          padding: '5px'
        }
      },
      cellProps: { style: { backgroundColor: '#ffffff', padding: '5px' } }
    }
  }
];

const History = () => {
  const {
    searchWorkOrdersSimple,
    workOrdersHistory,
    loadingWorkOrders,
    technicians,
    serviceAreas,
    serviceCategories,
    clients
  } = useWorkOrderContext();

  const { currentPage, totalPages, workOrders } = workOrdersHistory;

  const [filterParams, setFilterParams] = useState({
    code: null,
    type: null,
    schedulingFrom: undefined,
    schedulingTo: undefined,
    technicianIds: null,
    statuses: null,
    priorities: null,
    clientIds: null,
    serviceCategoryIds: null,
    serviceAreaIds: null,
    page: 1,
    perPage: 10
  });

  useEffect(() => {
    searchWorkOrdersSimple({
      page: 1,
      perPage: 10,
      serviceAreaIds: filterParams.serviceAreaIds,
      technicianIds: filterParams.technicianIds,
      clientIds: filterParams.clientIds,
      scheduledFrom: filterParams.schedulingFrom,
      scheduledTo: filterParams.schedulingTo,
      statuses: filterParams.statuses,
      serviceCategoryIds: filterParams.serviceCategoryIds
    });
  }, [filterParams]);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setFilterParams(prevParams => ({
        ...prevParams,
        page: newPage
      }));
    }
  };

  const table = useAdvanceTable({
    data: workOrders,
    columns: tableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true
  });

  const calculateVisiblePages = () => {
    const visiblePages = [];
    const startPage = Math.max(1, Math.min(currentPage - 3, totalPages - 6));
    const endPage = Math.min(totalPages, startPage + 6);
    for (let i = startPage; i <= endPage; i++) {
      visiblePages.push(i);
    }
    return visiblePages;
  };

  const visiblePages = calculateVisiblePages();

  return (
    <>
      <div className="filters-History-container">
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-us">
          <Form.Group className="mb-3 history-form-select">
            <Form.Label className="history-form-label">
              Scheduling Date
            </Form.Label>
            <DatePicker
              options={{
                mode: 'range',
                dateFormat: 'm-d-Y'
              }}
              placeholder="Select dates"
              onChange={(dates: Date[]) => {
                setFilterParams({
                  ...filterParams,
                  schedulingFrom: dates[0],
                  schedulingTo: dates[1]
                });
              }}
            />
          </Form.Group>
        </LocalizationProvider>

        <Form.Group className="mb-3 history-form-select">
          <Form.Label className="history-form-label">Technician</Form.Label>
          <Form.Select
            name="technicianIds"
            value={filterParams.technicianIds}
            onChange={val => {
              setFilterParams({ ...filterParams, technicianIds: [val] });
            }}>
            {technicians.map(technician => (
              <option key={technician.id} value={technician.id}>
                {technician.first_name + ' ' + technician.last_name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3 history-form-select">
          <Form.Label className="history-form-label">Status</Form.Label>
          <Form.Select
            name="status"
            onChange={val => {
              setFilterParams({ ...filterParams, statuses: [val] });
            }}>
            {workOrderStatuses.map(status => (
              <option key={status} value={status}>
                {status}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3 history-form-select">
          <Form.Label className="history-form-label">Priority</Form.Label>
          <Form.Select
            name="priorities"
            onChange={val => {
              setFilterParams({ ...filterParams, priorities: [val] });
            }}>
            {workOrderPriorities.map(priority => (
              <option key={priority} value={priority}>
                {priority}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3 history-form-select">
          <Form.Label className="history-form-label">Type</Form.Label>
          <Form.Select
            name="clientType"
            value={''}
            onChange={val => {
              setFilterParams({ ...filterParams, type: val });
            }}>
            <option key={'reactive'} value={'reactive'}>
              Reactive
            </option>
            <option key={'turns_and_rehabs'} value={'turns_and_rehabs'}>
              Turns & Rehabs
            </option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3 history-form-select">
          <Form.Label className="history-form-label">Client</Form.Label>
          <Form.Select
            name="clientIds"
            value={filterParams.clientIds}
            onChange={event => {
              setFilterParams({
                ...filterParams,
                clientIds: [event.currentTarget.value]
              });
            }}>
            {clients.map(client => (
              <option key={client.id} value={client.id}>
                {client.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3 history-form-select">
          <Form.Label className="history-form-label">
            Service Category
          </Form.Label>
          <Form.Select
            name="serviceCategory"
            onChange={event => {
              setFilterParams({
                ...filterParams,
                serviceCategoryIds: [event.currentTarget.value]
              });
            }}>
            {serviceCategories.map(category => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3 history-form-select">
          <Form.Label className="history-form-label">Service Area</Form.Label>
          <Form.Select
            name="serviceAreaIds"
            onChange={event => {
              setFilterParams({
                ...filterParams,
                serviceAreaIds: [event.currentTarget.value]
              });
            }}>
            {serviceAreas.map(area => (
              <option key={area.id} value={area.id}>
                {area.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>

      {loadingWorkOrders ? (
        <LogoAnimated />
      ) : (
        <AdvanceTableProvider {...table}>
          <div className="border-y">
            <AdvanceTable
              tableProps={{ className: 'phoenix-table table-sm fs-9 mt-4' }}
            />
            {visiblePages.length > 0 && (
              <div className="d-flex justify-content-center mt-3">
                <Button
                  variant="secondary"
                  className="me-2"
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                  style={{ height: '36px', width: '14px' }}>
                  {'<'}
                </Button>
                {visiblePages.map(pageNumber => (
                  <Button
                    key={pageNumber}
                    variant="secondary"
                    className={
                      currentPage === pageNumber ? 'active me-2' : 'me-2'
                    }
                    onClick={() => handlePageChange(pageNumber)}
                    style={{
                      backgroundColor:
                        currentPage === pageNumber ? '#0aedd1' : '#ffffff',
                      color: '#000000',
                      border: '0.5px solid #CFD5CF',
                      marginBottom: '1rem'
                    }}>
                    {pageNumber}
                  </Button>
                ))}
                <Button
                  variant="secondary"
                  className="me-2"
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                  style={{ height: '36px', width: '14px' }}>
                  {'>'}
                </Button>
              </div>
            )}
          </div>
        </AdvanceTableProvider>
      )}
    </>
  );
};

export default History;
