import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Form, Modal, FormLabel, Button, Spinner, Alert } from 'react-bootstrap';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';

interface SubClientData {
  id?: number;
  name: string;
  logo_url?: string;
}

const CreateNewClient = ({ closeModalAction }: { closeModalAction: () => void }) => {
  const { createClient, getClients, uploadImage } = useWorkOrderContext();

  const [formData, setFormData] = useState({
    name: '',
    logo_url: '',
    sub_clients: [] as SubClientData[],
  });

  const [newSubClientName, setNewSubClientName] = useState<string>('');
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [subClientLogoFiles, setSubClientLogoFiles] = useState<{ [key: number]: File | null }>({});
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleNewSubClientChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewSubClientName(e.target.value);
  };

  const handleAddSubClient = () => {
    if (newSubClientName.trim()) {
      setFormData(prevFormData => ({
        ...prevFormData,
        sub_clients: [
          ...prevFormData.sub_clients,
          { name: newSubClientName }
        ]
      }));
      setNewSubClientName('');
      setErrorMessage(null);
    }
  };

  const handleSubClientChange = (index: number, name: string) => {
    const updatedSubClients = formData.sub_clients.map((subClient, i) =>
      i === index ? { ...subClient, name } : subClient
    );
    setFormData(prevFormData => ({
      ...prevFormData,
      sub_clients: updatedSubClients
    }));
  };

  const handleLogoUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setLogoFile(e.target.files[0]);
    }
  };

  const handleSubClientLogoUpload = (index: number, e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSubClientLogoFiles({
        ...subClientLogoFiles,
        [index]: e.target.files[0]
      });
    }
  };

  const uploadLogoAndGetId = async (file: File): Promise<string | null> => {
    const formData = new FormData();
    formData.append('image', file);
  
    try {
      const imageUrl = await uploadImage(formData);
      return imageUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      return null;
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // if (formData.sub_clients.length === 0) {
    //   setErrorMessage('There must be at least one subclient.');
    //   return;
    // }

    setIsSaving(true);
    setErrorMessage(null);

    try {
      let logoUrl = formData.logo_url;

      if (logoFile) {
        const imageUrl = await uploadLogoAndGetId(logoFile);
        if (imageUrl) {
          logoUrl = imageUrl;
        }
      }

      const updatedSubClients = await Promise.all(
        formData.sub_clients.map(async (subClient, index) => {
          if (subClientLogoFiles[index]) {
            const imageUrl = await uploadLogoAndGetId(subClientLogoFiles[index]!);
            return { ...subClient, logo_url: imageUrl || subClient.logo_url };
          }
          return subClient;
        })
      );

      await createClient({
        name: formData.name,
        logo_url: logoUrl,
        sub_clients: updatedSubClients
      });

      await getClients();
      closeModalAction();
    } catch (error) {
      console.error("Error create client:", error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Modal.Body className="p-card py-0">
        <div className="work-order">
          {/* {errorMessage && <p className='text-danger'>{errorMessage}</p>} */}
          <div className="mt-2 mb-3">
            <FormLabel>Name <span style={{ color: 'red' }}>*</span></FormLabel>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="form-control"
              placeholder="Enter client name"
              title='Complete this field'
              required
            />
          </div>

          <div className="mb-3">
            <FormLabel>Client Logo</FormLabel>
            <input
              type="file"
              onChange={handleLogoUpload}
              className="form-control"
              accept="image/*"
            />
          </div>
          
          {formData.logo_url && (
            <div className="mt-2">
              <img src={formData.logo_url} alt="Client Logo" width="50" />
            </div>
          )}

          <hr />

          <div className="mb-3">
            <FormLabel>Sub Clients <span style={{ color: 'red' }}>*</span></FormLabel>
            {formData.sub_clients.map((subClient, index) => (
              <div key={subClient.id || index} className="mb-3">
                <div className="d-flex gap-2 mb-2">
                  <input
                    type="text"
                    value={subClient.name}
                    onChange={(e) => handleSubClientChange(index, e.target.value)}
                    placeholder="Subclient name"
                    className="form-control"
                    required
                  />
                </div>
                <div>
                  <input
                    type="file"
                    onChange={(e) => handleSubClientLogoUpload(index, e)}
                    className="form-control"
                    accept="image/*"
                  />
                  {subClient.logo_url && (
                    <div className="mt-2">
                      <img src={subClient.logo_url} alt="Subclient Logo" width="50" />
                    </div>
                  )}
                </div>
                <hr />
              </div>
            ))}

            <input
              type="text"
              value={newSubClientName}
              onChange={handleNewSubClientChange}
              placeholder="Add new subclient"
              className="form-control mt-2"
            />
            <Button onClick={handleAddSubClient} className="mt-2">
              Add Subclient
            </Button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center border-0">
        <Button variant="primary" className="px-4" type="submit" disabled={isSaving}>
          {isSaving ? (
            "Saving..."
          ) : (
            "Save"
          )}
        </Button>
      </Modal.Footer>
    </Form>
  );
};

export default CreateNewClient;