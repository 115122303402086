import React, {
  PropsWithChildren,
  useState,
  useEffect,
  useCallback
} from 'react';
import SearchBox, { SearchBoxProps } from './SearchBox';
import { Dropdown } from 'react-bootstrap';
import lodash from 'lodash';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import { Link, useNavigate } from 'react-router-dom';

interface WorkOrder {
  id: string;
  code: string;
  status: string;
  service_category: string;
}

interface DropdownSearchBoxProps extends SearchBoxProps {
  className?: string;
  searchBoxClassName?: string;
}

const DropdownSearchBox: React.FC<
  PropsWithChildren<DropdownSearchBoxProps>
> = ({ children, className, searchBoxClassName, ...rest }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [filteredWorkOrders, setFilteredWorkOrders] = useState<WorkOrder[]>([]);

  const { findWorkOrderByCode, workOrderFound } = useWorkOrderContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (workOrderFound) {
      setOpenDropdown(true);
      setFilteredWorkOrders([workOrderFound]);
    } else {
      setFilteredWorkOrders([]);
    }
  }, [workOrderFound]);

  const search = useCallback(
    (searchTerm: string) => {
      if (searchTerm.length >= 5) {
        findWorkOrderByCode(searchTerm);
      }
    },
    [findWorkOrderByCode]
  );


  const debouncedSearch = useCallback(
    lodash.debounce(searchTerm => search(searchTerm), 800),
    [search]
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    debouncedSearch.cancel();
    setSearchInputValue(value);
    debouncedSearch(value);
  };


  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (searchInputValue.length >= 4) {
        search(searchInputValue);
      }
    }
  };

  return (
    <Dropdown
      className={className}
      onToggle={() => setOpenDropdown(!openDropdown)}
      show={openDropdown}>
      <Dropdown.Toggle as="div" aria-expanded={openDropdown} bsPrefix="toggle">
        <SearchBox
          placeholder="Search by work order code..."
          className={searchBoxClassName}
          value={searchInputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          {...rest}
        />
      </Dropdown.Toggle>
      {children && (
        <Dropdown.Menu
          className="dropdown-menu border border-300 font-base start-0 py-0 overflow-hidden w-100"
          style={{ width: 400 }}>
          {filteredWorkOrders.map(order => (
            <Dropdown.Item
              key={order.id}
              onClick={() => {
                navigate(`/workorder-data/${order.code}`);
              }}>
              <div className="d-flex align-items-center gap-2">
                <span>{order.code}</span>
                <span style={{ textTransform: 'capitalize' }}>
                  {order.status.replace('_', ' ')}
                </span>
                <span>- {order.service_category}</span>
              </div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default DropdownSearchBox;