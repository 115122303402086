import classNames from 'classnames';
import NavbarTopHorizontal from 'components/navbars/navbar-horizontal/NavbarTopHorizontal';
import { isLogged } from 'helpers/login-utils';
import ClientsProvider, { useClientsContext } from 'providers/ClientsProvider';
import { useMainLayoutContext } from 'providers/MainLayoutProvider';
import ModalsProvider from 'providers/ModalsProvider';
import WorkOrderProvider, {
  useWorkOrderContext
} from 'providers/WorkOrderProvider';
import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Outlet, useNavigate } from 'react-router-dom';

const MainLayout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (isLogged() === false) {
      navigate('/login');
    }
  }, []);

  const { contentClass } = useMainLayoutContext();

  const { getServiceAreas, getTechnicians, getServiceCategories, clients, getClients } =
    useWorkOrderContext();

  useEffect(() => {
    getServiceAreas();
    getClients();
    getServiceCategories();
    getTechnicians();
    getClients();
  }, []);

  return (
    <Container fluid className="px-0">
      <NavbarTopHorizontal />
      <div style={{maxWidth: '1800px', margin: '0 auto'}} className={classNames(contentClass, 'content')}>
        <Outlet />
      </div>
    </Container>
  );
};

const index = () => {
  return (
    <ClientsProvider>
      <WorkOrderProvider>
        <ModalsProvider>
          <MainLayout />
        </ModalsProvider>
      </WorkOrderProvider>
    </ClientsProvider>
  );
};

export default index;
