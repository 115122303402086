import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { Form, Modal, FormLabel, Button } from 'react-bootstrap';
import { Option } from 'react-bootstrap-typeahead/types/types';
import geocodeByAddress from 'helpers/maps-utils';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';

const NewServiceAreaForm = ({
  closeModalAction
}: {
  closeModalAction: () => void;
}) => {
  const { createServiceArea } = useWorkOrderContext();

  const [formData, setFormData] = useState({
    name: '',
    lat: null,
    long: null
  });

  const [selectedAddress, setSelectedAddress] = useState<Option | null>(null);
  const [results, setResults] = useState<google.maps.GeocoderResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [addressValid, setAddressValid] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (addressValid) {
      createServiceArea(formData);
      closeModalAction();
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Modal.Body className="p-card py-0">
        <div className="work-order">
          <div className="mt-2 mb-3">
            <FormLabel>
              Name or Location <span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <AsyncTypeahead
              id="residence_address"
              isLoading={loading}
              onChange={(selectedOptions: Option[]) => {
                const selectedAddress = selectedOptions[0];
                if (
                  selectedAddress &&
                  typeof selectedAddress === 'object' &&
                  'label' in selectedAddress
                ) {
                  setFormData({
                    ...formData,
                    name: selectedAddress.label,
                    lat: selectedAddress.location.lat,
                    long: selectedAddress.location.lng
                  });
                  setAddressValid(true);
                } else {
                  setAddressValid(false);
                }
                setSelectedAddress(selectedAddress);
              }}
              filterBy={() => true}
              onSearch={async query => {
                setLoading(true);
                try {
                  const _results = await geocodeByAddress(query);
                  setResults(_results);
                } catch (err) {
                  console.error({ err });
                } finally {
                  setLoading(false);
                }
              }}
              options={results.map(r => ({
                id: r.place_id,
                label: r.formatted_address,
                ...r,
                location: {
                  lat: r.geometry.location.lat(),
                  lng: r.geometry.location.lng()
                }
              }))}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center border-0">
        <Button
          variant="primary"
          className="px-4"
          type="submit"
          disabled={!addressValid}>
          Save
        </Button>
      </Modal.Footer>
    </Form>
  );
};

export default NewServiceAreaForm;
