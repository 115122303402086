import { WorkOrder } from 'api/types/work-orders';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useReducer
} from 'react';

import {
  ModalsState,
  SHOW_MODAL_SCHEDULE_WORK_ORDER,
  SHOW_MODAL_WORK_ORDER_ON_MAP,
  modalsReducer
} from 'reducers/ModalsReducer';

interface ModalsContextInterface extends ModalsState {
  dispatchShowModalScheduleWorkOrder: (
    show: boolean,
    workOrderId?: number
  ) => void;
  dispatchShowWorkOrderOnMap: (show: boolean, workOrder?: WorkOrder) => void;
}

export const ModalsContext = createContext({} as ModalsContextInterface);

const ModalsProvider = ({ children }: PropsWithChildren) => {
  const initialState: ModalsState = {
    showModalScheduleWorkOrder: false,
    showModalWorkOrderOnMap: false
  };

  const [modalsState, modalsDispatch] = useReducer(modalsReducer, initialState);

  const dispatchShowModalScheduleWorkOrder = async (show = false) => {
    modalsDispatch({ payload: show, type: SHOW_MODAL_SCHEDULE_WORK_ORDER });
  };

  const dispatchShowWorkOrderOnMap = async (show = false) => {
    modalsDispatch({ payload: show, type: SHOW_MODAL_WORK_ORDER_ON_MAP });
  };

  return (
    <ModalsContext.Provider
      value={{
        ...modalsState,
        dispatchShowModalScheduleWorkOrder,
        dispatchShowWorkOrderOnMap
      }}>
      {children}
    </ModalsContext.Provider>
  );
};

export const useModalsContext = () => useContext(ModalsContext);

export default ModalsProvider;
