import ServiceCategoryTable from "components/tables/ServiceCategoryTable";

const ServiceCategoryAbm = () => {
    return (
        <div className="p-3">
            <h3 style={{ textAlign: 'center'}} >Service Category Table</h3>
            <ServiceCategoryTable />
        </div>
    );
}

export default ServiceCategoryAbm;