import React, { useState, useEffect } from 'react';
import { Form, Modal, FormLabel, Button } from 'react-bootstrap';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';

interface ClientData {
  id: number;
  name: string;
  logo_url?: string;
  sub_clients?: SubClientData[];
}

interface SubClientData {
  id?: number;
  name: string;
  logo_url?: string;
}

interface EditClientProps {
  closeModalAction: () => void;
  updateClientData: ClientData;
}

const EditClient: React.FC<EditClientProps> = ({ closeModalAction, updateClientData }) => {
  const { updateClient, getClients, uploadImage } = useWorkOrderContext();

  const [formData, setFormData] = useState<ClientData>({
    id: updateClientData.id,
    name: updateClientData.name,
    logo_url: updateClientData.logo_url,
    sub_clients: updateClientData.sub_clients
  });  

  const [newSubClientName, setNewSubClientName] = useState<string>('');
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [subClientLogoFiles, setSubClientLogoFiles] = useState<{ [key: number]: File | null }>({});
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setFormData(updateClientData);
  }, [updateClientData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleNewSubClientChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewSubClientName(e.target.value);
  };

  const handleAddSubClient = () => {
    if (newSubClientName.trim()) {
      setFormData(prevFormData => ({
        ...prevFormData,
        sub_clients: [
          ...(prevFormData.sub_clients || []),
          { name: newSubClientName }
        ]
      }));
      setNewSubClientName('');
    }
  };

  const handleSubClientChange = (index: number, name: string) => {
    const updatedSubClients = formData.sub_clients!.map((subClient, i) =>
      i === index ? { ...subClient, name } : subClient
    );
    setFormData(prevFormData => ({
      ...prevFormData,
      sub_clients: updatedSubClients
    }));
  };

  const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setLogoFile(e.target.files[0]);
    }
  };

  const handleSubClientLogoUpload = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSubClientLogoFiles({
        ...subClientLogoFiles,
        [index]: e.target.files[0]
      });
    }
  };

  const uploadLogoAndGetId = async (file: File): Promise<string | null> => {
    const formData = new FormData();
    formData.append('image', file);
  
    try {
      const imageUrl = await uploadImage(formData);
      return imageUrl;
    } catch (error) {
      console.error('Error uploading image:', error);
      return null;
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSaving(true);
    try {
      let logoUrl = formData.logo_url;

      if (logoFile) {
        const imageUrl = await uploadLogoAndGetId(logoFile);
        if (imageUrl) {
          logoUrl = imageUrl; 
        }
      }

      const updatedSubClients = await Promise.all(
        formData.sub_clients!.map(async (subClient, index) => {
          if (subClientLogoFiles[index]) {
            const imageUrl = await uploadLogoAndGetId(subClientLogoFiles[index]!);
            if (imageUrl) {
              return { ...subClient, logo_url: imageUrl };
            }
          }
          return subClient;
        })
      );

      await updateClient(formData.id, {
        id: formData.id,
        name: formData.name,
        logo_url: logoUrl,
        sub_clients: updatedSubClients.map(subClient => ({
          id: subClient.id,
          name: subClient.name,
          logo_url: subClient.logo_url,
          active: true,
        }))
      });

      closeModalAction();
      await getClients();
    } catch (error) {
      console.error('Error updating client and subclients:', error);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Modal.Body className="p-card py-0">
        <div className="work-order">
          <div className="mt-3 mb-3">
            <FormLabel>Client Name</FormLabel>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="form-control"
              placeholder="Enter client name"
              required
            />
          </div>

          <div className="mb-3">
            {/* <FormLabel>{formData.name} Logo</FormLabel> */}
            <input
              type="file"
              onChange={handleLogoUpload}
              className="form-control"
              accept="image/*"
            />
          </div>
          {formData.logo_url && (
            <div className="mt-2">
              <img src={formData.logo_url} alt="Subclient Logo" width="50" />
            </div>
          )}
          <hr />

          <div className="mb-3">
            <FormLabel>Sub Clients</FormLabel>
            {formData.sub_clients?.map((subClient, index) => (
              <div key={subClient.id || index} className="mb-3">
                <div className="d-flex gap-2 mb-2">
                  <input
                    type="text"
                    value={subClient.name}
                    onChange={(e) => handleSubClientChange(index, e.target.value)}
                    placeholder="Subclient name"
                    className="form-control"
                  />
                </div>
                <div>
                  {/* <FormLabel>{subClient.name} Logo</FormLabel> */}
                  <input
                    type="file"
                    onChange={(e) => handleSubClientLogoUpload(index, e)}
                    className="form-control"
                    accept="image/*"
                  />
                  {subClient.logo_url && (
                    <div className="mt-2">
                      <img src={subClient.logo_url} alt="Subclient Logo" width="50" />
                    </div>
                  )}
                </div>
                <hr />
              </div>
            ))}

            <input
              type="text"
              value={newSubClientName}
              onChange={handleNewSubClientChange}
              placeholder="Add new subclient"
              className="form-control mt-2"
            />
            <Button onClick={handleAddSubClient} className="mt-2">
              Add Subclient
            </Button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center border-0">
        <Button variant="primary" className="px-4" type="submit">
        {isSaving ? (
            "Saving..."
          ) : (
            "Save"
          )}
        </Button>
      </Modal.Footer>
    </Form>
  );
};

export default EditClient;
