import Lightbox from 'components/base/LightBox';
import useLightbox from 'hooks/useLightbox';

interface RenderImageScheduleProps {
  images: string[];
}

const RenderImageSchedule = ({ images }: RenderImageScheduleProps) => {
  const { lightboxProps, openLightbox } = useLightbox(images);

  const imagesToShow = images.slice(0, 2);

  return (
    <div className="d-flex flex-row">
      <Lightbox {...lightboxProps} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '5px'
        }}>
        {imagesToShow.map((image, index) => (
          <div key={index} onClick={() => openLightbox(index + 1)}>
            <img
              src={image}
              style={{
                width: '65px',
                height: '65px',
                borderRadius: '8px',
                cursor: 'pointer'
              }}
            />
          </div>
        ))}
        {images.length > 2 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '65px',
              height: '65px',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              borderRadius: '8px',
              cursor: 'pointer'
            }}
            onClick={() => openLightbox(2)}>
            <span
              style={{
                fontSize: '20px',
                marginLeft: '-4px',
                color: 'white'
              }}>
              +{images.length - 2}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
const ImageRenderSchedule = ({ workOrder }) => {
  return (
    <div>
      <RenderImageSchedule images={workOrder.images} />
    </div>
  );
};

export default ImageRenderSchedule;
