import { Form } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useState, useEffect } from 'react';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';

const LeftMenu = ({ activeButton, serviceAreaFilters }) => {
  const { searchWorkOrders, workOrders, snoozedCount, getTotalSnoozedCount } = useWorkOrderContext();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filtersToShow, setFiltersToShow] = useState(null);
  const [filtersSet, setFiltersSet] = useState(false);
  const [openGroups, setOpenGroups] = useState([]);
  const [filtersState, setFiltersState] = useState([]);

  const handleAccordionToggle = group => {
    if (openGroups.includes(group)) {
      setOpenGroups(openGroups.filter(g => g !== group));
    } else {
      setOpenGroups([...openGroups, group]);
    }
  };

  const searchWorkOrdersStandard = filters => {
    searchWorkOrders({
      ...filters,
      perPage: 10
    });
  };

  const onSelectFilter = filter => {
    const filtersWithGroup = selectedFilters.filter(
      sf => sf.group === filter.group
    );
    const existentFilter = filtersWithGroup.find(f =>
      f.filter && f.filter.constructor === Object && 'id' in f.filter
        ? f.filter.id === filter?.filter.id
        : f.filter === filter.filter
    );

    let _selectedFilters;

    if (existentFilter) {
      const withoutUnselected = selectedFilters.filter(f =>
        f.filter?.constructor === Object && 'id' in f.filter
          ? f.filter.id !== filter.filter.id
          : f.filter !== filter.filter
      );
      _selectedFilters = withoutUnselected;
    } else {
      _selectedFilters = [...selectedFilters, filter];
    }
    setSelectedFilters(_selectedFilters);

    const technicians = _selectedFilters.filter(
      sf => sf.group === 'technicians'
    );
    const technicianIds = technicians.map(t => t.filter.id);

    const clients = _selectedFilters.filter(sf => sf.group === 'clients');
    let clientIds = clients?.map(c => c.filter.id);

    const prioritiesFilters = _selectedFilters.filter(
      sf => sf.group === 'priority'
    );

    const priorities = prioritiesFilters.map(p => p.filter);

    const serviceAreas = _selectedFilters.filter(
      sf => sf.group === 'service_areas'
    );

    const customStatuses = _selectedFilters
      .filter(
        sf =>
          ![
            'service_areas',
            'technicians',
            'priority',
            'clients',
            'due_date',
            'snoozed',
            'billing'
          ].includes(sf.group)
      )
      .map(f => f.filter);

    const dueOverdueFilters = _selectedFilters
      .filter(sf => sf.group === 'due_date')
      .map(f => f.filter);

    const snoozedReasons = _selectedFilters
      .filter(sf => sf.group === 'snoozed')
      .map(f => f.filter);

    const billingStatuses = _selectedFilters
      .filter(sf => sf.group === 'billing')
      .map(f => f.filter);

    const searchFilters = {
      statuses: customStatuses,
      statusGroup: activeButton,
      technicianIds: technicianIds,
      clientIds,
      priorities,
      dueOverdueFilters,
      snoozedReasons,
      billingStatuses,
      page: 1
    };

    searchWorkOrdersStandard(searchFilters);
  };

  const getColorByFilterName = filterName => {
    switch (filterName) {
      case 'critical':
        return '#B70E06';
      case 'emergency':
        return '#D88608';
      case 'urgent':
        return '#D8D208';
      case 'routine':
        return '#0867D8';
      default:
        return '#0867D8';
    }
  };

  // Este effect actualiza filtersToShow cuando cambia snoozedCount
  useEffect(() => {
    if (snoozedCount?.filters && Object.keys(snoozedCount.filters).length > 0) {
      const _filters = { ...snoozedCount.filters };

      delete _filters.plainStatuses;
      delete _filters.todayFilters;
      const { quoting, billing, dispatching, due_date, ...shared } = _filters;
      setFiltersToShow({
        all: _filters,
        dispatching: { dispatching, due_date, ...shared },
        quoting: { quoting, ...shared },
        billing: { billing, ...shared }
      });
      setFiltersSet(true);
    }
  }, [snoozedCount]);

  // Effect para actualizar snoozedCount y otros filtros cada vez que workOrders cambie
  useEffect(() => {
    const fetchData = async () => {
      await getTotalSnoozedCount();
    };
    fetchData();
  }, [workOrders]);

  useEffect(() => {
    if (serviceAreaFilters !== undefined) {
      onSelectFilter({
        group: 'service_areas',
        filter: { id: serviceAreaFilters }
      });
    }
  }, [serviceAreaFilters]);

  if (!filtersToShow) {
    return null;
  }

  const Filters = () => {
    const activeFilters = filtersToShow[activeButton];
    if (!activeFilters) return null;
    const groups = Object.entries(filtersToShow[activeButton]);
    return groups.map(([group, _filters], index) => {
      if (group === 'service_areas') return null;
      const isOpen = openGroups.includes(group);
      return (
        <div key={group} className="left-submenu mt-2">
          <Accordion activeKey={isOpen ? group : null}>
            <Accordion.Item
              eventKey={group}
              style={
                index === groups.length - 1 ? { borderBottom: 'none' } : {}
              }>
              <Accordion.Header onClick={() => handleAccordionToggle(group)}>
                <span className="left-submenu-title fw-bold">
                  {group.replace('_', ' ').toUpperCase()}
                </span>
              </Accordion.Header>
              <Accordion.Body>
                {(Array.isArray(_filters)
                  ? _filters
                  : Object.keys(_filters)
                ).map(filter => (
                  <div key={filter?.id || filter} className="left-submenu-item">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%'
                      }}>
                      <div>
                        <Form.Check
                          type="checkbox"
                          id={
                            filter?.constructor === Object && 'id' in filter
                              ? filter.id
                              : filter
                          }
                          className="mb-0"
                          style={{ color: '#4111aa', marginLeft: '0.5rem' }}>
                          <Form.Check.Input
                            onClick={evt => {
                              evt.stopPropagation();
                              onSelectFilter({ group, filter });
                            }}
                            defaultChecked={selectedFilters.find(sf =>
                              sf?.constructor === Object && 'id' in sf
                                ? sf.id === filter.id
                                : sf.filter === filter
                            )}
                            type={'checkbox'}
                          />
                          <Form.Check.Label>
                            {(filter?.constructor === Object &&
                            'label' in filter
                              ? filter.label
                              : filter
                            )
                              .replace(/_/g, ' ')
                              .toUpperCase()}
                          </Form.Check.Label>
                        </Form.Check>
                      </div>
                      <div>
                        <div
                          className="custom-badge"
                          onClick={() =>
                            onSelectFilter({
                              filter,
                              group
                            })
                          }
                          style={{
                            backgroundColor: getColorByFilterName(filter)
                          }}>
                          {filter?.constructor === Object
                            ? filter.count
                            : _filters[filter]}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      );
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '2rem',
        width: '25%',
        marginTop: activeButton === 'quoting' || activeButton === 'billing' ? '0rem' : '3rem'
      }}>
      <div>
        <div className="left-menu-items">
          <div
            key={activeButton}
            className="left-menu-item"
            style={{
              padding: '0.5rem',
              marginBottom: '1rem',
              marginTop:
                activeButton === 'quoting' || activeButton === 'billing'
                  ? '0'
                  : '-3rem',
              backgroundColor: '#ffffff',
              boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)'
            }}>
            <Accordion defaultActiveKey={activeButton}>
              <Accordion.Item
                eventKey={activeButton}
                style={{ borderBottom: 'none' }}>
                <Accordion.Body>
                  {' '}
                  <Filters />{' '}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftMenu;
