import CustomerOrdersTable from "components/tables/CustomerOrdersTable";

const WorkOrders = () => {
    return (
        <div>
            <CustomerOrdersTable />
        </div>
    );
}

export default WorkOrders;
