import { EventClickArg } from '@fullcalendar/core';
import FullCalendar from 'components/base/FullCalendar';

import CalendarProvider, {
  useCalendarContext
} from 'providers/CalendarProvider';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import { useEffect, useState } from 'react';
import { SET_CALENDAR_STATE } from 'reducers/CalendarReducer';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Link } from 'react-router-dom';
import './calendar.scss';

const Calendar = () => {
  const { calendarDispatch } = useCalendarContext();
  const {
    scheduledWorkOrders,
    searchWorkOrdersScheduled,
    totalPages
  } = useWorkOrderContext();
  const [legendText, setLegendText] = useState('');
  const [workOrderText, setWorkOrderText] = useState('');

  useEffect(() => {
    searchWorkOrdersScheduled();
  }, []);

  useEffect(() => {
    if (scheduledWorkOrders.length > 0) {
      setLegendText(`${scheduledWorkOrders.length} scheduled of`);
      setWorkOrderText(`${totalPages} work orders`);
    } else {
      setLegendText('');
    }
  }, [scheduledWorkOrders]);

  const handleEventClick = (info: EventClickArg) => {
    if (info.event.url) {
      window.open(info.event.url);
      info.jsEvent.preventDefault();
    } else {
      calendarDispatch({
        type: SET_CALENDAR_STATE,
        payload: {
          selectedEvent: info.event
        }
      });
    }
  };

  return (
    <div
      style={{
        padding: '1rem',
        marginBottom: '1rem',
        borderRadius: '1rem',
        backgroundColor: '#ffffff',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)'
      }}>
      {legendText && (
        <div style={{ marginBottom: '1rem', textAlign: 'center' }}>
          <p>
            {legendText}
            {scheduledWorkOrders.length > 0 ? ` ${workOrderText}` : ''}
          </p>
        </div>
      )}
      <div className="mt-6 mb-9">
        <FullCalendar
          height={800}
          plugins={[dayGridPlugin, timeGridPlugin]}
          events={scheduledWorkOrders.map(order => ({
            id: order.id,
            title: `${order.scheduling[0].technicians[0].fullname} ${order.code}`,
            start: order.next_visit.date_and_time,
            url: `/workorder-data/${order.code}`,
            extendedProps: { code: order.code }
          }))}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
          eventClick={handleEventClick}
          eventContent={arg => (
            <div className="event-content">
              {arg.timeText && <div className="event-time">{arg.timeText}</div>}
              <div className="event-title">
                <Link
                  to={arg.event.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'darkblue' }}>
                  {arg.event.title}
                </Link>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

const index = () => {
  return (
    <CalendarProvider>
      <Calendar />
    </CalendarProvider>
  );
};

export default index;
