import { Button, Modal } from "react-bootstrap";

function SeeMoreTextModal({ show, text, handleClose, }) {
  if (!text) return null;
  return (
    <Modal show={show} onHide={handleClose} >
      <Modal.Header closeButton>
        <Modal.Title>{text.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {text.text}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SeeMoreTextModal;