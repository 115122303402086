import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Form, Modal, FormLabel, Button, Col } from 'react-bootstrap';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import DatePicker from 'components/base/DatePicker';

interface TechnicianData {
  first_name: string;
  last_name: string;
  technician_number: string;
  start_date: string | null;
  email: string;
}

const CreateNewTechnicians = ({
  closeModalAction
}: {
  closeModalAction: () => void;
}) => {
  const { createTechnician } = useWorkOrderContext();

  const [formData, setFormData] = useState<TechnicianData>({
    technician_number: '',
    first_name: '',
    last_name: '',
    start_date: null,
    email: null
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleStartDateChange = (date: Date[]) => {
    const formattedDate =
      date.length > 0 ? date[0].toISOString().split('T')[0] : null;
    setFormData(prevState => ({ ...prevState, start_date: formattedDate }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormSubmitted(true);
    try {
      await createTechnician(formData);
      closeModalAction();
    } catch (error) {
      console.error('Error creating technician:', error);
    }
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Modal.Body className="p-card py-0">
        <div className="mt-2 mb-3">
          <FormLabel>
            Technician Number <span style={{ color: 'red' }}>*</span>
          </FormLabel>
          <input
            title="Complete this field"
            type="text"
            name="technician_number"
            value={formData.technician_number}
            onChange={handleChange}
            className="form-control"
            placeholder="Enter technician number"
            required
          />
        </div>
        <div className="work-order">
          <div className="mt-3 mb-3">
            <FormLabel>
              First Name <span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <input
              title="Complete this field"
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              className="form-control"
              placeholder="Enter first name"
              required
            />
          </div>
          <div className="mt-3 mb-3">
            <FormLabel>
              Last Name <span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <input
              title="Complete this field"
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              className="form-control"
              placeholder="Enter last name"
              required
            />
          </div>
          <div className="mt-3 mb-3">
            <FormLabel>
              Email <span style={{ color: 'red' }}>*</span>
            </FormLabel>
            <input
              title="Complete this field"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="form-control"
              placeholder="Enter email"
              required
            />
            <span style={{ fontSize: '11px', fontStyle: 'italic'}}>Important: this email should be the one that the technician will use to register with the app</span>
          </div>
          <div className="mt-3 mb-3">
            <FormLabel>Start Date</FormLabel>
            <DatePicker
              render={(_, ref) => (
                <div style={{ position: 'relative' }}>
                  <input
                    type="text"
                    ref={ref}
                    className="form-control"
                    placeholder="H:i"
                    readOnly
                    value={formData.start_date || ''}
                    style={{ paddingRight: '35px' }}
                  />
                </div>
              )}
              onChange={handleStartDateChange}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center border-0">
        <Button variant="primary" className="px-4" type="submit" disabled={
          !formData.email || !formData.first_name || !formData.last_name || !formData.technician_number
        }>
          Save
        </Button>
      </Modal.Footer>
    </Form>
  );
};

export default CreateNewTechnicians;
