import React, { useState, useEffect, useCallback } from 'react';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import { useParams } from 'react-router-dom';
import {
  Card,
  Button,
  ButtonGroup,
  Modal,
  FormControl,
  FormLabel,
  Row
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRotateLeft,
  faPrint,
  faEdit,
  faBan
} from '@fortawesome/free-solid-svg-icons';
import MapModal from 'components/modals/MapModal';
import WorkOrderOverviewTab from './workorder-tabs/workOrderOverviewTab';
import UpdateWorkOrderScheduleModal from './UpdateWorkOrderScheduleModal';
import './workorder-data.scss';
import AddWorkOrderForm from 'components/forms/AddNewWorkOrder';
import CancelWorkOrderModal from './cancelWorkOrderModal';
import ProposalTab from './workorder-tabs/proposalTab';
import { formatAsDateAndTime, formatAsTime } from 'helpers/date-utils';
import ConfirmationModal from 'components/modals/ConfirmDiscardChanges';
import LogoAnimated from 'components/icons/logo-animated';
import SeeMoreTextModal from 'components/modals/SeeMoreTextModal';
import CheckinCheckoutImages from './workorder-tabs/checkin-checkout';
import { formatAsCurrency } from 'helpers/currency-utils';
import MaterialTab from './workorder-tabs/materialsTab';
import { borderRadius } from '@mui/system';
import ScheduleWorkOrderModal from 'components/modals/ScheduleWorkOrderModal';
import { useModalsContext } from 'providers/ModalsProvider';

const WorkOrderData = () => {
  const {
    workOrderFullDetailFound,
    getFullDetailWorkOrderByCode,
    updateWorkOrderSchedule,
    cancelWorkOrder
  } = useWorkOrderContext();
  const { code } = useParams();
  const [activeTab, setActiveTab] = useState('Overview');
  const [showMapModal, setShowMapModal] = useState(false);
  const [address, setAddress] = useState('');
  const { technicians } = useWorkOrderContext();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showSeeMoreTextModal, setShowSeeMoreTextModal] = useState(false);
  const [seeMoreText, showSeeMoreText] = useState(null);
  const [isEditableTechnician, setEditableTechnician] = useState(false);

  const { dispatchShowModalScheduleWorkOrder } = useModalsContext();
  const { setSelectedWorkOrder } = useWorkOrderContext();

  const handleClose = () => {
    setShowConfirmation(false);
    closeWorkOrderModal();
    getFullDetailWorkOrderByCode(code);
  };

  const closeWorkOrderModal = () => {
    setShowMapModal(false);
    setShowUpdateModal(false);
    setOpenEditModal(false);
    setShowCancelModal(false);
  };

  const handleCancelWorkOrder = async reason => {
    cancelWorkOrder(workOrderFullDetailFound.id, reason);
    setShowCancelModal(false);
  };

  const handleOpenCancelModal = () => {
    setShowCancelModal(true);
  };

  const handleOpenEditModal = () => {
    setOpenEditModal(true);
  };

  useEffect(() => {
    getFullDetailWorkOrderByCode(code);
  }, []);

  if (!workOrderFullDetailFound) {
    return <LogoAnimated />;
  }

  const handleAddressClick = () => {
    setAddress(workOrderFullDetailFound);
    setShowMapModal(true);
  };

  const handleCloseMapModal = () => {
    setShowMapModal(false);
  };

  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };

  const handleCreateClick = () => {
    dispatchShowModalScheduleWorkOrder(true);
    setSelectedWorkOrder(workOrderFullDetailFound);
  };

  const handleEditClick = () => {
    setShowUpdateModal(true);
  };

  const refreshDataAfterClose = async () => {
    await getFullDetailWorkOrderByCode(code);
  };

  const isCancelled = workOrderFullDetailFound.status === 'cancelled';

  const getColorByFilterName = filterName => {
    switch (filterName) {
      case 'critical':
        return '#B70E06';
      case 'emergency':
        return '#D88608';
      case 'urgent':
        return '#D8D208';
      case 'routine':
        return '#0867D8';
      default:
        return '#0867D8';
    }
  };

  interface Tab {
    name: string;

    content: React.ReactNode;
  }

  const handleUpdateSubmit = async (workOrderId, scheduleId, schedule) => {
    try {
      await updateWorkOrderSchedule(workOrderId, scheduleId, schedule);

      await getFullDetailWorkOrderByCode(code);

      setShowUpdateModal(false);
    } catch (error) {
      console.error('Error updating work order schedule:', error);
    }
  };

  const tabContent: Tab[] = [
    { name: 'Overview', content: <WorkOrderOverviewTab /> },
    { name: 'Comunication', content: 'Comunication' },
    { name: 'Checkin/out', content: <CheckinCheckoutImages /> },
    {
      name: 'Proposal',
      content: <ProposalTab />
    },
    { name: 'Invoice', content: 'Invoice' },
    { name: 'Materials', content: <MaterialTab /> }
  ];

  return (
    <div>
      <SeeMoreTextModal
        handleClose={() => setShowSeeMoreTextModal(false)}
        show={showSeeMoreTextModal}
        text={seeMoreText}
      />
      <div className="d-flex workorder-data " style={{ height: '66vh' }}>
        <Card
          className="work-order-data-container"
          style={{
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
            padding: '1rem',
            borderRadius: '0.5rem',
            height: '64vh',
            minWidth: '327px'
          }}>
          <div className="d-flex work-order-logo-box">
            <div className="work-order-data-logo">
              <div className="d-flex gap-3 work-order-logo-container">
                <div className="work-order-logo">
                  {workOrderFullDetailFound.client.logo_url ? (
                    <img
                      src={workOrderFullDetailFound.client.logo_url}
                      alt="Client Logo"
                      title="Client"
                    />
                  ) : (
                    <img src="/no-image.webp" alt="No default Image" />
                  )}
                </div>
                {workOrderFullDetailFound.sub_client?.logo_url && (
                  <div className="work-order-logo">
                    {workOrderFullDetailFound.sub_client.logo_url ? (
                      <img
                        src={workOrderFullDetailFound.sub_client.logo_url}
                        alt="SubClient Logo"
                        title="Subclient"
                      />
                    ) : (
                      <img src="/no-image.webp" alt="No default image" />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="work-order-left-data">
            <div className="work-order-data-title fw-bold fs-5">
              {workOrderFullDetailFound?.code.toUpperCase()}
            </div>
            <div
              className="work-order-data-address"
              onClick={() => handleAddressClick()}>
              {workOrderFullDetailFound.residence_address}{' '}
            </div>
            <div className="work-order-data-description">
              <div>
                {workOrderFullDetailFound.client.name}{' '}
                {workOrderFullDetailFound.sub_client.name !== '' &&
                  `- ${workOrderFullDetailFound.sub_client.name}`}
              </div>
              <div className="work-order-data-contact d-flex flex-row gap-5">
                <div>
                  <span className="work-order-contact">
                    {workOrderFullDetailFound.resident_name}
                  </span>
                </div>
                <div>
                  <span className="work-order-contact">
                    {workOrderFullDetailFound.resident_phone}
                  </span>
                </div>
              </div>
              <div className="work-order-data-email d-flex flex-row">
                <div>
                  <span className="work-order-contact">
                    {workOrderFullDetailFound.resident_email}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Card>

        <Card
          style={{
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
            height: '64vh',
            width: '90%',
            padding: '1rem',
            borderRadius: '0.5rem'
          }}>
          <div className="d-flex justify-content-between flex-row work-order-data-title fw-bold fs-5 gap-5 ">
            <div className="d-flex flex-row gap-5">
              <div>
                {workOrderFullDetailFound.status
                  .replace(/_/g, ' ')
                  .toUpperCase()}
              </div>
            </div>
            <div className="p-2 d-flex flex-row gap-3 mb-5">
              <FontAwesomeIcon
                icon={faEdit}
                className={`work-order-icon ${isCancelled ? 'fa-disabled' : ''}`}
                onClick={isCancelled ? null : handleOpenEditModal}
                size="xl"
                style={{ marginLeft: '0.5rem' }}
              />
              <Modal size="lg" show={openEditModal} onHide={handleClose}>
                <AddWorkOrderForm
                  confirmModalAction={() => setShowConfirmation(true)}
                  closeModalAction={() => handleClose()}
                  workOrderToEdit={workOrderFullDetailFound}
                  workOrderCode={workOrderFullDetailFound.code}
                />
              </Modal>

              <ConfirmationModal
                show={showConfirmation}
                handleConfirm={handleClose}
                handleClose={() => setShowConfirmation(false)}
              />

              <FontAwesomeIcon
                icon={faRotateLeft}
                className="work-order-icon"
                title="Reload data"
                onClick={() => {
                  getFullDetailWorkOrderByCode(code);
                }}
              />
              <FontAwesomeIcon
                icon={faPrint}
                className="work-order-icon fa-disabled"
                aria-disabled
                title="not available for now in Beta version"
              />
              <FontAwesomeIcon
                icon={faBan}
                className={`work-order-icon ${isCancelled ? 'fa-disabled' : ''}`}
                title="Cancel this work order"
                style={{ color: 'red' }}
                onClick={isCancelled ? null : handleOpenCancelModal}
              />
              <CancelWorkOrderModal
                onCancelWorkOrder={reason => handleCancelWorkOrder(reason)}
                show={showCancelModal}
                onHide={() => setShowCancelModal(false)}
              />

              {/* <FontAwesomeIcon icon={faEllipsisV} className="work-order-icon" /> */}
            </div>
          </div>
          <div className="work-order-info d-flex flex-column flex-wrap">
            <div className="work-order-info-basic-data">
              <div className="col-md-6">
                <div>
                  <p className="work-order-title">Client NTE:</p>
                  <p className="work-order-field-value">
                    {formatAsCurrency(workOrderFullDetailFound.nte)}
                  </p>
                </div>
                <div>
                  <p className="work-order-title">Tech NTE:</p>
                  <p className="work-order-field-value">
                    {workOrderFullDetailFound.tech_nte &&
                      formatAsCurrency(workOrderFullDetailFound.tech_nte)}
                    {!workOrderFullDetailFound.tech_nte && 'No data'}
                  </p>
                </div>
                <div>
                  <p className="work-order-title">Priority:</p>
                  <div
                    style={{
                      display: 'flex',
                      gap: '0.5rem',
                      flexDirection: 'row',
                      alignItems: 'start'
                    }}>
                    <div
                      className="custom-badge work-order-priority work-order-priority-text"
                      style={{
                        backgroundColor: getColorByFilterName(
                          workOrderFullDetailFound.priority
                        ),
                        color: 'white',
                        padding: '0.5rem',
                        borderRadius: '4px',
                        marginBottom: '0.5rem'
                      }}>
                      <span>{workOrderFullDetailFound.priority}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="work-order-title">Service Category:</p>
                  <p className="work-order-field-value">
                    {workOrderFullDetailFound.service_category.name}
                  </p>
                </div>
                <div>
                  <p className="work-order-title">Resident Name:</p>
                  <p className="work-order-field-value">
                    {workOrderFullDetailFound.resident_name}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <p className="work-order-title">Created/Updated at:</p>
                  <p className="work-order-field-value">
                    <span className="work-order-date-time">
                      {formatAsDateAndTime(workOrderFullDetailFound.created_at)}
                    </span>
                    <span className="work-order-date-time ms-1">
                      -{' '}
                      {formatAsDateAndTime(workOrderFullDetailFound.updated_at)}
                    </span>
                  </p>
                </div>
                <div>
                  <div className="d-flex">
                    <p className="work-order-title">Scheduling:</p>
                    <FontAwesomeIcon
                      icon={faEdit}
                      onClick={
                        isCancelled
                          ? null
                          : () => {
                              if (
                                workOrderFullDetailFound.scheduling &&
                                workOrderFullDetailFound.scheduling.length > 0
                              ) {
                                handleEditClick();
                              } else {
                                handleCreateClick();
                              }
                            }
                      }
                      className={`work-order-icon icon-edit-schedule ${isCancelled ? 'fa-disabled' : ''}`}
                      size="sm"
                      style={{ marginLeft: '1rem', marginRight: '0.5rem' }}
                    />
                  </div>
                  <p className="work-order-field-value">
                    <span className="work-order-date-time">
                      {workOrderFullDetailFound.scheduling &&
                      workOrderFullDetailFound.scheduling.length > 0
                        ? formatAsDateAndTime(
                            workOrderFullDetailFound.scheduling[0].date_and_time
                          )
                        : 'No Schedule'}
                      {' - '}
                      <span className="work-order-date-time">
                        {workOrderFullDetailFound.next_visit
                          ? formatAsTime(
                              workOrderFullDetailFound.next_visit
                                .date_and_time_end
                            )
                          : 'No End Time'}
                      </span>
                    </span>

                    {workOrderFullDetailFound.next_visit ? (
                      <UpdateWorkOrderScheduleModal
                        workOrderId={workOrderFullDetailFound.id}
                        scheduleVisit={workOrderFullDetailFound.next_visit}
                        show={showUpdateModal}
                        onHide={() => setShowUpdateModal(false)}
                        onSubmit={handleUpdateSubmit}
                      />
                    ) : (
                      <ScheduleWorkOrderModal onClose={refreshDataAfterClose} />
                    )}
                  </p>
                </div>
                <div>
                  <p className="work-order-title">Technicians:</p>
                  <p className="work-order-field-value">
                    {workOrderFullDetailFound.scheduling &&
                    workOrderFullDetailFound.scheduling.length > 0 &&
                    workOrderFullDetailFound.scheduling[0].technicians &&
                    workOrderFullDetailFound.scheduling[0].technicians.length >
                      0 ? (
                      workOrderFullDetailFound.scheduling[0].technicians.map(
                        technician =>
                          isEditableTechnician ? (
                            <div
                              key={technician.id}
                              className="d-flex flex-column gap-2">
                              <Row>
                                <FormLabel>Lead:</FormLabel>
                                <FormControl
                                  as="select"
                                  style={{
                                    maxWidth: '200px'
                                  }}
                                  value={technician?.id || ''}
                                  defaultValue={technician?.id || ''}
                                  onChange={e => {
                                    console.log(e.currentTarget.value);
                                  }}>
                                  {technicians
                                    .filter(
                                      // show only technicians that are not already in the schedule
                                      possibleFreeTechnician =>
                                        !workOrderFullDetailFound.scheduling[0].technicians.some(
                                          scheduledTechnician =>
                                            scheduledTechnician.id ===
                                            possibleFreeTechnician.id
                                        )
                                    )
                                    .map((technician: any) => (
                                      <option
                                        key={technician.id}
                                        value={technician.id}>
                                        {technician.technician_number}{' '}
                                        {technician.first_name}{' '}
                                        {technician.last_name}
                                      </option>
                                    ))}
                                </FormControl>
                              </Row>
                              <Button
                                variant="phoenix-link"
                                style={{
                                  maxWidth: '100px',
                                  padding: 0,
                                  textAlign: 'start',
                                  color: 'green'
                                }}
                                onClick={() => setEditableTechnician(false)}>
                                Save
                              </Button>
                            </div>
                          ) : (
                            <div
                              key={technician.id}
                              className="d-flex flex-row gap-2"
                              onClick={() => setEditableTechnician(true)}>
                              <span className="work-order-contact">
                                {technician.fullname}
                              </span>
                              <span className="work-order-contact">
                                {technician.type}
                              </span>
                            </div>
                          )
                      )
                    ) : (
                      <span className="">No technicians assigned yet.</span>
                    )}
                  </p>
                </div>
                <div>
                  <p className="work-order-title">Time Work Finished:</p>
                  <p className="work-order-field-value">
                    {formatAsDateAndTime(
                      workOrderFullDetailFound.time_finished
                    ) || 'Not finished yet'}
                  </p>
                </div>
                <div>
                  <p className="work-order-title">Working Time:</p>
                  <p className="work-order-field-value">
                    {workOrderFullDetailFound.working_time ||
                      'Not finished yet'}
                  </p>
                </div>
              </div>
            </div>
            <div className="work-order-info-details">
              <div className="col-md-8 work-order-description">
                {' '}
                <p className="work-order-title">Description:</p>
                <p
                  className="work-order-field-value"
                  onClick={
                    workOrderFullDetailFound.issue_resident_detail?.length > 96
                      ? () => {
                          showSeeMoreText({
                            text: workOrderFullDetailFound.issue_resident_detail,
                            title: 'Description'
                          });
                          setShowSeeMoreTextModal(true);
                        }
                      : undefined
                  }
                  title={
                    workOrderFullDetailFound.issue_resident_detail?.length > 96
                      ? 'Click to see more'
                      : ''
                  }
                  style={{
                    cursor:
                      workOrderFullDetailFound.issue_resident_detail?.length >
                      96
                        ? 'pointer'
                        : 'auto'
                  }}>
                  {workOrderFullDetailFound.issue_resident_detail}
                </p>
              </div>
              {/* <div>
              <p className="work-order-title">Additional Details:</p>
              <p className="work-order-field-value">
                {workOrderFullDetailFound.issue_technician_detail}
              </p>
            </div> */}

              <div className="col-md-4 work-order-notes">
                <p className="work-order-title">Notes:</p>
                <p
                  className="work-order-field-value"
                  onClick={
                    workOrderFullDetailFound.next_visit &&
                    workOrderFullDetailFound.next_visit.notes?.length > 96
                      ? () => {
                          showSeeMoreText({
                            text: workOrderFullDetailFound.next_visit.notes,
                            title: 'Scheduling notes'
                          });
                          setShowSeeMoreTextModal(true);
                        }
                      : undefined
                  }
                  title={
                    workOrderFullDetailFound.next_visit &&
                    workOrderFullDetailFound.next_visit.notes?.length > 96
                      ? 'Click to see more'
                      : ''
                  }
                  style={{
                    cursor:
                      workOrderFullDetailFound.next_visit &&
                      workOrderFullDetailFound.next_visit.notes?.length > 96
                        ? 'pointer'
                        : 'auto'
                  }}>
                  {workOrderFullDetailFound.next_visit &&
                    workOrderFullDetailFound.next_visit.notes}
                </p>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <ButtonGroup
        aria-label="Basic example"
        className="workorder-data-buttons-group">
        {tabContent.map(tab => (
          <Button
            key={tab.name}
            variant="phoenix-secondary"
            onClick={() => handleTabClick(tab.name)}
            active={activeTab === tab.name}
            style={{
              boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
              backgroundColor: activeTab === tab.name ? '#1e6fbb' : '#ffffff',
              color: activeTab === tab.name ? '#ffffff' : '#000000'
            }}>
            {tab.name}
          </Button>
        ))}
      </ButtonGroup>

      {tabContent.map(
        tab =>
          activeTab === tab.name && (
            <Card
              key={tab.name}
              style={{
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
                height: '80%',
                width: '100%',
                padding: '1rem',
                marginTop: '2rem',
                borderRadius: '0.5rem'
              }}>
              {tab.content}
            </Card>
          )
      )}
      <MapModal
        workOrder={workOrderFullDetailFound}
        handleClose={handleCloseMapModal}
        show={showMapModal}
        hideInfoWindow={true}
      />
    </div>
  );
};

export default WorkOrderData;
