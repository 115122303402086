export const SHOW_MODAL_SCHEDULE_WORK_ORDER = 'MODALS/SHOW_MODAL_SCHEDULE_WORK_ORDER';
export const SHOW_MODAL_WORK_ORDER_ON_MAP = 'MODALS/SHOW_MODAL_WORK_ORDER_ONMAP';

export interface ModalsState {
  showModalScheduleWorkOrder: false,
  showModalWorkOrderOnMap: false,
}

export type SHOW_MODAL_SCHEDULE_WORK_ORDER_TYPE = {
  type: typeof SHOW_MODAL_SCHEDULE_WORK_ORDER;
  payload: boolean;
};

export type SHOW_MODAL_WORK_ORDER_ON_MAP_TYPE = {
  type: typeof SHOW_MODAL_WORK_ORDER_ON_MAP;
  payload: boolean;
};

export const modalsReducer = (
  state: ModalsState,
  action: { payload: any; type: string }
) => {
  switch (action.type) {
    case SHOW_MODAL_SCHEDULE_WORK_ORDER: {
      const { payload } = action;
      return {
        ...state,
        showModalScheduleWorkOrder: payload,
      };
    }
    case SHOW_MODAL_WORK_ORDER_ON_MAP: {
      const { payload } = action;
      return {
        ...state,
        showModalWorkOrderOnMap: payload,
      };
    }
    default:
      return state;
  }
};
