import TechniciansAbmTable from 'components/tables/TechniciansAbmTable';

const TechiniciansAbm = () => {
  return (
    <div className="p-3">
      <h3 style={{ textAlign: 'center' }}>Technicians</h3>
      <TechniciansAbmTable />
    </div>
  );
};

export default TechiniciansAbm;
