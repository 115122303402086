import React, { useState } from 'react';
import Item from 'components/work-orders/work-order-list-item';
import '../../../assets/scss/theme/_List-schedule.scss';
import ScheduleWorkOrderModal from 'components/modals/ScheduleWorkOrderModal';
import MapModal from 'components/modals/MapModal';
import PaginationButtons from 'pages/dashboard/dashboard-pages-tab/paginationButtons';
import { SearchWorkOrdersParams } from 'api/types/work-orders';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';


const WorkOrdersList = ({ workOrders }) => {
  
  const {
    workOrdersOnList,
    currentPage,
    totalPages,
    searchWorkOrders,
    totalItems
  } = useWorkOrderContext();

  const [mapModalData, setMapModalData] = useState(null);
  const [mapModalShow, setMapModalShow] = useState(false);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);

  const handlePageChange = async (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      const params: SearchWorkOrdersParams = {
        page: newPage,
        perPage: 10
      };
      await searchWorkOrders(params);
    }
  };

  const handleOpenMapModal = workOrder => {
    setMapModalData(workOrder);
    setMapModalShow(true);
  };

  const handleCloseMapModal = () => {
    setMapModalShow(false);
    setMapModalData(null);
  };

  const visiblePages = [];
  const startPage = Math.max(1, Math.min(currentPage - 3, totalPages - 6));
  const endPage = Math.min(totalPages, startPage + 6);
  for (let i = startPage; i <= endPage; i++) {
    visiblePages.push(i);
  }

  const startItem = (currentPage - 1) * 10 + 1;
  const endItem = Math.min(currentPage * 10, totalItems);

  return (
    <>
      <ScheduleWorkOrderModal onClose={''}/>

      <MapModal
        workOrder={mapModalData}
        handleClose={handleCloseMapModal}
        show={mapModalShow}
      />

      {workOrders && workOrders.length > 0 ? (
        workOrders.map(wo => (
          <Item
            key={wo.id}
            workOrder={wo}
            onSelectWorkOrder={workOrder => setSelectedWorkOrder(workOrder)}
            onViewMapClick={handleOpenMapModal}
          />
        ))
      ) : (
        <p>No work orders to display.</p>
      )}

      <PaginationButtons
        currentPage={currentPage}
        totalPages={totalPages}
        visiblePages={visiblePages}
        onPageChange={handlePageChange}
        startItem={startItem}
        endItem={endItem}
        totalItems={totalItems}
      />
    </>
  );
};

export default WorkOrdersList;
