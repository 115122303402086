import axios from 'axios';
import { getAccessToken, isLogged } from 'helpers/login-utils';

export const baseClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://www.onsitepro.ai/api/',
});


if (isLogged()) {
  baseClient.defaults.headers.common.Authorization = `Bearer ${getAccessToken()}`;
}
