import { useState, useEffect } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Modal } from 'react-bootstrap';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Button from 'components/base/Button';
import CreateNewClient from 'components/forms/CreateNewClient';
import { Client, ServiceArea } from 'api/types/work-orders';
import EditClient from 'components/forms/EditNewClient';

interface Clients {
  name: string;
  sub_clients: [];
}

const CientAbmTable = () => {
  const { client, createClient, getClients, clients = [] } = useWorkOrderContext(); // Aseguramos que `clients` tenga un valor predeterminado
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const [editModalData, setEditModalData] = useState<{
    id: number;
    name: string;
    logo_url?: string;
    sub_clients: []
  }>({
    id: 0,
    name: '',
    logo_url: '',
    sub_clients: []
  });

  useEffect(() => {
    getClients();
  }, []);

  const handleCreate = () => {
    setShowCreateModal(true);
  };

  const handleCloseModal = () => {
    setShowCreateModal(false);
  };

  const handleShowEditModal = (client: Client) => {
    setEditModalData({
      id: client.id,
      name: client.name,
      logo_url: client.logo_url,
      sub_clients: client.sub_clients || []
    });
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleDelete = (original: Clients) => {
    alert('not implemented yet');
  };

  

  const ClientsColumns: ColumnDef<Client>[] = [
    {
      accessorKey: 'name',
      header: 'Name',
      cell: ({ row: { original } }) => original.name,
      meta: {
        headerProps: { style: { width: '20%' }, className: 'text-center pe-7' },
        cellProps: { className: 'text-center fw-semi-bold text-1000 pe-7' }
      }
    },
    {
      id: 'action',
      header: 'Actions',
      cell: ({ row: { original } }) => (
        <div className="d-flex justify-content-start">
          <Button
            variant="primary"
            className="m-2"
            size="sm"
            onClick={() => handleShowEditModal(original as Client)}
          >
            Edit
          </Button>
        </div>
      ),
      meta: {
        headerProps: { style: { width: '25%' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: clients,
    columns: ClientsColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  return (
    <AdvanceTableProvider {...table}>
      <div className="border-y">
        <div
          className="d-flex flex-wrap gap-3 justify-content-end"
          style={{ paddingRight: '2rem' }}>
          <Button
            variant="success"
            size="sm"
            className="mt-3"
            onClick={() => handleCreate()}>
            Create
          </Button>
        </div>
        <Modal show={showCreateModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Create New Client</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateNewClient closeModalAction={handleCloseModal} />
          </Modal.Body>
        </Modal>
        <Modal show={showEditModal} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Client</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditClient closeModalAction={handleCloseEditModal} updateClientData={editModalData} />
          </Modal.Body>
        </Modal>

        <AdvanceTable
          tableProps={{ className: 'phoenix-table table-sm fs-9' }}
        />
        <AdvanceTableFooter pagination showViewAllBtn={false} />
      </div>
    </AdvanceTableProvider>
  );
};

export default CientAbmTable;
