import { WorkOrder } from 'api/types/work-orders';
import { WorkOrderState } from 'providers/WorkOrderProvider';

//Action types
export const GET_SERVICE_AREAS = 'GET_SERVICE_AREAS';
export const CREATE_SERVICE_AREA = 'CREATE_SERVICE_AREA';
export const CREATE_CLIENT = 'CREATE_CLIENT';
export const GET_TECHNICIANS = 'GET_TECHNICIANS';
export const GET_CLIENTS = 'GET_CLIENTES';
export const GET_SERVICE_CATEGORIES = 'GET_SERVICE_CATEGORIES';
export const CREATE_SERVICE_CATEGORIES = 'CREATE_SERVICE_CATEGORIES';
export const UPDATE_SERVICE_CATEGORIES = 'UPDATE_SERVICE_CATEGORIES';
export const SEARCH_WORK_ORDERS = 'SEARCH_WORK_ORDERS';
export const CREATE_WORK_ORDER = 'CREATE_WORK_ORDER';
export const GET_WORK_ORDERS = 'GET_WORK_ORDERS';
export const GET_WORK_ORDERS_HISTORY = 'GET_WORK_ORDERS_HISTORY';
export const GET_TODAY_WORK_ORDERS = 'GET_TODAY_WORK_ORDERS';
export const GET_ON_MAP_WORK_ORDERS = 'GET_ON_MAP_WORK_ORDERS';
export const GET_ON_CALENDAR_WORK_ORDERS = 'GET_ON_CALENDAR_WORK_ORDERS';
export const GET_ON_LIST_WORK_ORDERS = 'GET_ON_LIST_WORK_ORDERS';
export const GET_SCHEDULED_WORK_ORDERS = 'GET_SCHEDULED_WORK_ORDERS';
export const UPDATE_SERVICE_AREA = 'UPDATE_SERVICE_AREA';
export const CREATE_TECHNICIAN = 'CREATE_TECHNICIAN';
export const UPDATE_TECHNICIAN = 'UPDATE_TECHNICIAN';
export const SET_SELECTED_WORK_ORDER = 'SET_SELECTED_WORK_ORDER';
export const SCHEDULING_VISIT = 'SCHEDULING_VISIT';
export const VISIT_SCHEDULED = 'VISIT_SCHEDULED';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const FIND_ORDER_BY_CODE = 'FIND_ORDER_BY_CODE';
export const WORK_ORDER_CODE = 'WORK_ORDER_CODE';
export const UPDATE_WORK_ORDER_SCHEDULE = 'UPDATE_WORK_ORDER_SCHEDULE';
export const SET_PAGE = 'SET_PAGE';
export const UPDATE_WORK_ORDER = 'UPDATE_WORK_ORDER';
export const CREATE_USER = 'CREATE_USER';
export const FINDING_WORK_ORDER = 'FINDING_WORK_ORDER';
export const WORK_ORDER_FOUND = 'WORK_ORDER_FOUND';
export const LOADING_WORK_ORDERS = 'LOADING_WORK_ORDERS';
export const SET_CURRENT_PARAMS = 'SET_CURRENT_PARAMS';
export const SET_WORK_ORDER_IMAGES = 'SET_WORK_ORDER_IMAGES';
export const SET_SNOOZED_COUNT = 'SET_SNOOZED_COUNT';

export type WORK_ORDERS_ACTION_TYPE =
  | {
      type: typeof GET_SERVICE_AREAS;
      payload: any[];
    }
  | {
      type: typeof GET_CLIENTS;
      payload: any[];
    }
  | {
      type: typeof GET_SERVICE_CATEGORIES;
      payload: any[];
    }
  | {
      type: typeof CREATE_WORK_ORDER;
      payload: any[];
    }
  | {
      type: typeof SEARCH_WORK_ORDERS;
      payload: any[];
    }
  | { type: typeof GET_WORK_ORDERS; payload: any[] }
  | { type: typeof GET_WORK_ORDERS_HISTORY; payload: any[] }
  | { type: typeof UPDATE_SERVICE_AREA; payload: any[] }
  | { type: typeof GET_TECHNICIANS; payload: any[] }
  | {
      type: typeof CREATE_SERVICE_AREA;
      payload: any[];
    }
    | { type: typeof CREATE_TECHNICIAN; payload: any[] }
    | { type: typeof UPDATE_TECHNICIAN; payload: any[] }
    | { type: typeof CREATE_SERVICE_CATEGORIES; payload: any[] }
    | { type: typeof GET_SCHEDULED_WORK_ORDERS; payload: any[] }
    | { type: typeof SET_SELECTED_WORK_ORDER; payload: WorkOrder }
    | { type: typeof SCHEDULING_VISIT }
    | { type: typeof VISIT_SCHEDULED }
    | { type: typeof UPDATE_CLIENT }
    | { type: typeof FIND_ORDER_BY_CODE; payload: any }
    | { type: typeof UPDATE_SERVICE_CATEGORIES; payload: any[] }
    | { type: typeof UPDATE_WORK_ORDER_SCHEDULE; payload: any[] }
    | { type: typeof GET_WORK_ORDERS;
      payload: {
        workOrders: any[];
        filters: any;
        currentPage: number;
        totalPages: number;
        totalItems: number;
      };
    
    }
    | { type: typeof SET_PAGE; payload: number }
    | { type: typeof UPDATE_WORK_ORDER; payload: any[] }
    | {
      type: typeof CREATE_USER;
      payload: { email: string; password: string };
    } | {
      type: typeof WORK_ORDER_FOUND;
      payload: any;
    } | {
      type: typeof FINDING_WORK_ORDER;
    } | {
      type: typeof LOADING_WORK_ORDERS;
    } | {
      type: typeof SET_WORK_ORDER_IMAGES;
    } | { type: typeof SET_SNOOZED_COUNT; payload: number };
  
    
    

export const workOrderReducer = (
  state: WorkOrderState,
  action: { payload?: any; type: string }
): WorkOrderState => {
  const { payload } = action;
  switch (action.type) {
    case GET_CLIENTS: {
      return {
        ...state,
        clients: payload 
      };
    }
    case GET_SERVICE_AREAS: {
      return {
        ...state,
        serviceAreas: payload
      };
    }

    case GET_SERVICE_CATEGORIES: {
      return {
        ...state,
        serviceCategories: payload
      };
    }
    case UPDATE_WORK_ORDER: {
      const updatedWorkOrder = payload;
      const updatedWorkOrders = state.workOrders.map(order => {
        if (order.id === updatedWorkOrder.id) {
          return { ...order, ...updatedWorkOrder }; 
        }
        return order;
      });
    
      return {
        ...state,
        workOrders: [...updatedWorkOrders],
      };
    }    

    case CREATE_USER: {
      return {
        ...state,
        user: payload
      };
    }
    
    case CREATE_SERVICE_CATEGORIES: {
      return {
        ...state,
        serviceCategories: payload
      };
    }
    case CREATE_WORK_ORDER: {

      return {
        ...state,
        workOrders: [...state.workOrders, payload]
      };
    }

    case CREATE_SERVICE_AREA: {

      return {
        ...state,
        serviceAreas: [...state.serviceAreas, payload]
      };
    }

    case CREATE_CLIENT: {

      return {
        ...state,
        clients: [...state.clients, payload]
      };
    }

    case UPDATE_WORK_ORDER_SCHEDULE: {
      if (payload && payload.workOrderId && payload.scheduleId && payload.schedule) {
        const { workOrderId, scheduleId, schedule } = payload;
        const updatedWorkOrder = state.workOrders.find(
          (order) => order.id === workOrderId
        );
        if (updatedWorkOrder) {
          const updatedSchedule = updatedWorkOrder.schedule.map((item) => {
            if (item.id === scheduleId) {
              return { ...item, ...schedule };
            }
            return item;
          });
          const updatedWorkOrders = state.workOrders.map((order) => {
            if (order.id === workOrderId) {
              return { ...order, schedule: updatedSchedule };
            }
            return order;
          });
          return {
            ...state,
            workOrders: updatedWorkOrders,
          };
        }
      }
      return state;
    }

    case GET_SCHEDULED_WORK_ORDERS: {
      return {
        ...state,
        scheduledWorkOrders: payload.workOrders,
        appliedFilters: payload.appliedFilters,
      };
    }
    case GET_WORK_ORDERS: {
      return {
        ...state,
        loadingWorkOrders: false,
        workOrders: [...payload.workOrders],
        workOrdersOnCalendar: payload.workOrders,
        workOrdersOnList: payload.workOrders,
        workOrdersOnMap: payload.workOrders,
        filters: payload.filters,
        groups: payload.groups,
        currentPage: payload.currentPage,
        totalPages: payload.totalPages,
        totalItems: payload.totalItems,
        appliedFilters: payload.appliedFilters,
      };
    }

    case GET_WORK_ORDERS_HISTORY: {
      return {
        ...state,
        loadingWorkOrders: false,
        workOrdersHistory: {
          workOrders: payload.workOrders,
          filters: payload.filters,
          currentPage: payload.currentPage,
          totalPages: payload.totalPages,
          totalItems: payload.totalItems,
          appliedFilters: payload.appliedFilters,
        }
      };
    }

    case GET_TODAY_WORK_ORDERS: {
      return {
        ...state,
        loadingWorkOrders: false,
        todayWorkOrders: {
          workOrders: payload.workOrders,
          filters: payload.filters,
          currentPage: payload.currentPage,
          totalPages: payload.totalPages,
          totalItems: payload.totalItems,
          appliedFilters: payload.appliedFilters,
        }
      };
    }
    case GET_ON_MAP_WORK_ORDERS: {
      return {
        ...state,
        loadingWorkOrders: false,
        workOrdersOnMap: payload.workOrders,
        appliedFilters: payload.appliedFilters,
      };
    }

    case GET_ON_CALENDAR_WORK_ORDERS: {
      return {
        ...state,
        loadingWorkOrders: false,
        workOrdersOnCalendar: payload.workOrders,
        appliedFilters: payload.appliedFilters,
      };
    }

    case GET_ON_LIST_WORK_ORDERS: {
      return {
        ...state,
        loadingWorkOrders: false,
        workOrdersOnList: payload.workOrders,
        appliedFilters: payload.appliedFilters,
      };
    }
    
    case GET_TECHNICIANS: {
      return {
        ...state,
        technicians: payload
      };
    }
    case UPDATE_SERVICE_AREA: {
      const updatedServiceArea = payload;
      const updatedServiceAreas = state.serviceAreas.map(area => {
        if (area.id === updatedServiceArea.id) {
          return updatedServiceArea;
        } else {
          return area;
        }
      });

      return {
        ...state,
        serviceAreas: updatedServiceAreas
      };
    }


    
    case CREATE_TECHNICIAN: {

      return {
        ...state,
        technicians: [...state.technicians, payload]
      };
    }

    case UPDATE_TECHNICIAN: {
      const updatedTechnician = payload;
      const updatedTechnicians = state.technicians.map(technician => {
        if (technician.id === updatedTechnician.id) {
          return updatedTechnician;
        } else {
          return technician;
        }
      });

      return {
        ...state,
        technicians: updatedTechnicians
      };
    }
    case UPDATE_CLIENT: {
      const updatedClient = payload;
      const updatedClients = state.clients.map(client => {
        if (client.id === updatedClient.id) {
          return updatedClient;
        } else {
          return client;
        }
      });
      return {
        ...state,
        clients: updatedClients
      }
    }
    case UPDATE_SERVICE_CATEGORIES: {
      const updatedCategory = payload;
      const updatedCategories = state.serviceCategories.map(serviceCategory => {
        if (serviceCategory.id === updatedCategory.id) {
          return updatedCategory;
        } else {
          return serviceCategory;
        }
      });
    
      return {
        ...state,
        serviceCategories: updatedCategories
      };
    }
    case SET_SELECTED_WORK_ORDER: {

      return {
        ...state,
        selectedWorkOrder: payload,
      };
    }
    case SCHEDULING_VISIT: {

      return {
        ...state,
        schedulingVisit: true,
      };
    }
    case VISIT_SCHEDULED: {
      return {
        ...state,
        schedulingVisit: false,
      };
    }
    case FIND_ORDER_BY_CODE: {
      return {
        ...state,
        workOrderFound: payload,
      };
    }
    case FINDING_WORK_ORDER: {
      return {
        ...state,
        loadingFullDetailWorkOrder: true,
        workOrderFullDetailFound: null,
      };
    }
    case WORK_ORDER_FOUND: {
      return {
        ...state,
        loadingFullDetailWorkOrder: false,
        workOrderFullDetailFound: payload,
      };
    }
    case LOADING_WORK_ORDERS: {
      return {
        ...state,
        loadingWorkOrders: true,
      };
    }
    case SET_WORK_ORDER_IMAGES: {
      return {
        ...state,
        workOrders: state.workOrders.map(w => ({
          ...w,
          images: payload.filter(image => +image.work_order_id === +w.id).map(image => image.url)
        })),
      };
    }

    case SET_SNOOZED_COUNT: {
      return {
        ...state,
        snoozedCount: payload,
      };
    }

    default:
      return state;
  }
};
