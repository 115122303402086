import Button from 'components/base/Button';
import { useModalsContext } from 'providers/ModalsProvider';
import {  useEffect, useState } from 'react';
import moment from 'moment';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { formatAsTime, formatAsDateAndTime } from 'helpers/date-utils';
import SnoozedModal from './snoozedModal';
import ImageRenderSchedule from './renderImageSchedule';
import snoozeIcon from './snooze-icon.svg';
import unSnoozeIcon from './un-snooze-icon.svg';
import ReactivateModal from './reactivateSnoozedModal';

const maxLength = 354;

export const formatStatus = (status: any): string => {
  if (typeof status !== 'string' && !(status instanceof String)) {
    return String(status);
  }

  const words = status.replace(/_/g, ' ').split(' ');

  const formattedWords = words.map(
    word => word.charAt(0).toUpperCase() + word.slice(1)
  );

  return formattedWords.join(' ');
};

const getColorByPriority = (priority: string) => {
  switch (priority) {
    case 'critical':
      return '#B70E06';
    case 'emergency':
      return '#D88608';
    case 'urgent':
      return '#D8D208';
    case 'routine':
      return '#0867D8';
    default:
      return '#0867D8';
  }
};

const Item = ({
  workOrder,
  onSelectWorkOrder,
  onViewMapClick
}: {
  workOrder: any;
  onSelectWorkOrder: (workOrder: any) => void;
  onViewMapClick?: (workOrder: any) => void;
}) => {
  const [showFullText, setShowFullText] = useState(false);
  const { dispatchShowModalScheduleWorkOrder, dispatchShowWorkOrderOnMap } =
    useModalsContext();
  const { setSelectedWorkOrder, getFullDetailWorkOrderByCode } = useWorkOrderContext();

  const [workOrderStatus, setWorkOrderStatus] = useState(workOrder.status);

   useEffect(() => {
    setWorkOrderStatus(workOrder.status);
  }, [workOrder.status]);

  const handleConfirm = (newStatus) => {
    setWorkOrderStatus(newStatus);
  };
  
  

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  const handleViewMapClick = () => {
    if (onViewMapClick) {
      onViewMapClick(workOrder);
    }
  };
  const currentDate = new Date();

  const [showCreateSnoozedModal, setShowCreateSnoozedModal] = useState(false);
  const [showReactivateModal, setShowReactivateModal] = useState(false);

  const hideSchedulingButton = !!workOrder.next_visit;


  const truncatedText =
    workOrder.issue_resident_detail.length > maxLength && !showFullText
      ? workOrder.issue_resident_detail.slice(0, maxLength) + '...'
      : workOrder.issue_resident_detail;

  return (
    <div className="list-schedule d-flex flex-column">
      <div className="d-flex">
        <div className="list-schedule-data">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className="d-flex">
              {' '}
              <div className="mb-3">
                <h2>{formatStatus(workOrderStatus)} - </h2>
              </div>
              <div className="d-flex align-items-center schedule-id-stl mb-3">
                <Link
                  to={`/workorder-data/${workOrder.code}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {workOrder.code}
                </Link>
              </div>
            </div>

            <div>
              {workOrderStatus === 'snoozed' && (
                <img
                  src={unSnoozeIcon}
                  title="Deactivate snooze"
                  color="#3874ff"
                  style={{
                    width: '35px',
                    height: '35px',
                    marginRight: '-5px',
                    fontSize: '20px',
                    cursor: 'pointer'
                  }}
                  onClick={() => setShowReactivateModal(true)}
                />
              )}

              {workOrderStatus !== 'snoozed' && (
                <img
                  src={snoozeIcon}
                  title="Snooze work order"
                  style={{
                    width: '35px',
                    height: '35px',
                    marginRight: '-5px',
                    fontSize: '20px',
                    cursor: 'pointer'
                  }}
                  onClick={() => setShowCreateSnoozedModal(true)}
                />
              )}

              <SnoozedModal
                show={showCreateSnoozedModal}
                onHide={() => setShowCreateSnoozedModal(false)}
                workOrderId={workOrder.id}
                onConfirm={handleConfirm}
              />

              <ReactivateModal
                show={showReactivateModal}
                onHide={() => setShowReactivateModal(false)}
                workOrderId={workOrder.id}
                onConfirm={handleConfirm}
              />
            </div>
          </div>
          <div className="d-flex flex-row align-items-center gap-3 mb-4">
            {workOrder.next_visit && (
              <div
                className="gap-3 d-flex flex-row font-12"
                style={{ width: '80%' }}>
                <span className="text-700">
                  {' '}
                  Schedule by
                  <span className="schedule-by-stl">
                    {formatAsDateAndTime(workOrder.next_visit.date_and_time)}{' '}
                    <span className="text-700">To</span>{' '}
                    {formatAsTime(
                      workOrder.next_visit.date_and_time_end || moment(workOrder.next_visit.date_and_time).add(1, 'hour').toDate()
                    )}
                  </span>
                </span>
              </div>
            )}

            {/* <TaskElapsedTime creationTime={dataSchedule.ScheduleTimeCreate} /> */}

            <div>
              {/* <span className="text-700">
                  {' '}
                  Arrive by
                  <span className="schedule-by-stl">
                    {dataSchedule.ScheduleTimeArrive}
                  </span>
                </span> */}
            </div>

            {/* <CountdownClock arrivalTime={dataSchedule.ScheduleTimeArrive} /> */}
          </div>

          <div className="d-flex flex-row align-items-center mb-4">
            <div className="d-flex flex-column schedule-service-box ">
              <span className="schedule-service-title">Service Category</span>
              <span>{workOrder.service_category.name}</span>
            </div>
            <div className="d-flex flex-column schedule-service-box text-start">
              <span className="schedule-service-title">Priority</span>
              <span
                className="schedule-priority text-center"
                style={{
                  backgroundColor: getColorByPriority(workOrder.priority),
                  color: 'white',
                  padding: '0.5rem',
                  borderRadius: '4px'
                }}>
                {workOrder.priority}
              </span>
            </div>
            <div className="d-flex flex-column schedule-service-box schedule-service-area-style">
              <span className="schedule-service-title">Address</span>
              <div className="d-flex gap-2">
                {' '}
                <span>{workOrder.residence_address}</span>
                <span className="subtle-bold">
                  {workOrder.service_area.name}
                </span>
              </div>
            </div>
          </div>
          <div>
            <div
              className="d-flex flex-column schedule-service-box gap-2 mb-4"
              style={{ width: '80%', height: '4rem' }}>
              <span className="schedule-service-title">
                Who concern Details:
              </span>
              <span onClick={toggleShowFullText} style={{ cursor: 'pointer' }}>
                {truncatedText}

                {!showFullText &&
                  workOrder.issue_resident_detail.length > maxLength && (
                    <span style={{ color: 'blue' }}>Read more</span>
                  )}
              </span>
            </div>
          </div>
        </div>
        <div
          className="list-schedule-contact"
          style={{ boxSizing: 'content-box' }}>
          <div className="text-center my-2 wo-item-clients">
            <span className="text-800 fs-5 text-closer d-block">
              {workOrder.client.name}
            </span>
            <span className="text-800 fs-8 text-closer d-block">
              {workOrder.sub_client.name}
            </span>
          </div>

          <div>
            <div>
              <span className="schedule-by-stl schedule-contact-data">
                <FontAwesomeIcon
                  className="schedule-icons-data"
                  icon={faUser}
                />{' '}
                {workOrder.resident_name}
              </span>
            </div>
            <div className="d-flex flex-wrap">
              <span className="schedule-by-stl schedule-contact-data">
                <FontAwesomeIcon
                  className="schedule-icons-data"
                  icon={faEnvelope}
                />{' '}
                {workOrder.resident_email}
              </span>
            </div>
            <div>
              <span className="schedule-by-stl schedule-contact-data">
                <FontAwesomeIcon
                  className="schedule-icons-data"
                  icon={faPhone}
                />{' '}
                {workOrder.resident_phone}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="actions mt-3 justify-content-between">
        {workOrder.images && <ImageRenderSchedule workOrder={workOrder} />}
        <div className="d-flex flex-row gap-13 align-items-end">
          {!hideSchedulingButton && (
            <div className="">
              <Button
                variant="secondary"
                onClick={() => {
                  setSelectedWorkOrder(workOrder);

                  onSelectWorkOrder(workOrder);
                  dispatchShowModalScheduleWorkOrder(true);
                }}>
                Schedule
              </Button>
            </div>
          )}
          <div className="">
            <Button variant="primary" onClick={handleViewMapClick}>
              View on Map
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
