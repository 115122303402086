import { baseClient } from './base';
import {
  SearchWorkOrdersParams,
  WorkOrder,
  Technicians,
  ServiceArea,
  Client,
  TechniciansData,
  ServiceCategories,
  SearchWorkOrdersSimpleParams
} from './types/work-orders';

async function getServiceAreas() {
  const { data } = await baseClient.get('/admin/service-areas');
  return data.data;
}

async function getWorkOrderByCode(code: string) {
  try {
    const response = await baseClient.get(`/admin/work-orders/by-code/${code}`);
    return response.data.data;
  } catch (error) {
    console.error('Error al obtener la orden de trabajo por código:', error);
    throw error;
  }
}

async function createUser(email: string, password: string, fullname: string) {
  try {
    const user = { email, password, fullname };
    const { data } = await baseClient.post('/admin/users', user);
    return data.data;
  } catch (error) {
    console.error('Error al crear el usuario:', error);
    throw error;
  }
}

async function updateWorkOrder(workOrderCode: string, updatedData: Partial<WorkOrder>) {
  try {
    const url = `/admin/work-orders/${workOrderCode}`;
    const { data } = await baseClient.put(url, updatedData);
    return data.data;
  } catch (error) {
    console.error('Error al actualizar la orden de trabajo:', error);
    throw error;
  }
}

async function createServiceArea(ServiceArea: ServiceArea) {
  try {
    const { data } = await baseClient.post('/admin/service-areas', ServiceArea);
    return data.data;
  } catch (error) {
    console.error('Error al crear Service Area:', error);
    throw error;
  }
}

async function createClient(Client: Client) {
  try {
    const { data } = await baseClient.post('/admin/Clients', Client);
    return data.data;
  } catch (error) {
    console.error('Error al crear Service Area:', error);
    throw error;
  }
}

async function getServiceCategories() {
  const { data } = await baseClient.get('/admin/service-categories');
  return data.data;
}

async function getClients() {
  const { data } = await baseClient.get('/admin/clients');
  return data.data;
}

async function searchWorkOrders(params?: SearchWorkOrdersParams) {
  const url = `/admin/work-orders`;
  const { data } = await baseClient.get(url, { params });
  return data.data;
}

async function searchWorkOrdersSimple(params?: SearchWorkOrdersSimpleParams) {
  const url = `/admin/work-orders/search-history`;
  const { data } = await baseClient.get(url, { params });
  return data.data;
}

async function createWorkOrder(workOrderData: WorkOrder) {
  try {
    const { data } = await baseClient.post('/admin/work-orders', workOrderData);
    return data.data;
  } catch (error) {
    console.error('Error al crear la orden de trabajo:', error);
    throw error;
  }
}

const getWorkOrders = async (technicianId: number) => {
  try {
    const response = await baseClient.get(
      `/admin/work-orders?technicianId=${technicianId}`
    );
    return response.data.data;
  } catch (error) {
    console.error('Error al obtener las órdenes de trabajo:', error);
    throw error;
  }
};

const getTechnicians = async (): Promise<Technicians[]> => {
  try {
    const response = await baseClient.get('/admin/technicians', { params: { page_size: 100000 }});

    return response.data.data.records;
  } catch (error) {
    console.error('Error al obtener los técnicos:', error);
    throw error;
  }
};

async function createTechnician(technicianData: TechniciansData) {
  try {
    const { data } = await baseClient.post(
      '/admin/technicians',
      technicianData
    );
    return data.data;
  } catch (error) {
    console.error('Error al crear técnico:', error);
    throw error;
  }
}

async function updateTechnician(
  technicianId: number,
  technicianData: TechniciansData
) {
  try {
    const { data } = await baseClient.put(
      `/admin/technicians/${technicianId}`,
      technicianData
    );
    return data.data;
  } catch (error) {
    console.error('Error al actualizar técnico:', error);
    throw error;
  }
}

async function createServiceCategory(serviceCategory: ServiceCategories) {
  try {
    const { data } = await baseClient.post(
      '/admin/service-categories',
      serviceCategory
    );
    return data.data;
  } catch (error) {
    console.error('Error al crear la categoría de servicio:', error);
    throw error;
  }
}

async function updateServiceCategory(
  serviceCategory: number,
  ServiceCategories: TechniciansData
) {
  try {
    const { data } = await baseClient.put(
      `/admin/service-categories/${serviceCategory}`,
      ServiceCategories
    );
    return data.data;
  } catch (error) {
    console.error('Error al actualizar técnico:', error);
    throw error;
  }
}

async function updateServiceArea(
  serviceAreaId: number,
  serviceArea: ServiceArea
) {
  try {
    const { data } = await baseClient.put(
      `/admin/service-areas/${serviceAreaId}`,
      serviceArea
    );
    return data.data;
  } catch (error) {
    console.error('Error al actualizar Service Area:', error);
    throw error;
  }
}

async function updateClient(clientId: number, client: Client) {
  try {
    const { data } = await baseClient.put(`/admin/Clients/${clientId}`, client);
    return data.data;
  } catch (error) {
    console.error('Error al actualizar client id:', error);
    throw error;
  }
}

async function scheduleVisit(params: {
  workOrderId: number;
  technicians: { id: number, type: 'lead-tech' | 'helper-tech' }[],
  date: string;
  dateEnd: string;
  notes: string;
}) {
  const { data } = await baseClient.post(
    `/admin/work-orders/${params.workOrderId}/schedule`,
    {
      date_and_time: params.date,
      date_and_time_end: params.dateEnd,
      technicians: params.technicians,
      notes: params.notes
    }
  );
  return data;
}

async function updateWorkOrderSchedule(
  workOrderId: number,
  scheduleId: number,
  schedule: {
    technicians: { id: number; type: string }[];
    date_and_time: string;
    date_and_time_end: string;
    notes: string;
  }
) {
  try {
    const { data } = await baseClient.put(
      `/admin/work-orders/${workOrderId}/schedule/${scheduleId}`,
      schedule
    );
    return data.data;
  } catch (error) {
    console.error('Error al actualizar la programación del trabajo:', error);
    throw error;
  }
}

async function findWorkOrderByCode(code: string) {
  const { data } = await baseClient.get(`/admin/work-orders/by-code/${code}`);
  return data.data;
}

async function getFullDetailOrder(code: string) {
  const { data } = await baseClient.get(`/admin/work-orders/${code}`);
  return data.data;
}

async function snoozeWorkOrder(workOrderId: number, reason: string) {
  const { data } = await baseClient.post(`/admin/work-orders/${workOrderId}/snooze`, { snoozed_reason: reason });
  return data.message[0].status;
}

async function reactivateWorkOrder(workOrderId: number) {
  const { data } = await baseClient.put(`/admin/work-orders/${workOrderId}/reactivate`);
  return data.data.currentStatus;
}

async function cancelWorkOrder(workOrderId: number, reason: string) {
  const { data } = await baseClient.put(`/admin/work-orders/${workOrderId}/cancel`, { notes: reason });
  return data.data;
}

async function approveRejectQuote(quoteId: number, approveOrReject: 'approve' | 'reject', rejectReason?: string) {
  const { data } = await baseClient.put(`/admin/work-orders/quotes/${quoteId}/approve-or-reject`, { approveOrReject, rejectReason });
  return data.data;
}

async function getWorkOrderImages(workOrderIds: number[]) {
  const { data } = await baseClient.get(`/admin/work-orders/get-images`, { params: { workOrderIds } });
  return data.data;
}

async function getWorkOrdersFilters() {
  const response = await baseClient.get('/admin/work-orders/filters');
  return response;
}

async function uploadImage(formData: FormData) {
  try {
    const { data } = await baseClient.post('/m/images', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return data.url;
  } catch (error) {
    console.error('Error al subir la imagen:', error);
    throw error;
  }
}

export default {
  getWorkOrdersFilters,
  getServiceAreas,
  getServiceCategories,
  searchWorkOrders,
  createWorkOrder,
  getWorkOrders,
  createTechnician,
  getTechnicians,
  updateTechnician,
  createServiceArea,
  updateServiceArea,
  createClient,
  getClients,
  createServiceCategory,
  updateServiceCategory,
  updateClient,
  scheduleVisit,
  findWorkOrderByCode,
  updateWorkOrderSchedule,
  getWorkOrderByCode,
  updateWorkOrder,
  createUser,
  getFullDetailOrder,
  snoozeWorkOrder,
  cancelWorkOrder,
  reactivateWorkOrder,
  getWorkOrderImages,
  approveRejectQuote,
  searchWorkOrdersSimple,
  uploadImage
};
