import AddNewWorkOrder from 'components/forms/AddNewWorkOrder';
import { CalendarEvent } from 'data/calendarEvents';
import { getRandomNumber } from 'helpers/utils';
import usePhoenixForm from 'hooks/usePhoenixForm';
import { useCalendarContext } from 'providers/CalendarProvider';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { SET_CALENDAR_STATE } from 'reducers/CalendarReducer';
import ConfirmationModal from './ConfirmDiscardChanges';

const CalendarAddNewEventModal = () => {
  const {
    openNewEventModal,
    selectedStartDate,
    selectedEndDate,
    calendarDispatch
  } = useCalendarContext();

  const { formData, setFormData, onChange } = usePhoenixForm<CalendarEvent>({
    id: String(getRandomNumber(2000, 3000)),
    start: selectedStartDate,
    end: selectedEndDate
  });


  const handleClose = () => {
    setShowConfirmation(false);
    calendarDispatch({
      type: SET_CALENDAR_STATE,
      payload: {
        openNewEventModal: false
      }
    });
  };

  useEffect(() => {
    setFormData({
      ...formData,
      start: selectedStartDate,
      end: selectedEndDate
    });
  }, [selectedEndDate, selectedStartDate]);

  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <Modal size='lg' show={openNewEventModal} onHide={handleClose}>
      <ConfirmationModal show={showConfirmation} handleConfirm={handleClose} handleClose={() => setShowConfirmation(false)} />
      <AddNewWorkOrder confirmModalAction={() => setShowConfirmation(true)} closeModalAction={handleClose}/>
    </Modal>
  );
};

export default CalendarAddNewEventModal;
