import { loginWithGoogle } from "api/login";

function handleCredentialResponse(response) {
  loginWithGoogle(response.credential).then(() => {
    location.href = '/';
  })
}
window.onload = function () {
  window.google.accounts.id.initialize({
    client_id: '770648530448-n32muueet38gdgc1p1kh0o65us989bhe.apps.googleusercontent.com',
    callback: handleCredentialResponse
  });
  window.google.accounts.id.renderButton(
    document.getElementById('buttonDiv'),
    { theme: 'outline', size: 'large' } // customization attributes
  );

  window.google.accounts.id.prompt(); // also display the One Tap dialog
};

const AuthSocialButtons = ({ title }: { title: string }) => {
  return (
    <>
      <div id="buttonDiv" style={{ display: 'flex', justifyContent: 'center' }}></div>
      {/* <Button
        variant="phoenix-secondary"
        className="w-100"
        startIcon={
          <FontAwesomeIcon
            icon={faFacebook}
            className="text-primary me-2 fs-9"
          />
        }
      >
        {title} with facebook
      </Button> */}
    </>
  );
};

export default AuthSocialButtons;
