import moment, { Moment } from 'moment';

export const formatAsDate = (date: Date | string | Moment) => {
  return moment(date).format('MM/DD/YYYY');
}

export const formatAsDateAndTime = (date: Date | string | Moment) => {
  return moment(date).format('MM/DD/YYYY hh:mm A');
}

export const formatAsTime = (date: Date | string | Moment) => {
  return moment(date).format('hh:mm A');
}

export const formatForApi = (date: Date | string | Moment) => {
  return moment(date).utc().format();
}