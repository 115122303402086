import ServiceAreaAbmTable from "components/tables/ServiceAreaAbmTable";

const ServiceAreaAbm = () => {
    return (
        <div className="p-3">
            <h3 style={{ textAlign: 'center'}} >Service Area</h3>
            <ServiceAreaAbmTable />
        </div>
    );
}

export default ServiceAreaAbm;
