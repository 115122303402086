import { useState, useEffect } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Modal } from 'react-bootstrap';
import CreateNewServiceArea from 'components/forms/CreateNewServiceArea';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Button from 'components/base/Button';
import EditServiceArea from 'components/forms/EditServiceArea';

interface ServiceArea {
  id: number;
  name: string;
}

const ServiceAreaAbmTable = () => {
  const { serviceAreas, getServiceAreas } = useWorkOrderContext();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalData, setEditModalData] = useState<{ id: number; name: string; }>({
    id: -1, 
    name: '',
  });

  useEffect(() => {
    getServiceAreas();
  }, []);

  const handleCreate = () => {
    setShowCreateModal(true);
  };

  const handleCloseModal = () => {
    setShowCreateModal(false);
  };

  const handleShowEditModal = (serviceArea: ServiceArea) => {
    setEditModalData({ id: serviceArea.id, name: serviceArea.name });
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    getServiceAreas();
    
  };

  const handleDelete = (original: ServiceArea) => {
    alert('not implemented yet');
  };

  const serviceAreaTableColumns: ColumnDef<ServiceArea>[] = [
    {
      accessorKey: 'name',
      header: 'Name',
      cell: ({ row: { original } }) => original.name,
      meta: {
        headerProps: { style: { width: '20%' }, className: 'text-center pe-7' },
        cellProps: { className: 'text-center fw-semi-bold text-1000 pe-7' }
      }
    },

    {
      id: 'action',
      header: 'Actions',
      cell: ({ row: { original } }) => (
        <div className="d-flex justify-content-start">
          <Button
            variant="primary"
            className="m-2"
            size="sm"
            onClick={() => handleShowEditModal(original)}>
            Edit
          </Button>
        {/* 
          <Button
            variant="danger"
            size="sm"
            onClick={() => handleDelete(original)}>
            Delete
          </Button> */}
        </div>
      ),
      meta: {
        headerProps: { style: { width: '25%', paddingLeft: '1rem' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];


  const table = useAdvanceTable({
    data: serviceAreas,
    columns: serviceAreaTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  return (
    <AdvanceTableProvider {...table}>
      <div className="border-y">
        <div
          className="d-flex flex-wrap gap-3 justify-content-end"
          style={{ paddingRight: '2rem' }}>
          <Button
            variant="success"
            size="sm"
            className="mt-3"
            onClick={() => handleCreate()}>
            Create
          </Button>
        </div>
        <Modal show={showCreateModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Create New Service Area</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateNewServiceArea closeModalAction={handleCloseModal} />
          </Modal.Body>
          
        </Modal>
        <Modal show={showEditModal} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Service Area</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditServiceArea    closeModalAction={() => {
          handleCloseEditModal();
          setEditModalData({ id: 0, name: '' }); 
        }}
        serviceAreaId={editModalData.id}
        serviceAreaName={editModalData.name} />
          </Modal.Body>
         
        </Modal>

        <AdvanceTable
          tableProps={{ className: 'phoenix-table table-sm fs-9' }}
        />
        <AdvanceTableFooter pagination showViewAllBtn={false} />
      </div>
    </AdvanceTableProvider>
  );
};

export default ServiceAreaAbmTable;
