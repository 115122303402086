
import React, { useState, useEffect } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Modal } from 'react-bootstrap';

import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Button from 'components/base/Button';
import CreateNewTechnicians from 'components/forms/CreateNewTechnicians';
import EditNewTechnicians from 'components/forms/EditTechnicians';

interface Technician {
  id: number;
  technician_number: string;
  first_name: string;
  last_name: string;
  
}

const TechniciansAbmTable = () => {
  const { technicians, getTechnicians } = useWorkOrderContext();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalData, setEditModalData] = useState<Technician>({
    id: -1,
    technician_number: '',
    first_name: '',
    last_name: '',
   
  });
  useEffect(() => {
    getTechnicians();
  }, []);

  const handleCreate = () => {
    setShowCreateModal(true);
  };

  const handleCloseModal = () => {
    setShowCreateModal(false);
  };

  const handleShowEditModal = (technician: Technician) => {
    setEditModalData({ ...technician });
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    getTechnicians();
  };

  const handleDelete = (original: Technician) => {
    alert('not implemented yet');
  };

  const serviceAreaTableColumns: ColumnDef<Technician>[] = [
    
    {
      accessorKey: 'technician_number',
      header: 'Technician Number',
      cell: ({ row: { original } }) => original.technician_number,
      meta: {
        headerProps: { style: { width: '20%' }, className: 'text-center pe-7' },
        cellProps: { className: 'text-center fw-semi-bold text-1000 pe-7' }
      }
    },
    {
      accessorKey: 'first_name',
      header: 'First Name',
      cell: ({ row: { original } }) => original.first_name,
      meta: {
        headerProps: { style: { width: '20%' }, className: 'text-center pe-7' },
        cellProps: { className: 'text-center fw-semi-bold text-1000 pe-7' }
      }
    },
    {
      accessorKey: 'last_name',
      header: 'Last Name',
      cell: ({ row: { original } }) => original.last_name,
      meta: {
        headerProps: { style: { width: '20%' }, className: 'text-center pe-7' },
        cellProps: { className: 'text-center fw-semi-bold text-1000 pe-7' }
      }
    },
    {
      id: 'action',
      header: 'Actions',
      cell: ({ row: { original } }) => (
        <div className='d-flex justify-content-center'>
          <Button
            variant="primary"
            className="m-2"
            size="sm"
            onClick={() => handleShowEditModal(original)}>
            Edit
          </Button>
        
        {/*   <Button
            variant="danger"
            size="sm"
            onClick={() => handleDelete(original)}>
            Delete
          </Button> */}
        </div>
      ),
      meta: {
        headerProps: { style: { width: '25%', paddingLeft: '5rem' } },
        cellProps: { className: 'text-end' }
      }
    }
  ];

  const table = useAdvanceTable({
    data: technicians,
    columns: serviceAreaTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  return (
    <AdvanceTableProvider {...table}>
      <div className="border-y">
        <div
          className="d-flex flex-wrap gap-3 justify-content-end"
          style={{ paddingRight: '2rem' }}>
          <Button
            variant="success"
            size="sm"
            className="mt-3"
            onClick={() => handleCreate()}>
            Create
          </Button>
        </div>
        <Modal show={showCreateModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>New Technician</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CreateNewTechnicians closeModalAction={handleCloseModal} />
          </Modal.Body>
        </Modal>
        <Modal show={showEditModal} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit technician {editModalData.technician_number}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditNewTechnicians technician={editModalData} closeModalAction={handleCloseEditModal} />
          </Modal.Body>
        </Modal>

        <AdvanceTable
          tableProps={{ className: 'phoenix-table table-sm fs-9' }}
        />
        <AdvanceTableFooter pagination showViewAllBtn={false} />
      </div>
    </AdvanceTableProvider>
  );
};

export default TechniciansAbmTable;