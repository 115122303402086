import './proposal.scss';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';
import Button from 'components/base/Button';
import { Tabs, Tab } from 'react-bootstrap';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import RejectQuoteWithReasonModal from 'components/modals/RejectQuoteWithReasonModal';

const ONLY_NUMBER_DIGITS_REGEX = /[0-9]|\./;

const Quote = ({ quote, editable }) => {
  const [quoteData, setQuoteData] = useState(quote.data);

  const [_editable, setEditable] = useState(editable);

  const [showRejectedModal, setShowRejectedModal] = useState(false);
  const [accepting, setAccepting] = useState(false);

  const {
    approveRejectQuote,
    getFullDetailWorkOrderByCode,
    workOrderFullDetailFound
  } = useWorkOrderContext();

  const total = useMemo(
    () =>
      quoteData.cost_entries.reduce(
        (sum, cost) => cost.rate * cost.quantity + sum,
        0
      ),
    [quoteData]
  );

  function removeItem(item: any): void {
    item.deleted = true;
    setQuoteData({ ...quoteData });
  }

  function nonDeleted(costEntries: any) {
    return costEntries.filter(cost => !cost.deleted);
  }

  function addItem(): void {
    setQuoteData({ ...quoteData });
  }

  function updateQuote() {
    setQuoteData({ ...quoteData });
  }

  const acceptQuote = async () => {
    setAccepting(true);
    try {
      await approveRejectQuote(quote.id, 'approve');
      getFullDetailWorkOrderByCode(workOrderFullDetailFound.code);
    } catch (err) {
      alert(err);
    } finally {
      setAccepting(false);
    }
  };
  const preventNonNumericalDigits = e => {
    const isValidChar = ONLY_NUMBER_DIGITS_REGEX.test(e.key);
    const isControlKey = e.key === "Backspace" || (e.keyCode >= 37 && e.keyCode <= 40);
    if (!isValidChar && !isControlKey) {
      e.preventDefault();
      return false;
    }
  };
  return (
    <div>
      <div className="proposal-row d-flex flex-row justify-end">
        {quote.status === 'pending' ? (
          <div
            className="d-flex gap-2"
            style={{
              position: 'absolute',
              right: '30px'
            }}>
            <RejectQuoteWithReasonModal
              handleClose={() => setShowRejectedModal(false)}
              quoteId={quote.id}
              show={showRejectedModal}
            />
            <Button
              variant="link"
              className="proposal-accept"
              onClick={() => {
                if (_editable) {
                  setQuoteData({ ...quote.data });
                  setEditable(false);
                } else {
                  setEditable(true);
                }
              }}
              style={{ color: 'var(--phoenix-primary)' }}>
              {_editable ? 'Cancel edit' : 'Edit'}
            </Button>
            <Button
              variant="link"
              className="proposal-accept"
              disabled={accepting}
              onClick={acceptQuote}
              style={{ color: 'var(--phoenix-primary)' }}>
              {accepting ? 'Accepting' : 'Accept'}
            </Button>
            <Button
              variant="link"
              className="proposal-reject"
              disabled={accepting}
              onClick={() => setShowRejectedModal(true)}
              style={{ color: 'var(--phoenix-danger)' }}>
              Reject
            </Button>
          </div>
        ) : (
          <div
            style={{
              position: 'absolute',
              right: '30px'
            }}>
            <FontAwesomeIcon
              icon={faPrint}
              className="proposal-icon"
              aria-disabled
              title="Print Proposal"
              size="lg"
              color="#1e6fbb"
            />
          </div>
        )}
      </div>
      <div className="proposal-info-box d-flex flex-column gap-3">
        <div className="proposal-info-item d-flex flex-row">
          <div className="proposal-info-title">
            <b>Status:</b>
          </div>
          <div className="proposal-info-text">{quote.status}</div>
        </div>
        <div className="proposal-info-item d-flex flex-row">
          <div className="proposal-info-title">
            <b>Affected area:</b>
          </div>
          <div className="proposal-info-text">
            {_editable ? (
              <input
                className="input-proposal-client-edit"
                style={{ color: '#31374a' }}
                type="text"
                value={quoteData.affected_area}
                onChange={e => {
                  quoteData.affected_area = e.target.value;
                  updateQuote();
                }}
              />
            ) : (
              quoteData.affected_area
            )}
          </div>
        </div>
        <div className="proposal-info-item d-flex flex-row">
          <div className="proposal-info-title">
            <b>Affected side:</b>
          </div>
          <div className="proposal-info-text">
            {_editable ? (
              <input
                className="input-proposal-client-edit"
                style={{ color: '#31374a' }}
                type="text"
                value={quoteData.affected_side}
                onChange={e => {
                  quoteData.affected_side = e.currentTarget.value;
                  updateQuote();
                }}
              />
            ) : (
              quoteData.affected_side
            )}
          </div>
        </div>
        <div className="proposal-info-item d-flex flex-row">
          <div className="proposal-info-title">
            <b>Affected property level:</b>
          </div>
          <div className="proposal-info-text">
            {_editable ? (
              <input
                className="input-proposal-client-edit"
                style={{ color: '#31374a' }}
                type="text"
                value={quoteData.affected_property_level}
                onChange={e => {
                  quoteData.affected_property_level = e.currentTarget.value;
                  updateQuote();
                }}
              />
            ) : (
              quoteData.affected_property_level
            )}
          </div>
        </div>
        <div className="proposal-info-item d-flex flex-row">
          <div className="proposal-info-title">
            <b>Can be performed today?:</b>
          </div>
          <div className="proposal-info-text">
            {_editable ? (
              <input
                className="input-proposal-client-edit"
                style={{ color: '#31374a', width: '15px' }}
                type="checkbox"
                value={quoteData.can_be_performed_today}
                onChange={e => {
                  quoteData.can_be_performed_today = e.currentTarget.checked;
                  updateQuote();
                }}
              />
            ) : (
              quoteData.can_be_performed_today
            )}
          </div>
        </div>
        <div className="proposal-info-item d-flex flex-row">
          <div className="proposal-info-title">
            <b>Inspection result:</b>
          </div>
          <div className="proposal-info-text">
            {_editable ? (
              <textarea
                className="input-proposal-client-edit"
                style={{ color: '#31374a' }}
                rows={3}
                value={quoteData.inspection_result}
                onChange={e => {
                  quoteData.inspection_result = e.currentTarget.value;
                  updateQuote();
                }}
              />
            ) : (
              quoteData.inspection_result
            )}
          </div>
        </div>
        <div className="proposal-info-item d-flex flex-row">
          <div className="proposal-info-title">
            <b>Issue cause:</b>
          </div>
          <div className="proposal-info-text">
            {_editable ? (
              <textarea
                className="input-proposal-client-edit"
                style={{ color: '#31374a' }}
                rows={3}
                value={quoteData.issue_cause}
                onChange={e => {
                  quoteData.issue_cause = e.currentTarget.value;
                  updateQuote();
                }}
              />
            ) : (
              quoteData.issue_cause
            )}
          </div>
        </div>
        <div className="proposal-info-item d-flex flex-row">
          <div className="proposal-info-title">
            <b>Recommended solution:</b>
          </div>
          <div className="proposal-info-text">
            {_editable ? (
              <textarea
                className="input-proposal-client-edit"
                style={{ color: '#31374a' }}
                rows={3}
                value={quoteData.recommended_solution}
                onChange={e => {
                  quoteData.recommended_solution = e.currentTarget.value;
                  updateQuote();
                }}
              />
            ) : (
              quoteData.recommended_solution
            )}
          </div>
        </div>
        <div className="proposal-info-item d-flex flex-row">
          <div className="proposal-info-title">
            <b>Job process:</b>
          </div>
          <div className="proposal-info-text">
            {_editable ? (
              <textarea
                className="input-proposal-client-edit"
                style={{ color: '#31374a' }}
                rows={3}
                value={quoteData.job_process}
                onChange={e => {
                  quoteData.job_process = e.currentTarget.value;
                  updateQuote();
                }}
              />
            ) : (
              quoteData.job_process
            )}
          </div>
        </div>
        <div className="proposal-info-item d-flex flex-row">
          <div className="proposal-info-title">
            <b>Scope resolution:</b>
          </div>
          <div className="proposal-info-text">
            {_editable ? (
              <textarea
                className="input-proposal-client-edit"
                style={{ color: '#31374a' }}
                rows={3}
                value={quoteData.scope_resolution}
                onChange={e => {
                  quoteData.scope_resolution = e.currentTarget.textContent;
                  updateQuote();
                }}
              />
            ) : (
              quoteData.scope_resolution
            )}
          </div>
        </div>
        <div className="proposal-info-item d-flex flex-row">
          <div className="proposal-info-title">
            <b>Additional info:</b>
          </div>
          <div className="proposal-info-text">
            {_editable ? (
              <textarea
                className="input-proposal-client-edit"
                style={{ color: '#31374a' }}
                value={quoteData.additional_info}
                rows={5}
                onChange={e => {
                  quoteData.additional_info = e.currentTarget.textContent;
                  updateQuote();
                }}
              />
            ) : (
              quoteData.additional_info
            )}
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <h4>Cost entries</h4>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className="table-column-quantity">Type</th>
              <th className="table-column-description">Description</th>
              <th className="table-column-quantity">Quantity</th>
              <th className="table-column-unit-price">Unit Price</th>
              <th className="table-column-net-price">Net Price</th>
              {_editable && <th className="table-column-net-price"></th>}
            </tr>
          </thead>
          <tbody>
            {nonDeleted(quoteData.cost_entries).map((item, index) => (
              <tr key={index}>
                <td className="table-column-quantity">{item.type}</td>
                <td className="table-column-description">
                  {_editable ? (
                    <input
                      className="input-proposal-client-edit"
                      type="text"
                      value={item.description}
                      onChange={e => {
                        item.description = e.target.value;
                        updateQuote();
                      }}
                    />
                  ) : (
                    item.description
                  )}
                </td>
                <td className="table-column-quantity">
                  {_editable ? (
                    <input
                      className="input-proposal-client-edit"
                      type="text"
                      value={item.quantity}
                      onKeyDown={preventNonNumericalDigits}
                      onChange={e => {
                        item.quantity = e.target.value;
                        updateQuote();
                      }}
                    />
                  ) : (
                    item.quantity
                  )}
                </td>
                <td className="table-column-unit-price">
                  {_editable ? (
                    <input
                      className="input-proposal-client-edit"
                      type="text"
                      value={item.rate}
                      onKeyDown={preventNonNumericalDigits}
                      onChange={e => {
                        item.rate = e.target.value;
                        updateQuote();
                      }}
                    />
                  ) : (
                    item.rate
                  )}
                </td>
                <td className="table-column-net-price">{(+item.rate * +item.quantity).toFixed(2)}</td>
                {_editable && (
                  <td className="table-column-net-price">
                    <Button
                      variant="link"
                      className="cost-actions"
                      onClick={() => removeItem(item)}>
                      Remove
                    </Button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="total">
          {_editable && (
            <Button className="cost-actions" variant="link" onClick={addItem}>
              Add row
            </Button>
          )}
          <b>Total: $ {total}</b>
        </div>
      </div>
      <div className="table-responsive">
        <h4>Summary</h4>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Labor</th>
              <th>Material</th>
              <th>Expenses</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {nonDeleted(quoteData.cost_entries)
                  .filter(cost => cost.type === 'labor')
                  .reduce((sum, cost) => cost.rate * cost.quantity + sum, 0)}
              </td>
              <td>
                {nonDeleted(quoteData.cost_entries)
                  .filter(cost => cost.type === 'material')
                  .reduce((sum, cost) => cost.rate * cost.quantity + sum, 0)}
              </td>
              <td>
                {nonDeleted(quoteData.cost_entries)
                  .filter(cost => cost.type === 'expense')
                  .reduce((sum, cost) => cost.rate * cost.quantity + sum, 0)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const ProposalTab = () => {
  const { workOrderFullDetailFound } = useWorkOrderContext();
  const techToCompany = workOrderFullDetailFound.quotes.find(
    t => t.receiver === 'tech_to_company'
  );
  const companyToClient = workOrderFullDetailFound.quotes.find(
    t => t.receiver === 'company_to_client'
  );

  const [activeTab, setActiveTab] = useState('techProposal'); // Estado para la pestaña activa

  const handleTabChange = eventKey => {
    setActiveTab(eventKey);
  };

  return (
    <div className="proposal-container d-flex flex-column">
      <Tabs
        activeKey={activeTab}
        onSelect={handleTabChange}
        id="proposal-tabs"
        className="custom-tabs-proposal">
        {techToCompany && (
          <Tab eventKey="techProposal" title="Tech Proposal">
            <Quote quote={techToCompany} editable={false} />
          </Tab>
        )}
        {companyToClient && (
          <Tab eventKey="clientProposal" title="Client Proposal">
            <Quote quote={companyToClient} editable={true} />
          </Tab>
        )}
      </Tabs>
    </div>
  );
};

export default ProposalTab;
