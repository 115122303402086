import {
  PropsWithChildren,
  useContext,
  Dispatch,
  createContext,
  useReducer,
  useEffect
} from 'react';

import api from '../api/clients';
import { CLIENTS_ACTION_TYPE, ClientsState, clientsReducer } from 'reducers/ClientsReducer';
import { GET_CLIENTS } from 'reducers/WorkOrdersReducer';

interface ClientsContextInterface extends ClientsState {
  clientsDispatch: Dispatch<CLIENTS_ACTION_TYPE>;
  getClients: () => Promise<void>;
  clients: any[];
}

export const ClientsContext = createContext({} as ClientsContextInterface);

const ClientsProvider = ({ children }: PropsWithChildren) => {
  const initialState: ClientsState = {
    clients: [],
  };

  const [clientsState, clientsDispatch] = useReducer(
    clientsReducer,
    initialState
  );

  const getClients = async () => {
    try {
      const clients = await api.getClients();
      clientsDispatch({ payload: clients, type: GET_CLIENTS });
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <ClientsContext.Provider
      value={{
        ...clientsState,
        clientsDispatch,
        getClients,
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};

export const useClientsContext = () => useContext(ClientsContext);

export default ClientsProvider;
