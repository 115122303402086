import ClientAbmTable from "components/tables/ClientAbmTable";

const ClientAbm = () => {
    return (
        <div className="p-3">
            <h3 style={{ textAlign: 'center'}} >Client Table</h3>
            <ClientAbmTable />
        </div>
    );
}

export default ClientAbm;
