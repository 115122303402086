import { Form, Modal, Button } from 'react-bootstrap';
import { useWorkOrderContext } from 'providers/WorkOrderProvider';
import React, { useState, useEffect } from 'react';
import { useClientsContext } from 'providers/ClientsProvider';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { WorkOrder } from 'api/types/work-orders';
import { WorkOrderPriority } from './types/WorkOrders';
import moment, { Moment } from 'moment';
import FirstStepData from './AddNewWorkOrder/FirstStepData';
import ResidentData from './AddNewWorkOrder/ResidentData';

type WorkOrderFormData = {
  client_id: any;
  code: string;
  type: string;
  issue_resident_detail: string;
  priority: string;
  residence_address: string;
  residence_address_lat: number | undefined;
  residence_address_long: number | undefined;
  resident: {
    email: string;
    alternative_phone: string;
    full_name: string;
    phone: string;
  };
  service_area_id: any;
  service_category_id: any;
  sub_client_id: any;
  technician_id: any;
  client_nte: number;
  tech_nte: number;
  alternativeContact: {
    email: string;
    name: string;
    phone: string;
  };
  scheduleBy: Moment;
  arrivalBy: Moment;
  resolvedByNonQuoted: Moment;
};

const AddWorkOrderForm = ({
  closeModalAction,
  confirmModalAction,
  workOrderToEdit,
  workOrderCode
}: {
  closeModalAction: () => void;
  confirmModalAction: () => void;
  workOrderToEdit?: WorkOrder;
  workOrderCode?: string;
}) => {
  const { createWorkOrder, updateWorkOrder, serviceAreas, serviceCategories } =
    useWorkOrderContext();

  const { clients } = useClientsContext();

  const [codeExists, setCodeExists] = useState(false);

  const [formData, setFormData] = useState<{
    client_id: any;
    code: string;
    type: string;
    issue_resident_detail: string;
    priority: string;
    residence_address: string;
    residence_address_lat: number | undefined;
    residence_address_long: number | undefined;
    resident: {
      email: string;
      alternative_phone: string;
      full_name: string;
      phone: string;
    };
    service_area_id: any;
    service_category_id: any;
    sub_client_id: any;
    technician_id: any;
    client_nte: number;
    tech_nte: number;
    alternativeContact: {
      email: string;
      name: string;
      phone: string;
    };
    scheduleBy: Moment;
    arrivalBy: Moment;
    resolvedByNonQuoted: Moment;
  }>({
    client_id: null,
    code: null,
    type: '',
    issue_resident_detail: '',
    priority: '',
    residence_address: '',
    residence_address_lat: null,
    residence_address_long: null,
    resident: {
      email: '',
      alternative_phone: '',
      full_name: '',
      phone: ''
    },
    service_area_id: null,
    service_category_id: null,
    sub_client_id: null,
    technician_id: null,
    client_nte: 0,
    tech_nte: 0,
    alternativeContact: {
      email: '',
      name: '',
      phone: ''
    },
    scheduleBy: null,
    arrivalBy: null,
    resolvedByNonQuoted: null
  });

  useEffect(() => {
    if (workOrderToEdit) {
      setFormData({
        client_id: workOrderToEdit.client_id,
        code: workOrderToEdit.code,
        type: workOrderToEdit.type,
        issue_resident_detail: workOrderToEdit.issue_resident_detail,
        priority: workOrderToEdit.priority,
        residence_address: workOrderToEdit.residence_address,
        residence_address_lat: workOrderToEdit.residence_address_lat,
        residence_address_long: workOrderToEdit.residence_address_long,
        resident: {
          email: workOrderToEdit.resident_email || '',
          alternative_phone: workOrderToEdit.resident_alternative_phone || '',
          full_name: workOrderToEdit.resident_name || '',
          phone: workOrderToEdit.resident_phone || ''
        },
        client_nte: workOrderToEdit.nte,
        tech_nte: workOrderToEdit.tech_nte,
        alternativeContact: {
          email: workOrderToEdit.alternativeContact?.email || '',
          name: workOrderToEdit.alternativeContact?.name || '',
          phone: workOrderToEdit.alternativeContact?.phone || ''
        },
        service_area_id: workOrderToEdit.service_area_id,
        sub_client_id: workOrderToEdit.sub_client_id,
        technician_id: workOrderToEdit.technician_id,
        service_category_id: workOrderToEdit.service_category_id,
        scheduleBy: moment(workOrderToEdit.scheduleBy),
        arrivalBy: moment(workOrderToEdit.arrivalBy),
        resolvedByNonQuoted: moment(workOrderToEdit.resolvedByNonQuoted)
      });
    }
  }, [workOrderToEdit]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData(prevFormData => {
      if (name.startsWith('resident_')) {
        return {
          ...prevFormData,
          resident: {
            ...prevFormData.resident,
            [name.replace('resident_', '')]: value
          }
        };
      } else if (name.startsWith('alternativeContact_')) {
        return {
          ...prevFormData,
          alternativeContact: {
            ...prevFormData.alternativeContact,
            [name.replace('alternativeContact_', '')]: value
          }
        };
      } else {
        return {
          ...prevFormData,
          [name]: value
        };
      }
    });
  };

  const handleSubmit = (e: any) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();

    if (codeExists) {
      alert('The Work Order code already exists. Please generate a new one.');
      return;
    }

    if (form.checkValidity() === false) {
      setValidated(true);
      setStepToShow('first');
      return;
    }

    const updatedData = {
      client_id: formData.client_id,
      code: formData.code,
      issue_resident_detail: formData.issue_resident_detail,
      priority: formData.priority as WorkOrderPriority,
      residence_address: formData.residence_address,
      residence_address_lat: formData.residence_address_lat,
      residence_address_long: formData.residence_address_long,
      nte: formData.client_nte,
      tech_nte: formData.tech_nte,
      resident: {
        email: formData.resident.email,
        alternative_phone: formData.resident.alternative_phone,
        full_name: formData.resident.full_name,
        phone: formData.resident.phone
      },
      alternativeContact: {
        email: formData.alternativeContact.email,
        name: formData.alternativeContact.name,
        phone: formData.alternativeContact.phone
      },
      service_area_id: formData.service_area_id,
      sub_client_id: formData.sub_client_id,
      technician_id: formData.technician_id,
      service_category_id: formData.service_category_id,
      scheduleBy: formData.scheduleBy ? formData.scheduleBy.toDate() : null,
      arrivalBy: formData.arrivalBy ? formData.arrivalBy.toDate() : null,
      resolvedByNonQuoted: formData.resolvedByNonQuoted
        ? formData.resolvedByNonQuoted.toDate()
        : null
    };

    if (workOrderToEdit) {
      updateWorkOrder(workOrderToEdit.code, updatedData, closeModalAction);
    } else {
      createWorkOrder(formData, closeModalAction);
    }
  };

  const [validated, setValidated] = useState(false);

  const [stepToShow, setStepToShow] = useState('first');


  function requiredParamsMissing(formData: WorkOrderFormData): boolean {
    if (!formData.code) return true;
    if (!formData.priority) return true;
    if (!formData.type) return true;
    if (!formData.client_id) return true;
    if (!formData.issue_resident_detail) return true;
    if (!formData.residence_address) return true;
    if (!formData.service_category_id) return true;
    if (!formData.service_area_id) return true;
    return false;
  }

  return (
    <Form onSubmit={handleSubmit} noValidate validated={validated}>
      <Modal.Header className="px-card border-0 header-new-work-order">
        {/* elemento solo para alinear al centro el header :)*/}
        <h3></h3>
        <h3 className="lh-sm text-1000">
          {workOrderToEdit
            ? `Edit Work Order ${workOrderToEdit.code}`
            : 'Add Work Order'}
        </h3>
        <Button
          className="p-1 fs-10 text-900 add-work-order-title"
          variant="none"
          onClick={confirmModalAction}>
          DISCARD
        </Button>
      </Modal.Header>
      <Modal.Body className="p-card py-0">
        {stepToShow === 'first' && (
          <FirstStepData
            clients={clients}
            formData={formData}
            handleChange={handleChange}
            serviceAreas={serviceAreas}
            serviceCategories={serviceCategories}
            setFormData={setFormData}
            codeExists={codeExists}
            setCodeExists={setCodeExists}
          />
        )}
        {stepToShow === 'final' && (
          <ResidentData
            formData={formData}
            handleChange={handleChange}></ResidentData>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-center border-0">
        {stepToShow === 'first' && (
          <Button
            variant="primary"
            className="px-4"
            disabled={requiredParamsMissing(formData)}
            onClick={() => setStepToShow('final')}>
            Next
          </Button>
        )}
        {stepToShow === 'final' && (
          <>
            <Button
              variant="primary"
              className="px-4"
              onClick={() => setStepToShow('first')}>
              Previous
            </Button>
            <Button variant="primary" className="px-4" type="submit">
              Save
            </Button>
          </>
        )}
      </Modal.Footer>
    </Form>
  );
};

export default AddWorkOrderForm;
